import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import "./BubbleRadio.scss";
import classnames from "classnames";
export default class BubbleRadioSingle extends Component {
  constructor(props) {
    super(props);
    this.state = { optionNumber: this.props.def_option };
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(val) {
    //   console.log("val :>> ", val);
    this.setState(
      {
        optionNumber: val,
      },
      () => {
        this.props.onOptionClick(this.state.optionNumber);
        // console.log(`this.state.optionNumber`, this.state.optionNumber);
      }
    );
  }
  render() {
    // console.log("this.state.optionNumber", this.state.optionNumber);
    return (
      <>
        <div className="mt-2">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            {this.props.label}
          </small>
          <div className="d-flex bubbleRadioBox">
            <Col className="pr-2 pl-2 align-self-center text-center">
              <button
                type="button"
                className={classnames("option", {
                  selected: this.state.optionNumber === this.props.option1,
                })}
                onClick={this.handleClick.bind(this, this.props.option1)}
                style={{ cursor: "pointer" }}
              >
                {this.props.option1}
                <br />
                <small>{this.props.option1sub}</small>
              </button>
            </Col>
            {/* text-muted class makes the text grayed out to show that the option isn't available */}
            <Col className="pr-1 pl-1 align-self-center text-center">
              <button
                type="button"
                className={classnames("option", {
                  selected: this.state.optionNumber === this.props.option2,
                })}
                onClick={this.handleClick.bind(this, this.props.option2)}
                style={{ cursor: "pointer" }}
              >
                {this.props.option2}
                <br />
                <small>{this.props.option2sub}</small>
              </button>
            </Col>
            {/* selected class highlights the selected option */}
            {this.props.option3 != null ? (
              <Col className="pr-2 pl-2 align-self-center text-center">
                <button
                  type="button"
                  className={classnames("option", {
                    selected: this.state.optionNumber === this.props.option3,
                  })}
                  onClick={this.handleClick.bind(this, this.props.option3)}
                  style={{ cursor: "pointer" }}
                >
                  {this.props.option3}
                  <br />
                  <small>{this.props.option3sub}</small>
                </button>
              </Col>
            ) : null}
            {this.props.option4 != null ? (
              <Col className="pr-2 pl-2 align-self-center text-center">
                <button
                  type="button"
                  className={classnames("option", {
                    selected: this.state.optionNumber === this.props.option4,
                  })}
                  onClick={this.handleClick.bind(this, this.props.option4)}
                  style={{ cursor: "pointer" }}
                >
                  {this.props.option4}
                  <br />
                  <small>{this.props.option4sub}</small>
                </button>
              </Col>
            ) : null}
          </div>
        </div>
        <hr className="pt-1 mt-3 mb-2" />
      </>
    );
  }
}
