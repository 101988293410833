import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { mailInspectionApprovedOwner } from "../../actions/mailActions/propMails";
import { notifInspectionApprovedOwner } from "../../actions/notifActions/propNotifs";
import {
  changeInspectionStatus,
  deleteInspectionCard,
} from "../../actions/propActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
class InspectionRequestCard extends Component {
  state = {};
  render() {
    const kh_phone_content = (
      <div className="mb-2">
        <div className="d-block mt-1 text-muted">
          <small className="text-uppercase">Keyholder's Number</small>
        </div>
        <div className="d-block">{this.props.keyholderMobile}</div>
      </div>
    );
    return (
      <>
        <div
          className="shadow-sm shadow-lg--hover rounded my-2"
          style={{ padding: "20px 40px" }}
        >
          <div className="mb-2">
            <row>
              <Button
                className="btn-icon btn-neutral btn-round  my-2 mr-1"
                color="primary"
                type="button"
                style={{ float: "right" }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text:
                      "This inspection request will be Deleted permanantly!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      this.props.deleteInspectionCard(this.props.prop_id);
                    }
                  });
                }}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-trash mr-0" />
                </span>
                {/* <span className="btn-inner--text">Delete Property</span> */}
              </Button>
            </row>
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Property Name</small>
            </div>
            <div className="d-block">{this.props.prop_name}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Name</small>
            </div>
            <div className="d-block">{this.props.owner_name}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Phone</small>
            </div>
            <div className="d-block">{this.props.owner_phone}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Location</small>
            </div>
            <div className="d-block">{this.props.locality}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Furnishing</small>
            </div>
            <div className="d-block">{this.props.furnishing}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Address</small>
            </div>
            <div className="d-block">{this.props.address}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Keyholder</small>
            </div>
            <div className="d-block text-uppercase">
              {this.props.keyholder} (
              {this.props.holdertype === "igen"
                ? "Ready to handover the key!"
                : this.props.holdertype}
              )
            </div>
            {this.props.holdertype === "igen" ? "" : kh_phone_content}
          </div>

          <div className="mt-4 mb-2">
            <Row>
              <Col>
                <Button
                  block
                  className="btn-round mb-2"
                  color="primary"
                  outline
                  type="button"
                  style={{
                    margin: "0px auto",
                    padding: "10px 12px",
                    fontSize: "0.7em",
                  }}
                  onClick={() =>
                    // this.props.history.push({
                    //   pathname: "/pm-property" + this.props.prop_id
                    // })
                    (window.location = "/pm-property" + this.props.prop_id)
                  }
                >
                  View Property
                </Button>
                <Button
                  block
                  type="submit"
                  className="btn-round"
                  color="primary"
                  //onClick={() => this.props.onProceed()}
                  style={{
                    margin: "0px auto",
                    padding: "12px 12px",
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: "Inspection Completed?",
                      text:
                        "The inspection status of this property will be updated as completed",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        this.props.changeInspectionStatus({
                          id: this.props.prop_id,
                          inspection_status: "completed",
                        });
                        const mail_content = {
                          prop_id: this.props.prop_id,
                          owner_id: this.props.owner_id,
                          prop_name: this.props.prop_name,
                          address: this.props.address,
                          dateTime: new Date(Date.now()),
                        };
                        mailInspectionApprovedOwner(mail_content);
                        notifInspectionApprovedOwner(mail_content);
                        //  this.props.tabChange();
                      }
                    });
                  }}
                >
                  Inspection Completed
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
InspectionRequestCard.propTypes = {
  changeInspectionStatus: PropTypes.func,
  deleteInspectionCard: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  prop: state.prop,
});

export default connect(mapStateToProps, {
  changeInspectionStatus,
  deleteInspectionCard,
})(InspectionRequestCard);
