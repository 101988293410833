import React, { Component } from "react";
import MultiStepProgressBar from "components/Navbars/MultiStepProgressBar/MultiStepProgressBar";
import PhoneInputnew from "components/Inputs/PhoneInput/PhoneInput";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import { Row, Col, Button, FormGroup, Form, Modal, Input } from "reactstrap";
import DividerWithText from "components/Elements/DividerWithText";
import { saveNewPropKeyHolder } from "../../actions/propActions";
import { toggleRegPropOwner } from "../../actions/propActions";
import { registerProp } from "../../actions/propActions";
import NotificationRequestPopup from "components/Modals/NotificationRequestPopup/NotificationRequestPopup";
import { subscribeUser } from "../../notifications/subscription";
import {
  mailPropRegister,
  mailInspectionReceivedOwner,
} from "../../actions/mailActions/propMails";
import {
  notifInspectionReceivedOwner,
  notifPropRegister,
} from "../../actions/notifActions/propNotifs";

import { updateUserRole } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SignupCard from "components/Cards/SignupCard";
import LoginCard from "components/Cards/LoginCard";
import Swal from "sweetalert2";

class KeyDetails extends Component {
  constructor() {
    super();
    this.state = {
      key_holder: "igen",
      key_holder_phone: "",
      key_holder_name: "",
      not_igen_part: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
    this.props.toggleRegPropOwner(!this.state[state]);
  };

  componentDidUpdate(nextProps) {
    if (nextProps.prop.new_prop_owner !== null) {
    }
  }

  validateKey = () => {
    if (
      this.state.key_holder !== "igen" &&
      this.state.key_holder_phone === ""
    ) {
      return false;
    } else {
      return false;
    }
  };

  async onSubmit(e) {
    e.preventDefault();

    if (this.state.not_igen_part !== "" && this.state.key_holder === "igen") {
      Swal.fire({
        icon: "warning",
        title: "Select Keyholder",
        text: "Please select a Keyholder Type and enter details!",
      });
    } else if (
      this.state.key_holder !== "igen" &&
      this.state.key_holder_phone.length < 10
    ) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Phone Number!",
        text: "Please enter a valid Keyholder Phone number!",
      });
    } else if (
      this.state.key_holder !== "igen" &&
      this.state.key_holder_name.length < 2
    ) {
      Swal.fire({
        icon: "warning",
        title: "Enter Keyholder Name!",
        text: "Please enter the name of the Keyholder!",
      });
    } else {
      const khInfo = {
        key_holder: this.state.key_holder,
        key_holder_phone: this.state.key_holder_phone,
        key_holder_name: this.state.key_holder_name,
      };
      console.log("to be submitted", khInfo);

      await this.props.saveNewPropKeyHolder(khInfo);

      const new_prop = this.props.prop.new_prop_info;
      const new_owner = this.props.prop.new_prop_owner;
      const new_kh = this.props.prop.new_prop_key_holder;

      const newProp = {
        name: new_prop.prop_name,
        area: new_prop.area,
        land_area: new_prop.land_area,
        city: new_prop.city,
        locality: new_prop.locality,
        address: new_prop.address,
        furnishing: new_prop.furnish,
        bedroom: new_prop.bedrooms,
        bathroom: new_prop.bathrooms,
        parking: new_prop.parking,
        purpose: new_prop.purpose,
        available_from: new_prop.available_from,
        available_for: new_prop.available_for,
        rent_amount: new_prop.rent_amount,
        maintenance_amount: new_prop.maintenance_amount,
        pic_names: new_prop.pic_names,
        pic_urls: new_prop.pic_urls,
        remarks: new_prop.remarks,
        price: new_prop.price,
        is_active: new_prop.is_active,
        is_rented_out: new_prop.is_rented_out,
        is_sold: new_prop.is_sold,
        inspection_status: new_prop.inspection_status,

        property_type: new_prop.property_type,
        landmark: new_prop.landmark,
        water_source: new_prop.water_source,
        floor_number: new_prop.floor_number,
        parking_number: new_prop.parking_number,
        total_floor_number: new_prop.total_floor_number,
        offer_type: this.state.offer_type,

        // owner_id:this.props.prop.new_prop_owner.owner_id,
        owner_id: this.props.auth.user.id,
        owner_name: new_owner.name,
        owner_email: new_owner.email,
        owner_phone: new_owner.phone,

        key_holder: this.state.key_holder,
        key_holder_phone: this.state.key_holder_phone,
        key_holder_name: this.state.key_holder_name,

        dateTime: new Date(Date.now()),
      };
      this.props.registerProp(newProp);
      //mail admin: new property created
      mailPropRegister(newProp);
      notifPropRegister(newProp);
      //mail to owner: property submitted for inspection
      mailInspectionReceivedOwner(newProp);
      notifInspectionReceivedOwner(newProp);

      // change user role to owner
      const roleChange = {
        ...this.props.auth.user,
        role: "owner",
      };
      this.props.updateUserRole(roleChange);
      // this.props.onProceed();

      if (!this.props.auth.user.notifStatus) {
        this.toggleModal("notifReq");
      } else {
        this.props.onProceed();
      }
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    let notIgen = (
      <>
        <OptionSelector
          label="Select the Keyholder"
          option1="HOUSE OWNER"
          option2="CARETAKER/ NEIGHBOUR/ FRIEND"
          option3="APARTMENT SECURITY"
          onOptionClick={(val) => {
            switch (val) {
              case 1:
                this.setState({ key_holder: "House Owner" });
                break;
              case 2:
                this.setState({ key_holder: "Caretaker" });
                break;
              case 3:
                this.setState({ key_holder: "Apartment Security" });
                break;
              default:
                this.setState({ key_holder: "" });
            }
          }}
        />
        <div className="mt-4 mb-2">
          <small className="text-muted text-uppercase">
            Key Holder's Details
          </small>
        </div>
        <PhoneInputnew
          onNumberChange={(value) => {
            this.setState({ key_holder_phone: value });
          }}
        />
        <Input
          className="mb-3 mt-4"
          placeholder="Keyholder's Name"
          type="text"
          name="key_holder_name"
          //value={this.state.key_holder_name}
          onChange={this.onChange}
        />
      </>
    );
    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <MultiStepProgressBar step="3" />
          <h3 className="text-black mt-4 mt-lg-4 mb-lg-4 text-center">
            Key Details
          </h3>
          <Row>
            <Col
              sm="12"
              md="6"
              lg="6"
              className="mt-1"
              style={{ margin: "0px auto" }}
            >
              <FormGroup>
                <OptionSelector
                  label="Ready to hand over the keys to iGen?"
                  option1="YES"
                  option2="NO"
                  // option3="MAYBE"
                  default="2"
                  def_option={1}
                  onOptionClick={(val) => {
                    switch (val) {
                      case 1:
                        this.setState({
                          key_holder: "igen",
                          not_igen_part: "",
                        });
                        break;
                      case 2:
                        this.setState({ not_igen_part: notIgen });
                        break;
                      case 3:
                        this.setState({ not_igen_part: notIgen });
                        break;
                      default:
                        this.state.key_holder = "";
                    }
                  }}
                />
                <div className="mt-3"></div>
                {/* <DividerWithText content="OR" /> */}
                {this.state.not_igen_part}
                <div className="d-flex flex-row">
                  <div className="d-flex flex-grow-1 ml-2 mr-3">
                    <Button
                      className="mt-5 btn-round btn-outline-primary"
                      type="button"
                      onClick={() => this.props.onBack()}
                      style={{
                        margin: "0px auto",
                        minWidth: "140px",
                        width: "100%",
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="d-flex flex-grow-1 mx-3">
                    <Button
                      className="mt-5 btn-round"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        if (
                          !(
                            this.state.key_holder !== "igen" &&
                            this.state.key_holder_phone === ""
                          )
                        ) {
                          if (!this.props.auth.isAuthenticated) {
                            this.toggleModal("defaultModal");
                          }
                        } else {
                          Swal.fire({
                            icon: "warning",
                            title: "Invalid Phone Number!",
                            text: "Please enter a valid Keyholder Phone number!",
                          });
                        }
                      }}
                      style={{
                        margin: "0px auto",
                        minWidth: "140px",
                        width: "100%",
                      }}
                    >
                      Finish
                    </Button>
                  </div>
                </div>
              </FormGroup>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
              >
                <SignupCard
                  onDone={() => {
                    this.toggleModal("defaultModal");
                  }}
                  onLoginClick={() => {
                    this.toggleModal("logModal");
                    this.toggleModal("defaultModal");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.logModal}
                toggle={() => this.toggleModal("logModal")}
              >
                <LoginCard
                  onDone={() => {
                    setTimeout(() => {
                      if (this.props.auth.isAuthenticated) {
                        this.toggleModal("logModal");
                      }
                    }, 1500);
                  }}
                  onSignupClick={() => {
                    this.toggleModal("defaultModal");
                    this.toggleModal("logModal");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.notifReq}
                toggle={() => this.toggleModal("notifReq")}
                style={{ zIndex: 100 }}
              >
                <NotificationRequestPopup
                  clickAction={() => {
                    subscribeUser(this.props.auth.user.id);
                    this.toggleModal("notifReq");
                    window.location = "/owner-dashboard";
                  }}
                  clickAction2={() => {
                    this.toggleModal("notifReq");
                    window.location = "/owner-dashboard";
                  }}
                />
              </Modal>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
  errors: state.errors,
});
KeyDetails.propTypes = {
  saveNewPropKeyHolder: PropTypes.func,
  updateUserRole: PropTypes.func,
  registerProp: PropTypes.func,
  toggleRegPropOwner: PropTypes.func,
  auth: PropTypes.object,
  prop: PropTypes.object,
};
export default connect(mapStateToProps, {
  saveNewPropKeyHolder,
  registerProp,
  toggleRegPropOwner,
  updateUserRole,
})(KeyDetails);
