import React, { Component } from "react";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, UncontrolledCarousel, Container } from "reactstrap";
import PropertyInfoCard from "components/Cards/PropertyInfoCard/PropertyInfoCard";
import Navbar from "components/Navbars/DarkNavbar.jsx";
import Footer from "components/Footers/Footer/Footer";
import {
  getPropByUrl,
  getPropByKey,
  getAuthToken,
} from "../../actions/propActions";
import { withRouter } from "react-router-dom";

class Property extends Component {
  constructor() {
    super();
    this.state = { propId: "" };
  }

  async componentWillMount() {
    const handle = await this.props.match.params.id;
    // await this.props.getPropByUrl(`/api/properties/prop/${handle}`);
    await this.props.getAuthToken();
    await this.props.getPropByKey(handle);
  }
  async componentDidMount() {
    const handle = this.props.match.params.id;
    this.setState({ propId: handle });
    // await this.props.getPropByUrl(`/api/properties/prop/${handle}`);
    await this.props.getAuthToken();
    await this.props.getPropByKey(handle);
  }
  componentWillReceiveProps() {
    this.setState({ propId: this.props.match.params.id });
  }

  render() {
    const { property, loading } = this.props.prop;
    console.log("this.props", this.props);
    let propInfoCard;
    console.log("property", property);
    var images = [];

    const items = [
      {
        src: "images/fallback-property-image.svg",
        altText: "",
        caption: "",
        header: "",
      },
    ];
    if (
      property === null ||
      loading ||
      property === undefined ||
      property === {}
    ) {
      propInfoCard = <Spinner />;
    } else {
      for (var x = 0; x < property.Media.length; x++) {
        images = images.concat({
          src: property.Media[x].MediaURL,
          altText: "",
          caption: "",
          header: "",
        });
      }

      propInfoCard = (
        <PropertyInfoCard
          user_role="tenant"
          id={this.state.propId} //{property.ListingKey}
          area={property.LivingArea}
          name={property.UnparsedAddress}
          // rent={property.rent_monthly_basic}
          remarks={property.PublicRemarks}
          purpose={property.PropertySubType}
          bedroom={property.BedroomsTotal}
          bathroom={property.BathroomsTotalInteger}
          parking={property.ParkingTotal}
          locality={property.UnparsedAddress}
          city={property.City}
          price={property.ListPrice}
          // land_area={property.land_area}
          rent_detail_monthly_basic={property.TotalActualRent}
          // rent_detail_monthly_maintenance={property.rent_monthly_maintenance}
          available_from={
            property.AvailabilityDate ? property.AvailabilityDate : "N/A"
          }
          available_for={property.PropertySubType}
          // furnishing={property.furnishing}
          address={property.UnparsedAddress + "," + property.City}
          // requested={property.requested}
          // inspection_status={property.inspection_status}
          // landmark={property.landmark}
          property_type={property.PropertySubType}
          water_source={property.WaterBodyName}
          // floor_number={property.floor_number}
          parking_number={property.ParkingTotal}
          // total_floor_number={property.total_floor_number}
        />
      );
      // propInfoCard = null;
      var mapStr = `https://www.google.com/maps/embed/v1/place?q=${property.StreetNumber}+${property.StreetName}+${property.StreetSuffix},+${property.City},+${property.StateOrProvince}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;
      console.log("mapStr", mapStr);
    }
    return (
      <>
        <Navbar />
        <Container className="container-lg py-md" style={{ minHeight: "90vh" }}>
          <Row className="mt-5 mb-5">
            <Col lg="6">
              <div className="overflow-hidden rounded">
                <UncontrolledCarousel
                  items={images.length < 1 ? items : images}
                  interval={false}
                />
              </div>
            </Col>
            {propInfoCard}
          </Row>
        </Container>

        <Container>
          <div
            style={{
              overflow: "hidden",
              maxWidth: "100%",
              width: "100%",
              height: "500px",
            }}
          >
            <div
              id="embed-map-canvas"
              style={{ height: "100%", width: "100%", maxWidth: "100%" }}
            >
              <iframe
                title="Gmap"
                style={{ height: "100%", width: "100%", border: "0" }}
                frameborder="0"
                //src="https://www.google.com/maps/embed/v1/place?q=340+Dunsmore+Lane,+Barrie,+ON,+Canada&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                // src="https://www.google.com/maps/embed/v1/place?q=90+DEAN+Avenue,+Barrie,+Ontario&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                src={mapStr}
              ></iframe>
            </div>
            <a
              class="google-map-code-enabler"
              href="https://kbj9qpmy.com/bp"
              id="grab-maps-authorization"
            >
              Internet Provider
            </a>
          </div>
        </Container>

        <Footer />
      </>
    );
  }
}
Property.propTypes = {
  getPropByUrl: PropTypes.func,
  getPropByKey: PropTypes.func,
  getAuthToken: PropTypes.func,
  prop: PropTypes.object.isRequired,
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getPropByUrl,
  getPropByKey,
  getAuthToken,
})(withRouter(Property));
