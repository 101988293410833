import React, { Component } from "react";
import TenantDashPropCardNew from "components/Cards/TenantDashPropCardNew/TenantDashPropCardNew";
import { Container, Row, Col } from "reactstrap";
import DarkNavbar from "components/Navbars/DarkNavbar";
import TenantPropProgress from "views/TenantPropProgress/TenantPropProgress";
import { getVisitDash } from "../../actions/visitActions";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class TenantDashboard extends Component {
  constructor() {
    super();
    this.state = {
      progress_component: "",
      tenant_dash_card_lg: 12,
    };
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      window.location = "/login-page";
    }

    const tenObject = {
      tenant_id: this.props.auth.user.id,
    };
    this.props.getVisitDash(tenObject);
  }

  render() {
    const { visitDashs, loading } = this.props.visit;
    let pc = (
      <Col sm="12" md="12" lg="4" id="tenant_progress" className="mt-3">
        <TenantPropProgress prop_name="Trinity Mercury" />
      </Col>
    );
    let tenDashs;

    if (visitDashs === null || loading) {
      tenDashs = <Spinner />;
    } else {
      if (Object.keys(visitDashs).length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        tenDashs = visitDashs.map((v) => (
          <TenantDashPropCardNew
            tenant_id={this.props.auth.user.id}
            area={v.prop_area}
            prop_id={v.prop_id}
            visit_status={v.visit_status}
            owner_email={v.owner_email}
            owner_name={v.owner_name}
            owner_phone={v.owner_phone}
            owner_id={v.owner_id}
            consideration={v.visit_consideration}
            visit_id={v.visit_id}
            prop_name={v.prop_name}
            ten_name={v.ten_name}
            rent={v.prop_rent}
            price={v.prop_price}
            purpose={v.prop_purpose}
            bedroom={v.prop_bedrooms}
            bathroom={v.prop_bathrooms}
            place={v.prop_locality}
            city={v.prop_city}
            requested={v.prop_requested}
            timeSlot={v.visit_time}
            pmMobile="+91 6282706378"
            bookDate={v.visit_date}
            hero_image={
              Array.isArray(v.gallery) && v.gallery.length
                ? v.gallery[0]
                : default_pic
            }
            onTenantDashCardClick={() => {}}
          />
        ));
      }
    }

    return (
      <>
        <DarkNavbar />
        <Container className="py-lg" style={{ minHeight: "90vh" }}>
          <h3 className="text-primary mt-3 mb-3 mb-lg-4">My Visits</h3>
          <Row>
            <Col
              sm="12"
              md="12"
              lg={this.state.tenant_dash_card_lg}
              id="tenant_dash_card"
            >
              {tenDashs}
            </Col>
            {this.state.progress_component}
          </Row>
        </Container>
      </>
    );
  }
}
TenantDashboard.propTypes = {
  getVisitDash: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  visit: state.visit,
  auth: state.auth,
});
export default connect(mapStateToProps, { getVisitDash })(TenantDashboard);
