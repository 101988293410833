import axios from "../client";

const convertedVapidKey = urlBase64ToUint8Array(
  "BKFMoMsPdHoCuypsQ4iT_a3wiJAhOO9VZyrfgu-PhELaOVCjj3VzCUqse8DCCtZujZfyh4hdqwcbSu9PtI4jUP0"
);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription, user_id) {
  // return fetch(`http://localhost:5000/api/notifications/sendNotif`, {
  var sub = { subscription };
  var subscriptionObject = JSON.stringify({ ...sub, user_id: user_id });
  console.log("subscriptionObject", subscriptionObject);
  return axios
    .post("/api/notifications/newSub", JSON.parse(subscriptionObject))
    .then((res) => console.log("res of sendSubscription", res))
    .catch((err) => {
      console.log("err", err);
    });
}

export function subscribeUser(user_id) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }

        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            if (existedSubscription === null) {
              console.log("No subscription detected, make a request.");
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then(function (newSubscription) {
                  console.log("New subscription added.");
                  sendSubscription(newSubscription);
                })
                .catch(function (e) {
                  if (Notification.permission !== "granted") {
                    console.log("Permission was not granted.");
                  } else {
                    console.error(
                      "An error ocurred during the subscription process.",
                      e
                    );
                  }
                });
            } else {
              console.log("Existed subscription detected.");
              sendSubscription(existedSubscription, user_id);
            }
          });
      })
      .catch(function (e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
}
