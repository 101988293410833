import React, { Component } from "react";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  UncontrolledCarousel,
  Container,
  Button,
  Input,
} from "reactstrap";
import PropertyInfoCard from "components/Cards/PropertyInfoCard/PropertyInfoCard";
import Navbar from "components/Navbars/PMNavbar";
import Footer from "components/Footers/Footer/Footer";
import {
  getPropByUrl,
  updateIsActive,
  updateKeyReceived,
  deleteProperty,
} from "../../actions/propActions";
import { deleteVisit } from "../../actions/visitActions";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { mailKeyReceivedOwner } from "../../actions/mailActions/propMails";
import { notifKeyReceivedOwner } from "../../actions/notifActions/propNotifs";
import { deleteImages } from "../../actions/imageActions";

class Property extends Component {
  constructor() {
    super();
    this.state = { propId: "", is_active: true, key_received: false };
    this.onChange = this.onChange.bind(this);
  }

  async componentWillMount() {
    const handle = await this.props.match.params.id;
    await this.props.getPropByUrl(`/api/properties/prop/${handle}`);
  }
  async componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    }
    const handle = this.props.match.params.id;
    this.setState({ propId: handle });
    await this.props.getPropByUrl(`/api/properties/prop/${handle}`);
  }

  componentWillReceiveProps() {
    this.setState({ propId: this.props.match.params.id });
    if (this.props.prop.property) {
      if (
        !this.props.prop.property.loading &&
        this.props.prop.property.is_active !== undefined
      )
        this.setState(
          {
            is_active: this.props.prop.property.is_active === "true",
            key_received: this.props.prop.property.key_received === "true",
          },
          () => {
            console.log("isActive after setstate ", this.state.is_active);
          }
        );
    }
  }
  onChange(e) {
    switch (e.target.name) {
      case "is_active":
        if (this.props.prop.property.inspection_status !== "completed") {
          Swal.fire({
            icon: "warning",
            title: "Not Possible",
            text:
              "This operation is possible only after the inspection is completed!",
          });
        } else {
          this.setState({ is_active: !this.state.is_active });

          var dat = {
            id: this.props.prop.property._id,
            is_active: (!this.state.is_active).toString(),
          };

          updateIsActive(dat);
        }

        break;
      case "key_received":
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.setState({ key_received: !this.state.key_received });
            var dat = {
              id: this.props.prop.property._id,
              key_received: this.state.key_received.toString(),
            };

            updateKeyReceived(dat);

            var mailContent = {
              prop_name: this.props.prop.property.name,
              owner_name: this.props.prop.property.owner_name,
              owner_id: this.props.prop.property.owner_id,
              owner_email: this.props.prop.property.owner_email,
              dateTime: new Date(Date.now()),
            };
            mailKeyReceivedOwner(mailContent);
            notifKeyReceivedOwner(mailContent);
          }
        });
        break;
      default:
        break;
    }
  }
  render() {
    const { property, loading } = this.props.prop;
    let propInfoCard;
    var images = [];
    let isActiveCheckbox;
    const items = [
      {
        src: "images/fallback-property-image.svg",
        altText: "",
        caption: "",
        header: "",
      },
    ];

    if (
      property === null ||
      loading ||
      property === undefined ||
      property === {}
    ) {
      propInfoCard = <Spinner />;
      isActiveCheckbox = <Spinner />;
    } else {
      for (var x = 0; x < property.gallery.length; x++) {
        images = images.concat({
          src: property.gallery[x],
          altText: "",
          caption: "",
          header: "",
        });
      }
      propInfoCard = (
        <PropertyInfoCard
          user_role="pm"
          id={property.id}
          area={property.area}
          name={property.name}
          remarks={property.remarks}
          rent={property.rent_monthly_basic}
          bedroom={property.bedroom}
          bathroom={property.bathroom}
          parking={property.parking}
          locality={property.locality}
          city={property.city}
          purpose={property.purpose}
          price={property.price}
          land_area={property.land_area}
          rent_detail_monthly_basic={property.rent_monthly_basic}
          rent_detail_monthly_maintenance={property.rent_monthly_maintenance}
          available_from={property.available_from}
          available_for={property.available_for}
          furnishing={property.furnishing}
          address={property.address}
          requested={property.requested}
          inspection_status={property.inspection_status}
          owner_name={property.owner_name}
          owner_email={property.owner_email}
          owner_phone={property.owner_phone}
          kh_name={property.kh_name}
          kh_phone={property.kh_phone}
          landmark={property.landmark}
          property_type={property.property_type}
          water_source={property.water_source}
          floor_number={property.floor_number}
          total_floor_number={property.total_floor_number}
          parking_number={property.parking_number}
        />
      );
      // propInfoCard = null;
      isActiveCheckbox = (
        <Row className="mt-5">
          <label className="custom-toggle ml-3">
            <Input
              type="checkbox"
              name="is_active"
              onChange={this.onChange}
              checked={!this.state.is_active}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
          <h6 className="text-primary d-inline pl-2"> Hide from Listing</h6>
        </Row>
      );
      var keyReceivedCheckbox = (
        <Row className="mt-1">
          <label className="custom-toggle ml-3">
            <Input
              type="checkbox"
              name="key_received"
              onChange={this.onChange}
              checked={this.state.key_received}
              disabled={this.state.key_received}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>

          <h6 className="text-primary d-inline pl-2">Property Key Received</h6>
        </Row>
      );
    }
    return (
      <>
        <Navbar />

        <Container className="container-lg py-md" style={{ minHeight: "90vh" }}>
          <Row md="1" className="mt-3 ">
            {" "}
            <Button
              className="btn-icon btn-neutral btn-round ml-auto my-2 mr-3"
              color="primary"
              type="button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/pm-edit-property",
                });
                //window.location = "/pm-edit-property";
              }}
            >
              <span className="btn-inner--icon">
                <i className="fa fa-edit mr-0" />
              </span>
              <span className="btn-inner--text">Edit Property</span>
            </Button>{" "}
            <Button
              className="btn-icon btn-neutral btn-round  my-2 mr-1"
              color="primary"
              type="button"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "This property and visits will be Deleted permanantly!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    console.log("this.props", this.props.prop.property._id);
                    // var dat2 = {
                    //   prop_id: this.props.prop.property._id,
                    // };
                    // deleteVisit(dat2);
                    var dat1 = {
                      _id: this.props.prop.property._id,
                    };

                    deleteProperty(dat1);
                    var pic_dat = {
                      del_imgs: this.props.prop.property.gallery,
                    };
                    deleteImages(pic_dat);
                  }
                });
              }}
            >
              <span className="btn-inner--icon">
                <i className="fa fa-trash mr-0" />
              </span>
              <span className="btn-inner--text">Delete Property</span>
            </Button>
          </Row>

          <Row className="mt-2 mb-5">
            <Col lg="6">
              <div className="overflow-hidden rounded">
                <UncontrolledCarousel
                  items={images.length < 1 ? items : images}
                  interval={false}
                />
              </div>
              {isActiveCheckbox}
              {keyReceivedCheckbox}
            </Col>

            {propInfoCard}
          </Row>
          <Col lg="6">
            {/*<Row>
              <label className="custom-toggle">
                <input type="checkbox" />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <h6 className="text-primary d-inline pl-2">
                {" "}
                Mark as Rented Out
              </h6>
            </Row> */}
          </Col>
        </Container>
        <Footer />
      </>
    );
  }
}
Property.propTypes = {
  getPropByUrl: PropTypes.func,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});
export default connect(mapStateToProps, { getPropByUrl })(withRouter(Property));
