import React, { Component } from "react";
import PropertyCard from "./PropertyCard";
import { Button, Col } from "reactstrap";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOwnerProps } from "../../actions/propActions";

class OwnerDashboardCard extends Component {
  componentDidMount() {
    const ownerObject = { "owner.id": this.props.auth.user.id };
    console.log("ownerId", ownerObject);
    this.props.getOwnerProps(ownerObject);
  }
  render() {
    const { owner_properties, loading } = this.props.prop;
    console.log("owner props", owner_properties);
    let propertiesContent;
    if (owner_properties === null || loading) {
      propertiesContent = <Spinner />;
    } else {
      if (Object.keys(owner_properties).length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        propertiesContent = owner_properties.map(p => (
          <>
            <Col sm="12" lg="4" md="4" xl="4">
              <PropertyCard
                id={p._id}
                area={p.area}
                name={p.name}
                rent={p.rent_monthly_basic}
                price={p.price}
                purpose={p.purpose}
                bedroom={p.experience.bedroom}
                bathroom={p.experience.bathroom}
                place={p.locality}
                city={p.city}
                requested={p.requested}
                inspection_status={p.inspection_status}
                hero_image={
                  Array.isArray(p.gallery) && p.gallery.length
                    ? p.gallery[0] //this.getImage(p.gallery[0])
                    : default_pic
                } //no++
              >
                <div className="text-center pb-2">
                  <Button
                    onClick={() => {
                      window.location = `/property${p._id}`;
                    }}
                    className="btn-1 ml-2 mr-2 mb-2"
                    color="primary"
                    outline
                    type="button"
                    style={{ width: "40%" }}
                  >
                    Details
                  </Button>
                  <Button
                    onClick={() => {
                      window.location = `/visitor-activities${p._id}`;
                    }}
                    className="btn-1 mb-2 mr-2"
                    color="primary"
                    type="button"
                    style={{ width: "40%" }}
                  >
                    Activities
                  </Button>
                </div>
              </PropertyCard>
            </Col>
          </>
        ));
      }
    }
    return <>{propertiesContent}</>;
  }
}
OwnerDashboardCard.propTypes = {
  getOwnerProps: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  prop: state.prop,
  auth: state.auth
});
export default connect(mapStateToProps, { getOwnerProps })(OwnerDashboardCard);
