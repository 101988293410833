import React, { Component } from "react";
import VisitorInfoCard from "../../components/Cards/VisitorInfoCard";
import DarkNavbar from "components/Navbars/DarkNavbar";
import { Container, Row, Col } from "reactstrap";
import { getPropVisits } from "../../actions/visitActions";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Footer from "components/Footers/Footer/Footer";

class TenantActivities extends Component {
  state = {
    plainTabs: 1,
  };

  componentDidMount() {
    var url = this.props.location.pathname;
    const handle = url.substring(19);
    const propObject = {
      prop_id: handle,
    };

    console.log(propObject);
    this.props.getPropVisits(propObject);
  }
  render() {
    const no_visits = (
      <>
        <div className="mx-auto">
          <img
            className="mx-auto d-block mb-2"
            alt="no visits"
            src={require("./../../assets/img/illustrations/no-visitors.svg")}
            style={{
              width: "300px",
              height: "auto",
              marginTop: "-150px",
            }}
          />
          <h5 className="text-center">No Visits Yet :(</h5>
        </div>
      </>
    );
    const { ownerVisits, loading } = this.props.visit;
    let ownerVisitData;
    if (ownerVisits === null || loading) {
      ownerVisitData = (
        <div className="mx-auto">
          <Spinner />
        </div>
      );
    } else {
      if (Object.keys(ownerVisits).length > 0) {
        ownerVisitData = ownerVisits.map((v) => (
          <Col sm="12" md="6" lg="4">
            <VisitorInfoCard
              tenant={v.ten_name}
              bookDate={v.visit_date}
              timeSlot={v.visit_time}
              pmMobile="+91 8592899639"
              status={v.visit_consideration}
              depositStatus={v.visit_deposit_received}
            />
          </Col>
        ));
      } else {
        ownerVisitData = no_visits;
      }
      // ownerVisitData = null;
    }
    return (
      <div>
        <DarkNavbar />
        <Container className="py-lg" style={{ minHeight: "90vh" }}>
          <h3 className="text-primary mt-4 mb-3 mb-lg-4">Visit Requests</h3>
          <Row>{ownerVisitData}</Row>
          {/* {Object.keys(ownerVisits).length < 1 ? no_visits : ""} */}
        </Container>
        <Footer />
      </div>
    );
  }
}
TenantActivities.propTypes = {
  getPropVisits: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  visit: state.visit,
  auth: state.auth,
});
export default connect(mapStateToProps, { getPropVisits })(TenantActivities);
