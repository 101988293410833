import React, { Component } from "react";
import Navbar from "../../components/Navbars/DarkNavbar";
import { Container, Row, Col } from "reactstrap";
import NotificationCard from "components/Cards/NotificationCard";
import {
  getNotifsById,
  updateNotif,
  clearAllNotif,
} from "../../actions/notifActions/notifActions";
import { emptyUnreadNotifs } from "../../actions/authActions";

import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import timeSince from "../../utils/timeSince";
import Swal from "sweetalert2";

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      ids: [],
    };
    this.id_arr = [];
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      window.location = "/login-page";
    } else {
      this.props.getNotifsById(this.props.auth.user.id);
      this.props.emptyUnreadNotifs(this.props.auth.user);
    }
  }
  componentDidUpdate() {
    this.id_arr = [];
    var noti = this.props.notif.notifs;
    if (noti !== undefined) {
      if (noti.length > 0) {
        Array.from(noti).forEach((n) => {
          this.id_arr = this.id_arr.concat(n._id);
        });
      }
    }
  }
  render() {
    const no_visits = (
      <>
        <div className="mx-auto my-lg py-lg">
          <img
            className="mx-auto d-block mb-2"
            alt="no notifications"
            src={require("./../../assets/img/illustrations/no-notifs.svg")}
            style={{
              width: "300px",
              height: "auto",
              marginTop: "-150px",
            }}
          />
          <h5 className="text-center mt-3">No Notifications Yet :(</h5>
        </div>
      </>
    );
    const { notifs, loading } = this.props.notif;
    let notifContent;

    if (notifs === null || notifs === undefined || loading) {
      notifContent = <Spinner />;
      //notifContent = no_visits;
    } else {
      if (Object.keys(notifs).length > 0) {
        notifContent = notifs.map((n) =>
          n.is_active === "true" ? (
            <Row className="mt-3">
              <Col>
                <NotificationCard
                  title={n.title}
                  description={n.description}
                  timeSince={timeSince(new Date(n.client_date))}
                  onCloseClick={() => {
                    this.props.updateNotif(n._id, n.user_id, {
                      is_active: "false",
                    });
                  }}
                  onClick={() => {
                    window.location = n.on_click;
                  }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )
        );
      } else {
        notifContent = no_visits;
      }
    }
    return (
      <div>
        <Navbar />
        <Container className="py-md">
          <Row>
            <Col md="8" xs="8">
              <h3 className="text-primary mt-lg-5 mt-3 mb-3 mb-lg-4">
                Notifications
              </h3>
            </Col>
            <Col md="4" xs="4">
              <p
                className="text-danger mt-lg-5 float-right"
                style={{ marginTop: "1.7rem", cursor: "pointer" }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "All the notifications will be deleted!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      this.props.clearAllNotif(
                        this.id_arr,
                        this.props.auth.user.id,
                        {
                          is_active: "false",
                        }
                      );
                    }
                  });
                }}
              >
                Clear All
              </p>
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col>
              <NotificationCard
                title="Inspection Successful"
                timeNumerical="2"
                timeUnit="Days"
              />
            </Col>
          </Row> */}
          {notifContent}
        </Container>
      </div>
    );
  }
}

Notifications.propTypes = {
  getNotifsById: PropTypes.func,
  updateNotif: PropTypes.func,
  clearAllNotif: PropTypes.func,
  emptyUnreadNotifs: PropTypes.func,
  auth: PropTypes.object,
  notif: PropTypes.object,
};

const mapStateToProps = (state) => ({
  notif: state.notif,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  emptyUnreadNotifs,
  getNotifsById,
  updateNotif,
  clearAllNotif,
})(Notifications);
