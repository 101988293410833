import React, { Component } from "react";

export default class VisitorInfoCard extends Component {
  render() {
    return (
      <>
        <div
          className="shadow-sm shadow-lg--hover rounded my-2"
          style={{ padding: "20px 40px" }}
        >
          <div className="my-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Tenant Name</small>
            </div>
            <div className="d-block">{this.props.tenant}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Date of Visit</small>
            </div>
            <div className="d-block">{this.props.bookDate}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Time slot</small>
            </div>
            <div className="d-block text-uppercase">{this.props.timeSlot}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Property Manager Contact</small>
            </div>
            <div className="d-block">{this.props.pmMobile}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Status</small>
            </div>
            <div className="d-block text-capitalize">
              {this.props.status.replace("_", " ")}
            </div>
          </div>
          {/* <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Status</small>
            </div>
            <div className="py-1">
              <button className="btn btn-primary btn-round pt-0 pb-1 px-3">
                <small style={{ fontSize: "0.4em" }}>{this.props.status}</small>
              </button>
            </div>
          </div> */}
          <div className="mb-2">
            <div className="d-block mt-2 text-muted">
              <small className="text-uppercase">Holding Deposit Status</small>
            </div>
            <div className="d-block">
              {this.props.depositStatus ? "Received" : "Not Received"}
            </div>
          </div>
        </div>
      </>
    );
  }
}
