import React, { Component } from "react";
//import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";

import Landing from "views/examples/Landing.jsx";
import Login from "views/examples/Login.jsx";
import Profile from "views/pages/Profile";
import Register from "views/examples/Register.jsx";
import Information from "views/pages/Info.jsx";
import Properties from "views/pages/Properties";
import Property from "views/pages/Property";
import PropertyOwnerView from "views/pages/PropertyOwnerView";
import AddProperty from "views/pages/AddProperty";
import EditProperty from "views/pages/EditProperty/EditProperty";
import TenantDashboard from "views/pages/TenantDashboard";
import PropertyViews from "views/pages/PropertyViews";
import OwnerDashboard from "views/pages/OwnerDashboard";
import Tenant from "views/pages/LandingTenant/LandingTenant";
import Landlord from "views/pages/LandingLandlord/LandingLandlord";
import TenantActivities from "views/pages/TenantActivities";
import Index from "views/Index";
import PropertyManagerLogin from "views/pages/PropertyManagerLogin";
import PropertyManagerHome from "views/pages/PropertyManagerHome";
import PmProperty from "views/pages/PmProperty";
import ChangePswd from "views/pages/ChangePassword";
import EmptyDashboard from "views/pages/DashboardEmpty";
import store from "./store";
//import { PersistGate } from "redux-persist/integration/react";
//import configureStore from "./configureStore";
import VisitRequests from "views/pages/VisitRequests";
import InspectionRequests from "views/pages/InspectionRequests";
import DateInput from "components/Inputs/DateInput";
import Notifications from "views/pages/Notifications";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import Terms from "views/pages/Terms";
import About from "views/pages/About/About";

import PropertyManagerDashboard from "views/pages/PropertyManagerDashboard";
//const { persistor } = configureStore();
//const { persistor, store } = configureStore();
//const onBeforeLift = () => {
// take some action before the gate lifts
//};

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // TODO: Clear current Profile

    // Redirect to login
    window.location.href = "/login-page";
  }
}
if (localStorage.userWithRoleToken) {
  store.dispatch(
    setCurrentUser(JSON.parse(localStorage.getItem("userWithRoleToken")))
  );
}
if (localStorage.userWithNotifSubscription) {
  store.dispatch(
    setCurrentUser(
      JSON.parse(localStorage.getItem("userWithNotifSubscription"))
    )
  );
}
if (localStorage.userWithUnreadNotif) {
  store.dispatch(
    setCurrentUser(JSON.parse(localStorage.getItem("userWithUnreadNotif")))
  );
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate
          loading={null}
          onBeforeLift={onBeforeLift}
          persistor={persistor}
        > */}
        <BrowserRouter>
          <Switch>
            <Route path="/" exact render={(props) => <About {...props} />} />
            <Route
              path="/search/:id"
              exact
              render={(props) => <Properties {...props} />}
            />
            <Route
              path="/tenant"
              exact
              render={(props) => <Tenant {...props} />}
            />
            <Route
              path="/owner"
              exact
              render={(props) => <Landlord {...props} />}
            />
            <Route
              path="/policy"
              exact
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              path="/terms"
              exact
              render={(props) => <Terms {...props} />}
            />
            <Route
              path="/about-us"
              exact
              render={(props) => <About {...props} />}
            />

            <Route
              path="/landing-page"
              exact
              render={(props) => <Landing {...props} />}
            />
            <Route
              path="/notifications"
              exact
              render={(props) => <Notifications {...props} />}
            />

            {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
            <Route
              path="/visit-requests"
              exact
              render={(props) => <VisitRequests {...props} />}
            />
            <Route
              path="/dateinputs7736"
              exact
              render={(props) => <DateInput {...props} />}
            />
            <Route
              path="/inspection-requests"
              exact
              render={(props) => <InspectionRequests {...props} />}
            />
            <Route
              path="/properties"
              exact
              render={(props) => <Properties {...props} />}
            />
            <Route
              path="/property:id"
              exact
              render={(props, match) => <Property {...props} match={match} />}
            />
            <Route
              path="/property-owner"
              exact
              render={(props) => <PropertyOwnerView {...props} />}
            />
            <Route
              path="/login-page"
              exact
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/register-page"
              exact
              render={(props) => <Register {...props} />}
            />
            <Route
              path="/info"
              exact
              render={(props) => <Information {...props} />}
            />
            <Route
              path="/add-property"
              exact
              render={(props) => <AddProperty {...props} />}
            />
            <Route
              path="/dashboard"
              exact
              render={(props) => <TenantDashboard {...props} />}
            />
            <Route
              path="/prop-visits"
              exact
              render={(props) => <PropertyViews {...props} />}
            />
            <Route
              path="/owner-dashboard"
              exact
              render={(props) => <OwnerDashboard {...props} />}
            />
            <Route
              path="/components"
              exact
              render={(props) => <Index {...props} />}
            />
            <Route
              path="/profile"
              exact
              render={(props) => <Profile {...props} />}
            />
            <Route
              path="/pm-login"
              exact
              render={(props) => <PropertyManagerLogin {...props} />}
            />
            <Route
              path="/visitor-activities:id"
              exact
              render={(props, match) => (
                <TenantActivities {...props} match={match} />
              )}
            />
            <Route
              path="/pm"
              exact
              render={(props) => <PropertyManagerHome {...props} />}
            />
            <Route
              path="/pm-property:id"
              exact
              render={(props, match) => <PmProperty {...props} match={match} />}
            />
            <Route
              path="/pm-edit-property"
              exact
              render={(props) => <EditProperty {...props} />}
            />
            <Route
              path="/dAdXdEdF:id"
              exact
              render={(props, match) => <ChangePswd {...props} match={match} />}
            />
            <Route
              path="/empty-dashboard"
              exact
              render={(props) => <EmptyDashboard {...props} />}
            />
            <Route
              path="/privacy-policy"
              exact
              render={(props) => <Landing {...props} />}
            />
            <Route
              path="/pm-dashboard"
              exact
              render={(props) => <PropertyManagerDashboard {...props} />}
            />
            {/* <Redirect to="/" /> */}
          </Switch>
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}
export default App;
