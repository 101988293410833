import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  loginUser,
  forgotPswdPage,
  socialLogin,
} from "../../actions/authActions";
import Swal from "sweetalert2";
import SocialButton from "../../components/Common/SocialButton";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class LoginCard extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      //this.props.history.push("/dashboard");
      window.location = "/properties";
    }
  }
  componentDidUpdate(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.onDone();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // this.props.history.push("/dashboard");
      //.location = "/dashboard";
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ errors: {} });
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData, this.props.return_url);

    if (this.props.onSignupClick) {
      //this.props.o();
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: {} });
  }
  handleFacebookLogin = (user) => {
    console.log("social media loggin success ", user);
    console.log("user._profile :", user._profile);
    const newUser = {
      name: user._profile.name,
      email: user._profile.email,
      phone: "",
    };
    this.props.socialLogin(newUser, this.props.return_url);
  };

  handleFacebookLoginFailure = (err) => {
    console.error("social media loggin failure", err);
  };
  handleGoogleLogin = (user) => {
    console.log("social media loggin success ", user);
    console.log("user._profile :", user._profile);
    const newUser = {
      name: user._profile.name,
      email: user._profile.email,
      phone: "",
    };
    this.props.socialLogin(newUser, this.props.return_url);
  };

  handleGoogleLoginFailure = (err) => {
    console.error("social media loggin failure", err);
  };

  render() {
    var page_state = (
      <a className="text-light" href="register-page">
        <small>Create new account</small>
      </a>
    );
    var card_state = (
      <a
        className="text-light"
        style={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={this.props.onSignupClick}
      >
        <small>Create new account</small>
      </a>
    );
    const { errors } = this.state;
    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-white pb-5">
            <div className="text-muted text-center mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <div className="btn-wrapper text-center">
                <SocialButton
                  provider="google"
                  appId="183940291907-qqjeq6vn08193odqpos1danmbft9oab4.apps.googleusercontent.com"
                  onLoginSuccess={this.handleGoogleLogin}
                  onLoginFailure={this.handleGoogleLoginFailure}
                  icon="fa fa-google"
                  color="google-plus"
                >
                  Continue with Google &nbsp;&nbsp;&nbsp;
                </SocialButton>
                <SocialButton
                  provider="facebook"
                  appId="663506307551273"
                  onLoginSuccess={this.handleFacebookLogin}
                  onLoginFailure={this.handleFacebookLoginFailure}
                  icon="fa fa-facebook-f"
                  color="facebook"
                >
                  Continue with Facebook
                </SocialButton>
              </div>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={this.onSubmit}>
              <FormGroup
                className={classnames("mb-3", {
                  focused: this.state.emailFocused,
                })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    onFocus={(e) => this.setState({ emailFocused: true })}
                    onBlur={(e) => this.setState({ emailFocused: false })}
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.email && (
                  <div className="text-warning text-center">
                    <small>{errors.email}</small>
                  </div>
                )}
              </FormGroup>
              <FormGroup
                className={classnames({
                  focused: this.state.passwordFocused,
                })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onFocus={(e) => this.setState({ passwordFocused: true })}
                    onBlur={(e) => this.setState({ passwordFocused: false })}
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.password && (
                  <div className="text-warning text-center">
                    <small>{errors.password}</small>
                  </div>
                )}
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span>Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button
                  block
                  className="my-4 btn-round"
                  color="primary"
                  type="submit"
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={async (e) => {
                e.preventDefault();
                const { value: email } = await Swal.fire({
                  title: "Enter your email ID",
                  input: "email",
                  inputValue: "",
                  showCancelButton: true,
                });
                const UserObj = {
                  email: email,
                };
                console.log(UserObj);
                forgotPswdPage(UserObj);
              }}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            {this.props.onSignupClick ? card_state : page_state}
          </Col>
        </Row>
      </>
    );
  }
}
LoginCard.propTypes = {
  loginUser: PropTypes.func.isRequired,
  socialLogin: PropTypes.func,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, socialLogin })(LoginCard);
