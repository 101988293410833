import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  UncontrolledTooltip,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Badge,
} from "reactstrap";
import { saveUserRemarks, getOwnerProps } from "../../actions/adminActions";
import axios from "../../client";
import Property from "../../components/Cards/PropertyCard";

class UserCard extends Component {
  constructor(props) {
    super();
    this.state = {
      remarks: props.remarks,
      remarkPopup: false,
      ownerProps: null,
      modalPropId: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onRemarkSubmit = this.onRemarkSubmit.bind(this);
  }
  componentDidMount() {
    // this.props.getOwnerProps({ userId: this.props.userId });
    axios
      .post("/api/admin/getOwnerProps", { userId: this.props.userId })
      .then((res) => {
        this.setState({ ownerProps: res.data });
      })
      .catch((err) => this.setState({ ownerProps: {} }));
  }
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  onRemarkSubmit() {
    const remarkDat = {
      userId: this.props.userId,
      remarks: this.state.remarks,
    };
    this.props.saveUserRemarks(remarkDat);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    //  const { ownerProps } = this.props.admin;
    const ownerProps = this.state.ownerProps;
    var propButtons =
      ownerProps == null ? (
        <></>
      ) : (
        ownerProps.map((p) => (
          <>
            <Col sm="6" md="6" lg="6" xl="6">
              <Button
                onClick={() => {
                  this.setState({ modalPropId: p._id });
                }}
                className="bubble-button mt-3"
              >
                {p.name}
              </Button>
            </Col>
          </>
        ))
      );
    let default_pic = "images/fallback-property-image.svg";
    var propModals =
      ownerProps == null ? (
        <></>
      ) : (
        ownerProps.map((p) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.modalPropId === p._id}
            toggle={() => this.toggleModal("success")}
          >
            <div className="modal-header border-0">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                //onClick={() => this.toggleModal("remarkPopup")}
                onClick={() => this.setState({ modalPropId: "" })}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body px-3 py-1 mx-lg-5">
              <div className="pb-3">
                <Property
                  purpose={p.purpose}
                  user="owner"
                  id={p._id}
                  area={p.area}
                  name={p.name}
                  price={p.price}
                  rent={p.rent_monthly_basic}
                  bedroom={p.bedroom}
                  bathroom={p.bathroom}
                  place={p.locality}
                  city={p.city}
                  remarks={p.remarks}
                  requested={p.requested}
                  inspection_status={p.inspection_status}
                  //hero_image={p.gallery[0]}
                  hero_image={
                    p.thumbnail
                      ? p.thumbnail
                      : Array.isArray(p.gallery) && p.gallery.length
                      ? p.gallery[0] //this.getImage(p.gallery[0])
                      : default_pic
                  } //no++
                />
              </div>
            </div>
          </Modal>
        ))
      );
    return (
      <>
        <div className="text-center mt-3 shadow rounded">
          <div
            className="pt-3 px-3 pb-1 text-left"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="overflow-hidden text-left">
              <div className="overflow-hidden">
                <h5 className="title mb-1">
                  <span className="d-inline pr-3 text-capitalize">
                    {this.props.name}{" "}
                    <Badge
                      color={
                        this.props.role === "owner"
                          ? "primary"
                          : this.props.role === "tenant"
                          ? "warning"
                          : "info"
                      }
                    >
                      {this.props.role == null ? "Visitor" : this.props.role}
                    </Badge>
                  </span>
                </h5>

                <div className="text-muted pb-1">
                  <span className="fa fa-envelope mr-2"></span>
                  <a href={"mailto:" + this.props.email}>{this.props.email}</a>
                </div>

                <div className="text-muted pb-1">
                  <span className="fa fa-phone mr-2"></span>
                  <a href="tel:number"> {this.props.phone}</a>
                </div>

                <Row className="m-1">{propButtons}</Row>
              </div>

              {/* <span className="float-left">
                <ul className="list-inline-item pl-0">
                  <span>
                    <small className="text-muted pr-2">
                      {this.props.phone}
                    </small>
                  </span>
                </ul>
              </span> */}
              {/* <span className="float-right text-primary ml-2">
                <i className="fa fa-users primary mr-2"></i>
                <small>{this.props.requested} watching</small>
              </span> */}
            </div>
          </div>
          <Row className="m-1">
            <Col sm="12" md="12" lg="12" xl="12">
              <Input
                className="my-3"
                placeholder="Remarks"
                type="text"
                name="remarks"
                disabled={false}
                value={this.state.remarks}
                onClick={() => {
                  this.toggleModal("remarkPopup");
                }}
                onChange={() => {
                  this.toggleModal("remarkPopup");
                }}
              />
            </Col>
            {/* <Col sm="12" md="3" lg="3" xl="2">
              <Button
                className="btn-icon btn-neutral btn-round  mt-3 "
                color="primary"
                type="button"
                style={{ float: "right" }}
                onClick={() => {}}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-trash mr-0" />
                </span>
              </Button>
            </Col> */}
          </Row>
          {this.props.children}
        </div>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.remarkPopup}
          toggle={() => this.toggleModal("success")}
        >
          <div className="modal-header border-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("remarkPopup")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body px-3 py-1 mx-lg-5">
            <div className="pb-3">
              <Input
                className="my-3"
                placeholder="Remarks"
                type="textarea"
                name="remarks"
                value={this.state.remarks}
                onChange={this.onChange}
              />
              <Button
                block
                onClick={() => this.onRemarkSubmit()}
                className="mt-5 btn-round"
                color="primary"
                style={{
                  margin: "0px auto",
                  maxWidth: "80px",
                  padding: "5px 3px",
                  borderRadius: "18px",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>

        {propModals}
      </>
    );
  }
}
UserCard.propTypes = {
  auth: PropTypes.object.isRequired,
  admin: PropTypes.object,
  saveUserRemarks: PropTypes.func,
  getOwnerProps: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});
export default connect(mapStateToProps, { saveUserRemarks, getOwnerProps })(
  UserCard
);
