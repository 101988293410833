import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Form,
} from "reactstrap";
import DarkNavbar from "../../components/Navbars/DarkNavbar";
import { updatePassword } from "../../actions/authActions";

export default class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      Cpassword: "",
      password: "",
      errors: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    var url = this.props.location.pathname;
    const handle = url.substring(9);
    this.setState({ id: handle });
  }
  onSubmit(e) {
    e.preventDefault();
    if (
      this.state.password === "" ||
      this.state.Cpassword === "" ||
      this.state.password !== this.state.Cpassword
    ) {
      this.setState({ errors: "Passwords do not match!" });
    } else {
      if (this.state.password.length < 6) {
        this.setState({
          errors: "Password must be between 6 and 30 characters!",
        });
      } else {
        const userData = {
          user_id: this.state.id,
          password: this.state.password,
        };

        updatePassword(userData);
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: "" });
  }
  render() {
    const { errors } = this.state;
    return (
      <>
        <DarkNavbar />
        <Container className="container-lg py-lg">
          <h3 className="text-primary mt-3 mb-4 text-center">
            Change Password
          </h3>
          <Row>
            <Col sm="12" md="6" lg="4" className="m-auto">
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup>
                  <Input
                    className="mt-4"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  <Input
                    className="mt-4"
                    placeholder="Confirm Password"
                    type="password"
                    name="Cpassword"
                    value={this.state.Cpassword}
                    onChange={this.onChange}
                  />
                  <Button
                    color="primary"
                    size="lg"
                    type="submit"
                    className="my-4"
                    block
                  >
                    Save Changes
                  </Button>
                </FormGroup>
                {errors && (
                  <div className="text-warning text-center">
                    <small>{errors}</small>
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
