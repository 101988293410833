import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Form,
} from "reactstrap";
import DarkNavbar from "../../components/Navbars/DarkNavbar";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserProfile } from "../../actions/authActions";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.setState(this.props.auth.user);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ errors: {} });
    const userData = {
      id: this.state.id,
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
    };
    this.props.updateUserProfile(userData, this.props.history);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: {} });
  }
  render() {
    const { errors } = this.state;
    return (
      <>
        <DarkNavbar />
        <Container className="container-lg py-lg">
          <h3 className="text-primary mt-3 mb-4 text-center">My Profile</h3>
          <Row>
            <Col sm="12" md="6" lg="4" className="m-auto">
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup>
                  <Input
                    className="mb-4"
                    placeholder="Full Name"
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />

                  {errors.name && (
                    <div className="text-warning text-center ">
                      <small>{errors.name}</small>
                    </div>
                  )}
                  <Input
                    className="mb-4"
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    disabled="true"
                    value={this.state.email}
                    onChange={this.onChange}
                  />

                  {errors.email && (
                    <div className="text-warning text-center ">
                      <small>{errors.email}</small>
                    </div>
                  )}
                  <Input
                    placeholder="Mobile Number"
                    type="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChange}
                  />

                  {errors.phone && (
                    <div className="text-warning text-center ">
                      <small>{errors.phone}</small>
                    </div>
                  )}
                  <Button
                    color="primary"
                    size="lg"
                    outline
                    className="my-4"
                    block
                    type="button"
                    onClick={() => {
                      window.location = "/dAdXdEdF" + this.state.id;
                    }}
                  >
                    Change Password
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    className="my-4"
                    block
                  >
                    Save Changes
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateUserProfile })(
  withRouter(Profile)
);
