import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DarkNavbar from "components/Navbars/DarkNavbar.jsx";
import Footer from "components/Footers/Footer/Footer";
import LoginCard from "components/Cards/LoginCard";
import { connect } from "react-redux";

class Login extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DarkNavbar />
        <main ref="main">
          <section
            className="section section-shaped section-lg"
            style={{ minHeight: "90vh" }}
          >
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-md">
              <Row className="justify-content-center">
                <Col lg="5">
                  <LoginCard
                    onDone={() => {
                      //setTimeout(() => { }, 2500);
                      if (this.props.auth.isAuthenticated) {
                        window.location = "/properties";
                      }
                    }}
                    return_url={"/properties"}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(Login);
