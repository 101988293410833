import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Card,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  Input,
  InputGroupText,
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

class PropertyManagerLogin extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(e) {
    e.preventDefault();

    const adminData = {
      email: this.state.username,
      password: this.state.password,
    };
    this.props.loginUser(adminData, "/pm");
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    var auth = this.props.auth;
    if (auth.isAuthenticated && auth.user.super_role === "admin") {
      window.location = "pm";
    }
  }
  render() {
    const { errors } = this.state;
    return (
      <>
        <div className="position-relative">
          <section className="section section-lg section-hero section-shaped bg-primary">
            <Container className="shape-container d-flex align-items-center py-md">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center pr-3" lg="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("../../assets/img/brand/Logo.png")}
                      style={{ width: "350px" }}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <Col sm="12" lg="4" className="m-auto">
            <Card
              className="shadow bg-white px-lg-5 px-4 py-4 py-lg-5"
              style={{ marginTop: "-120px", borderRadius: "12px" }}
            >
              <h3 className="text-dark text-center mb-4">Admin Login</h3>
              <Form role="form" onSubmit={this.onSubmit}>
                <FormGroup
                  className={classnames({
                    focused: this.state.usernameFocused,
                  })}
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="User Name"
                      type="text"
                      autoComplete="off"
                      name="username"
                      value={this.state.username}
                      onChange={this.onChange}
                    />
                  </InputGroup>
                  {errors.email && (
                    <div className="text-warning text-center ">
                      <small>{errors.email}</small>
                    </div>
                  )}
                </FormGroup>

                <FormGroup
                  className={classnames({
                    focused: this.state.passwordFocused,
                  })}
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      // onFocus={e => this.setState({ passwordFocused: true })}
                      // onBlur={e => this.setState({ passwordFocused: false })}
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />

                    <Button type="submit" color="primary" size="" className="">
                      Login
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <div className="text-warning text-center">
                      <small>{errors.password}</small>
                    </div>
                  )}
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </div>
      </>
    );
  }
}
PropertyManagerLogin.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(PropertyManagerLogin);
