import React from "react";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./PhoneInput.css";

class PhoneInputnew extends React.Component {
  state = { value: this.props.def_phone };
  render() {
    return (
      <PhoneInput
        className="form-control-alternative form-control"
        placeholder="Mobile Number"
        value={this.props.def_phone}
        onChange={value => {
          this.props.onNumberChange(value);
        }}
        country="IN"
      />
    );
  }
}

export default PhoneInputnew;
