import React, { Component } from "react";
import Navbar from "components/Navbars/PMNavbar";
import Spinner from "../../components/Common/Spinner";
import UserCard from "components/Cards/UserCard";
import axios from "../../client";
import {
  Input,
  Form,
  Button,
  FormGroup,
  Row,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  updateAdminConfig,
  getAllConfig,
  getAllUsers,
} from "../../actions/adminActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

class PropertyManagerDashboard extends Component {
  constructor() {
    super();
    this.state = {
      plainTabs: 1,
      menuItem: "config",
      locations: "Kalamassery",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(e) {
    e.preventDefault();
    const configData = {
      locations: this.state.locations,
    };
    console.log("configData :>> ", configData);
    this.props.updateAdminConfig(configData);
  }
  componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    }
    //this.props.getAllConfig();
    axios
      .get("/api/admin/getConfig")
      .then((res) => {
        console.log("getConfig res :>> ", res);
        this.setState(res.data[0]);
      })
      .catch((err) => {
        console.log("error in getting config :>> ", err);
      });

    this.props.getAllUsers();
  }
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    const { users, loading } = this.props.admin;
    let owners, tenants, visitors;

    if (users === null || loading) {
      owners = <Spinner />;
      tenants = <Spinner />;
      visitors = <Spinner />;
    } else {
      if (users && Object.keys(users).length > 0) {
        owners = users.map((p) =>
          // p.role === "owner" ?
          true ? (
            <Col sm="12" md="6" lg="6" xl="6">
              <UserCard
                userId={p._id}
                name={p.name}
                phone={p.phone}
                email={p.email}
                remarks={p.pm_remarks ? p.pm_remarks : ""}
                role={p.role}
              />
            </Col>
          ) : (
            <div></div>
          )
        );
        tenants = users.map((p) =>
          p.role === "tenant" ? (
            <Col sm="12" md="6" lg="6" xl="6">
              <UserCard
                userId={p._id}
                name={p.name}
                phone={p.phone}
                email={p.email}
                remarks={p.pm_remarks ? p.pm_remarks : ""}
              />
            </Col>
          ) : (
            <div></div>
          )
        );
        visitors = users.map((p) =>
          p.role == null ? (
            <Col sm="12" md="6" lg="6" xl="6">
              <UserCard
                userId={p._id}
                name={p.name}
                phone={p.phone}
                email={p.email}
                remarks={p.pm_remarks ? p.pm_remarks : ""}
              />
            </Col>
          ) : (
            <div></div>
          )
        );
      }
    }

    const configForm = (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col
            sm="12"
            md="6"
            lg="6"
            className="mt-3"
            style={{ margin: "0px auto" }}
          >
            <FormGroup>
              <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                Localities
              </small>
              <Input
                className="my-3"
                placeholder="locations"
                type="text"
                name="locations"
                value={this.state.locations}
                onChange={this.onChange}
                required
              />
              <Button
                block
                type="submit"
                className="mt-5 btn-round"
                color="primary"
                style={{
                  margin: "0px auto",
                  maxWidth: "120px",
                  padding: "10px 5px",
                  borderRadius: "18px",
                }}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
    const usersTab = (
      <>
        <Col className="nav-wrapper mt-2 mt-lg-2 pb-0 pb-lg-4" md="4">
          {/* <Nav
            className="nav-fill flex-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={this.state.plainTabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.plainTabs === 1,
                })}
                onClick={(e) => this.toggleNavs(e, "plainTabs", 1)}
                href="#pablo"
                role="tab"
              >
                Owners
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.plainTabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.plainTabs === 2,
                })}
                onClick={(e) => this.toggleNavs(e, "plainTabs", 2)}
                href="#pablo"
                role="tab"
              >
                Tenants
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.plainTabs === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.plainTabs === 3,
                })}
                onClick={(e) => this.toggleNavs(e, "plainTabs", 3)}
                href="#pablo"
                role="tab"
              >
                Visitors
              </NavLink>
            </NavItem>
          </Nav>{" "} */}
        </Col>
        <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
          <TabPane tabId="plainTabs1">
            <Row>{owners}</Row>
          </TabPane>
          {/* <TabPane tabId="plainTabs2">
            <Row>{tenants}</Row>
          </TabPane>
          <TabPane tabId="plainTabs3">
            <Row>{visitors}</Row>
          </TabPane> */}
        </TabContent>
      </>
    );
    return (
      <>
        <Navbar />
        {/* <Container className="container-lg py-md" style={{ minHeight: "90vh" }}> */}

        <div className="layout">
          <div className="layout--with-image__left-section py-md">
            <ListGroup>
              <ListGroupItem
                onClick={() => {
                  this.setState({ menuItem: "config" });
                }}
                className={classnames("", {
                  ls_selected: this.state.menuItem === "config",
                })}
              >
                Configuration
              </ListGroupItem>
              <ListGroupItem
                onClick={() => {
                  this.setState({ menuItem: "users" });
                }}
                className={classnames("", {
                  ls_selected: this.state.menuItem === "users",
                })}
              >
                Users
              </ListGroupItem>
            </ListGroup>
          </div>
          <div className="layout--with-image__right-section py-md">
            <Container
              className="container-lg py-md"
              style={{ minHeight: "90vh" }}
            >
              <div className="my-4">
                {this.state.menuItem === "config" ? configForm : usersTab}
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  admin: state.admin,
  auth: state.auth,
});
PropertyManagerDashboard.propTypes = {
  updateAdminConfig: PropTypes.func,
  getAllConfig: PropTypes.func,
  getAllUsers: PropTypes.func,
};
export default connect(mapStateToProps, {
  updateAdminConfig,
  getAllConfig,
  getAllUsers,
})(PropertyManagerDashboard);
