import { GET_NOTIFS, NOTIF_LOADING } from "../actions/types";

const initialState = {
  notifs: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NOTIF_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_NOTIFS:
      return {
        ...state,
        notifs: action.payload,
        loading: false
      };

    default:
      return state;
  }
}
