import React, { Component } from "react";
import "../MultiStepProgressBar/MultiStepProgressBar.scss";

class MultiStepProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
  }

  render() {
    return (
      <>
        <ul class="list-unstyled multi-steps text-primary mt-lg-3 mb-lg-4">
          <li className={this.props.step === "1" ? "active" : null}>
            <div>Property Info</div>
          </li>
          <li className={this.props.step === "2" ? "active" : null}>
            <div>Owner Info</div>
          </li>
          <li className={this.props.step === "3" ? "active" : null}>
            <div>Key Info</div>
          </li>
          <li>
            <div>Finish</div>
          </li>
        </ul>
      </>
    );
  }
}

export default MultiStepProgressBar;
