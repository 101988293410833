import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import "./BubbleRadio.scss";
import classnames from "classnames";
export default class BubbleRadioAuto extends Component {
  // get array of options, automatically generates buttons
  constructor(props) {
    super(props);
    this.state = { optionNumber: this.props.def_options };
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(val) {
    var optionArray = this.state.optionNumber;
    console.log("val :>> ", val);
    if (optionArray.includes(val)) {
      var arr = optionArray.filter(function (value, index) {
        return value !== val;
      });
      this.setState({ optionNumber: arr }, () => {
        this.props.onOptionClick(this.state.optionNumber);
      });
      console.log("new arr :>> ", arr);
    } else {
      this.setState(
        {
          optionNumber: [...optionArray, val],
        },
        () => {
          this.props.onOptionClick(this.state.optionNumber);
          console.log(`this.state.optionNumber`, this.state.optionNumber);
        }
      );
    }
    // this.props.onOptionClick(this.state.optionNumber);
  }

  render() {
    const bubbles = this.props.optionArray.map((b1) => (
      <Col className="pr-2 pl-2 align-self-center text-center">
        <button
          type="button"
          className={classnames("option", {
            selected:
              this.state.optionNumber && this.state.optionNumber.includes(b1),
          })}
          onClick={this.handleClick.bind(this, b1)}
          style={{ cursor: "pointer" }}
        >
          {b1}
          <br />
        </button>
      </Col>
    ));
    console.log("this.state.optionNumber", this.state.optionNumber);
    return (
      <>
        <div className="mt-2">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            {this.props.label}
          </small>
          <div className="d-flex bubbleRadioBox">{bubbles}</div>
        </div>
        <hr className="pt-1 mt-3 mb-2" />
      </>
    );
  }
}
