import React, { Component } from "react";
import { Card, Container, CardBody } from "reactstrap";
import DarkNavbar from "components/Navbars/DarkNavbar";
import Footer from "components/Footers/Footer/Footer";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <DarkNavbar />
        <section className="section section-md bg-primary">
          <Container>
            <div>
              <h1 className="text-white mt-3">Privacy Policy</h1>
            </div>
          </Container>
        </section>
        <Container class="py-md">
          <Card
            className="shadow shadow-lg--hover mb-5"
            style={{ marginTop: "-50px" }}
          >
            <CardBody className="py-md-5 px-md-5 lh-180">
              <h3>PURPOSE OF PRIVACY POLICY</h3>
              <p>
                This privacy policy is legit to all online platforms and
                networks of iGen properties. The terms such as "us", "we", or
                "our" used in the website refers to iGen properties and "your"
                or "you" refers to the users and customers of this website. The
                objective of this privacy policy is to make our customers aware
                of the collecting, usage, maintaining, and sharing of the
                information shared on the website of iGen properties by the user
                for the purpose of accessing and using this website. This
                information might include those you chose to share with us,
                emails and messages that you communicate with iGen properties,
                and any other information provided by you. We are familiar and
                we respect the importance of privacy of our customers, hence we
                are dedicated to ensuring the safety and security of your
                information.
              </p>
              <p>
                Users of this website are advised to read and understand the
                Privacy Policy mentioned in this page for safe usage of the
                website. The website is also subject to our terms and
                conditions. For accessing and using this website, the user
                should agree with our terms, conditions, and policies. You are
                free not to use our website if you do not wish to agree with our
                terms and policies. By accessing our website and responding to
                the advertisements and services, you are giving us consent to
                collect and use the information that you share with us. It is
                the responsibility of the user to ensure the authenticity of the
                information provided on the website. Violations and disclosure
                of false, unauthorized, and fake information are punishable.
              </p>
              <p>
                The data collection process of this website is subjected to
                "cookies" to analyze the traffic and to promote the safety of
                the website. Cookie files stores messages that help in tracking
                the traffic and services of the website. It also helps us to
                provide information based on your interest, to remember your
                password. Etc. Customers are open to decline the use of cookies
                in your browser. You may come across cookies or links of third
                party websites while using our website, iGen properties do not
                monitor or are not responsible for these activities of third
                party websites.
              </p>
              <p>
                Users can contact our team via email or contact number provided
                on the website for any queries regarding the privacy and
                policies.
              </p>
              <h3 className="mt-5">WE COLLECT FOLLOWING INFORMATION</h3>
              <p>
                The information that we collect from our users includes the
                information that they provide us to access our site or activate
                an account on our website, the information and details they
                choose to share with us, and information related to payment
                requirements.
              </p>
              <ul>
                <li>
                  Personal details Information that is relevant to identify you,
                  such as name, age, gender, and other similar information.
                </li>
                <li>
                  Payment requirements Payment details, encrypted passwords,
                  secured financial details as per requirements.
                </li>
                <li>
                  Contact details Email and other contact details to manage
                  services to you precisely and effectively.
                </li>
                <li>
                  Other information Additional information required to provide
                  you personalized services and to notify you with offers.
                </li>
              </ul>
              <h3 className="mt-5 text-uppercase">
                Information that is required to access the site/ to activate an
                account
              </h3>
              <p>
                While using this site and to open an account with iGen
                properties, we demand and collect information relevant for the
                efficient and smooth performance of the site and our services.
                We will not be able to work efficiently without your consent to
                share the information as required.
              </p>
              <p>
                <b>Personal details:</b> For accessing certain features of our
                website you might be asked to create an account that will be in
                relation to your profile. Your personalized account will be
                created upon the consent of the user. For creating an account we
                might require to identify you and to create a personalized
                account. We require information such as name, email address,
                date and place of birth, contact details, and other details that
                are required to provide you a better service. Certain features
                of the site, such as listing and bookings of services, might
                require other personal information such as descriptions and
                pictures.
              </p>
              <p>
                <b>Personal identification documents:</b> For ensuring a safe
                and trustworthy environment, we might need additional
                information to verify your identities, such as personal
                identification issued by the government, authorized ID cards,
                driving license, or any other documents required to verify the
                identity of the user.
              </p>
              <p>
                <b>Payment details:</b> Addition to the requirements to book or
                to create a listing, we might require you to provide certain
                fees; hence you will be asked to share certain legitimate
                financial information to ease the payment processes.
              </p>
              <p>
                <b>Communication information:</b> iGen is authorized to monitor
                and manage the communication details that you share with iGen.
                We collect information on your communication or any information
                that you wish to share with us during the communication process
                with agents, other users, and customer care centers that are
                associated with iGen.
              </p>
              <h3 className="mt-5 text-uppercase">
                Information that you choose to share with us
              </h3>
              <p>
                As a customer, you are free to choose the information that you
                wish to share with us. The sharing of such information will help
                us to deliver better and user-friendly services to you.
              </p>
              <p>
                <b>Additional details of profile:</b> you can choose to share
                information that would add more identifiable personal details to
                your profile. This includes your native language, languages you
                know, city of residence, and other descriptions. These details
                will be part of your public profile and could be viewed by the
                public.
              </p>
              <p>
                <b>Contact details from your address book:</b> You can choose to
                share the contacts in your address book by importing directly or
                add them manually to access the services from iGen with ease.
              </p>
              <p>
                <b>Other information:</b> other details to fill forms,
                responding to surveys, details for promotion purpose, and
                information relevant to use features and services from the
                website of iGen could be provided by the customer upon their
                choice.
              </p>
              <h3 className="mt-5 text-uppercase">
                Details relevant for payment requirements
              </h3>
              <p>
                For the best and effective services, it is necessary for iGen to
                collect specific financial details from our customers. Without
                these details, we will not be able to perform services provided
                on our website adequately. Following are the information that we
                would require:
              </p>
              <p>
                <b>Payment details:</b> To access the services from our website,
                our customers would be asked to share certain payment details
                with us. Customers should note that we are linked to third party
                payment service providers to ease our payment procedures. You
                will be asked to provide your payment details to these third
                party providers. Please be aware of the terms, conditions, and
                privacy policies of the third-party service provider.
              </p>
              <p>
                <b>Information relevant for identity verification:</b> In
                addition to the collection of payment details we might require
                certain documents and details to verify your identity to process
                the services. This could include authorized Identity Cards
                issued by government institutions (passports, driving license,
                national ID card, etc) and other documents valid as age proof in
                order to verify your identity to provide you with the best
                services in compliance with legal laws.
              </p>
              <h3 className="mt-5 text-uppercase">THIRD-PARTY LINKS </h3>
              <p>
                We share some of your information with third parties that
                provide us services to ease the payment procedures. We are
                committed to the security of our customers hence we share this
                information in accordance with the legal procedures. We share
                your information only for the purposes mentioned in the policy,
                with:
              </p>
              <ul>
                <li>Companies that are related with iGen properties.</li>
                <li>
                  Managers and landlords of property or with tenants or with
                  occupants related to the services that you choose.
                </li>
                <li>
                  Third-party vendors to aid us with miscellaneous services
                  including payment services. They are also linked with us to
                  verify your identity, to verify the authenticity of the
                  information available on public platforms, to recheck the
                  details of providers, to perform debugging and fraud
                  activities, to provide advertisement and customer care
                  services.
                </li>
                <li>
                  Entities that manage other online platforms or websites with
                  services that are connected to iGen.
                </li>
                <li>
                  Users and managers of online platforms where you share
                  comments, pictures, links, or any other content related to
                  iGen.
                </li>
                <li>
                  The general public on any online platform that is associated
                  with iGen. The sharing of content could be viewed by friends
                  and viewers of the respective online networks and depends on
                  the social sharing settings. Users are advised to read and
                  understand the policies of sharing these online platforms
                  before sharing any content.
                </li>
                <li>Third-parties associated with the law. </li>
                <li>
                  Third-parties associated with the iGen properties, to sell or
                  buy assets; we might share your information with them for
                  effective service experience.
                </li>
                <li>
                  Third parties related to iGen with any merger or sales
                  accounting.
                </li>
              </ul>
              <p>
                These providers have access to your details to perform these
                services and we are committed to safeguarding the information
                that you provide. We share your personal details and other
                information in accordance with the law. We might also share the
                information in an anonymous form, which could not be used to
                identify the individual.
              </p>
              <h3 className="mt-5 text-uppercase">
                PURPOSE OF COLLECTING INFORMATION
              </h3>
              <ul>
                <li>To communicate with you for user-friendly services</li>
                <li>Verifying your account</li>
                <li>To provide best and customized services</li>
                <li>To notify you with offers and promotion information</li>
                <li>
                  To inform you of the amendments in the terms, conditions, and
                  policies
                </li>
                <li>To track the trafficking and user flow</li>
                <li>Analytical and statistical purpose</li>
                <li>
                  Prevention of fake or false information, abusive comments and
                  security failures.
                </li>
                <li>To conduct security assessments</li>
                <li>
                  To verify the authenticity of the content shared in the
                  website
                </li>
                <li>To verify the laws related to the process. </li>
                <li>
                  To monitor and manage the relationship with our customers.
                </li>
                <li>To enforce compliance with third parties.</li>
              </ul>
              <h3 className="mt-5 text-uppercase">SECURITY OF YOUR DATA</h3>
              <p>
                The information that our customers provide is subject to further
                disclosure with Policy and Terms and Conditions, this process
                shall be conducted by adopting adequate safety measures. The
                details and information collected from our customers are stored
                in safe and secure servers. Our customers are free to withdraw
                their consent and contracts by contacting us.
              </p>
              <p>
                We do not undertake any responsibilities of third party
                transactions and transmissions associated with third-party
                links. Customers must commit to these transactions upon their
                risks. We will take appropriate steps to ensure the safety and
                security of your information within our limit.
              </p>
              <p>
                If you are a registered user of our website, you are responsible
                to take security measures to safeguard the login credentials of
                your account. These should not be passed on to third parties.
                Customers will be solely responsible for the fake information,
                abusive comments, and false content shared in your accounts.
                iGen will not be responsible for activities of other accounts on
                the site. But as a user of our website, please feel free to
                contact us immediately if you notice any kind of mishandlings of
                accounts.
              </p>
              <h3 className="mt-5 text-uppercase">RIGHTS OF CUSTOMERS</h3>
              <ul>
                <li>
                  Our customers are free to update and access your account
                  details through the settings options, upon that information is
                  real and true.
                </li>
                <li>
                  You are free to point out any mishandlings happening on our
                  website that are concerning you.
                </li>
                <li>
                  You are free to withdraw your membership with our website at
                  any point of time. But your details might be visible on the
                  site even after the cancellation of your membership as there
                  might be some circumstances to retain your information.
                </li>
              </ul>
              <h3 className="mt-5 text-uppercase">POLICY AMENDMENTS</h3>
              <p>
                The policy, terms, and conditions of this website can be amended
                at the sole discretion of iGen properties. Customers are advised
                to check the website frequently to notify themselves with the
                changes of the site.
              </p>
              <h3 className="mt-5 text-uppercase">TERMS AND CONDITION</h3>
              <p>
                The terms and conditions of this website are mentioned as a
                separate section on this website. This policy is a part of the
                terms and conditions of this website. Users are advised to read
                the terms and conditions section before using this website.
              </p>
              <h3 className="mt-5 text-uppercase">CONTACT US FOR QUERIES</h3>
              <p>
                As registered members and constant users of our website, you can
                contact us if you have any queries, comments, complaints, or
                recommendations. You are free to approach us for any matters
                that concern you. We are committed to resolving all the issues
                and requests of our users within a short time.
              </p>
              <p>For your queries and concerns</p>
              <p>
                You can send us an email via:
                <a href="mailto:info@igenproperties.in">
                  info@igenproperties.in
                </a>
                Or make a call at: ​
                <a href="tel:+91 6282 79 6060">+91 6282 79 6060</a>
              </p>
              <p>
                This privacy policy is active on this website from 1st April
                2020
              </p>
            </CardBody>
          </Card>
        </Container>
        <Footer />
      </>
    );
  }
}
