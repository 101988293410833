import React, { Component } from "react";
import Navbar from "components/Navbars/PMNavbar";
import Property from "components/Cards/PropertyCard";
import Footer from "components/Footers/Footer/Footer";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { getAllProps, getPropsByObject } from "../../actions/propActions";
import BubbleRadio from "components/Inputs/BubbleRadio/BubbleRadio";
import BubbleRadioSingle from "components/Inputs/BubbleRadio/BubbleRadioSingle";
import {
  Row,
  Col,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Modal,
  Input,
  Button,
} from "reactstrap";
import { PropertyTypes } from "utils/consts";
const configProps = [
  PropertyTypes.house,
  PropertyTypes.apartment,
  PropertyTypes.villa,
];
const commercialProps = [PropertyTypes.office_space, PropertyTypes.shop];
class Properties extends Component {
  constructor() {
    super();
    this.state = {
      plainTabs: 1,

      searchOpen: false,
      searchLocality: [], //["Kakkanad"],
      searchDeal: "Rental",
      searchOfferType: "Residential",
      searchPropertyType: [],
      searchFurnishedStatus: [],
      searchBhk: [],
      searchBudget: [],
    };
    this.searchSubmit = this.searchSubmit.bind(this);
  }
  searchSubmit() {
    //  {available_for: {$in: ['both','family']},bathroom: {$in: ['1','2','3']}}
    //{rent:{ $gte:"10000", $lte: "12000" }}
    var searchKeys = ["locality,property_type,furnishing,bedroom,"];
    console.log(`searchKeys`, searchKeys);
    var searchObj = {};
    if (this.state.searchLocality.length > 0) {
      searchObj.locality = { $in: this.state.searchLocality };
    }
    if (this.state.searchDeal !== "") {
      searchObj.purpose = this.state.searchDeal === "Rental" ? "rent" : "sell";
    }
    if (this.state.searchOfferType !== "") {
      // searchObj.property_type =
      //   this.state.searchOfferType === "Residential"
      //     ? { $in: configProps }
      //     : { $in: commercialProps };
    }
    if (this.state.searchPropertyType.length > 0) {
      searchObj.property_type = { $in: this.state.searchPropertyType };
    }
    if (this.state.searchFurnishedStatus.length > 0) {
      searchObj.furnishing = { $in: this.state.searchFurnishedStatus };
    }
    if (this.state.searchBhk.length > 0) {
      var bed = this.state.searchBhk;
      var bedArr = [];
      if (bed.includes("1 BHK")) {
        bedArr.push("1");
      }
      if (bed.includes("2 BHK")) {
        bedArr.push("2");
      }
      if (bed.includes("3 BHK")) {
        bedArr.push("3");
      }
      var tempObj = { $in: bedArr };

      if (bed.includes("3+ BHK")) {
        tempObj = { ...tempObj, $gt: "3" };
      }
      if (bedArr.length > 0 || Object.keys(tempObj).includes("$gt")) {
        searchObj.bedroom = tempObj;
      }
    }
    //{rent:{ $gte:"10000", $lte: "12000", $gte:"14000",$lte: "20000"}}
    // if (this.state.searchBudget.length > 0) {
    //   var bud = this.state.searchBudget;
    //   var budObj = {};
    //   var objArr = [];
    //   //{$or:[{rent:{$gte:1000, $lt: 2500}},{rent:{$lt:15000,$gte: 10000}}]}
    //   if (this.state.searchDeal === "Rental") {
    //     if (bud.includes("Below 5K")) {
    //       budObj = { ...budObj, $lt: "5000" };
    //       objArr.push({ rent: { $lt: 5000 } });
    //     }
    //     if (bud.includes("5K-10K")) {
    //       budObj = {
    //         ...budObj,
    //         $gte: "5000",
    //         $lt: "10000",
    //       };
    //       objArr.push({ rent: { $gte: 5000, $lt: 10000 } });
    //     }
    //     if (bud.includes("10K-25K")) {
    //       budObj = {
    //         ...budObj,
    //         $gte: "10000",
    //         $lt: "25000",
    //       };
    //       objArr.push({ rent: { $gte: 10000, $lt: 25000 } });
    //     }
    //     if (bud.includes("Above 25K")) {
    //       budObj = { ...budObj, $gte: "25000" };
    //       objArr.push({ rent: { $gte: 25000 } });
    //     }
    //     console.log("budObj :>> ", budObj);
    //     if (Object.keys(budObj).length > 0) {
    //       //searchObj.rent = budObj;
    //     }
    //     if (objArr.length > 0) {
    //       // searchObj.rent = { $or: objArr };
    //       searchObj.$or = objArr;
    //       console.log("searchObj.rent :>> ", { $or: objArr });
    //     }
    //   } else if (this.state.searchDeal === "Sale") {
    //     bud = this.state.searchBudget;
    //     budObj = {};
    //     if (bud.includes("Below 10L")) {
    //       budObj = { ...budObj, $lt: "1000000" };
    //       objArr.push({ price: { $lt: 1000000 } });
    //     }
    //     if (bud.includes("10L-50L")) {
    //       budObj = { ...budObj, $gte: "1000000", $lt: "5000000" };
    //       objArr.push({ price: { $gte: 1000000, $lt: 5000000 } });
    //     }
    //     if (bud.includes("50L-1Cr")) {
    //       budObj = { ...budObj, $gte: "5000000", $lt: "10000000" };
    //       objArr.push({ price: { $gte: 5000000, $lt: 10000000 } });
    //     }
    //     if (bud.includes("Above 1Cr")) {
    //       budObj = { ...budObj, $gte: "10000000" };
    //       objArr.push({ price: { $gte: 10000000 } });
    //     }
    //     if (Object.keys(budObj).length > 0) {
    //       // searchObj.price = budObj;
    //     }
    //     if (objArr.length > 0) {
    //       // searchObj.rent = { $or: objArr };
    //       searchObj.$or = objArr;
    //       console.log("searchObj.price :>> ", { $or: objArr });
    //     }
    //   }
    // }

    if (this.state.searchBudget.length > 0) {
      var bud = this.state.searchBudget;
      var budObj = {};
      var objArr = [];
      //{$or:[{rent:{$gte:1000, $lt: 2500}},{rent:{$lt:15000,$gte: 10000}}]}
      if (this.state.searchDeal === "Rental") {
        if (bud.includes("Below 15K")) {
          budObj = { ...budObj, $lt: "15000" };
          objArr.push({ rent: { $lt: 15000 } });
        }
        if (bud.includes("15K-25K")) {
          budObj = {
            ...budObj,
            $gte: "15000",
            $lt: "25000",
          };
          objArr.push({ rent: { $gte: 15000, $lt: 25000 } });
        }
        if (bud.includes("25K-40K")) {
          budObj = {
            ...budObj,
            $gte: "25000",
            $lt: "40000",
          };
          objArr.push({ rent: { $gte: 25000, $lt: 40000 } });
        }
        if (bud.includes("Above 40K")) {
          budObj = { ...budObj, $gte: "40000" };
          objArr.push({ rent: { $gte: 40000 } });
        }
        console.log("budObj :>> ", budObj);
        if (Object.keys(budObj).length > 0) {
          //searchObj.rent = budObj;
        }
        if (objArr.length > 0) {
          // searchObj.rent = { $or: objArr };
          searchObj.$or = objArr;
          console.log("searchObj.rent :>> ", { $or: objArr });
        }
      } else if (this.state.searchDeal === "Sale") {
        bud = this.state.searchBudget;
        budObj = {};
        if (bud.includes("Below 50L")) {
          budObj = { ...budObj, $lt: "5000000" };
          objArr.push({ price: { $lt: 5000000 } });
        }
        if (bud.includes("10L-50L")) {
          budObj = { ...budObj, $gte: "5000000", $lt: "7500000" };
          objArr.push({ price: { $gte: 5000000, $lt: 7500000 } });
        }
        if (bud.includes("50L-1Cr")) {
          budObj = { ...budObj, $gte: "7500000", $lt: "10000000" };
          objArr.push({ price: { $gte: 7500000, $lt: 10000000 } });
        }
        if (bud.includes("Above 1Cr")) {
          budObj = { ...budObj, $gte: "10000000" };
          objArr.push({ price: { $gte: 10000000 } });
        }
        if (Object.keys(budObj).length > 0) {
          // searchObj.price = budObj;
        }
        if (objArr.length > 0) {
          // searchObj.rent = { $or: objArr };
          searchObj.$or = objArr;
          console.log("searchObj.price :>> ", { $or: objArr });
        }
      }
    }
    console.log("searchObj :>> ", searchObj);
    var searchState = {
      searchLocality: this.state.searchLocality,
      searchDeal: this.state.searchDeal,
      searchOfferType: this.state.searchOfferType,
      searchPropertyType: this.state.searchPropertyType,
      searchFurnishedStatus: this.state.searchFurnishedStatus,
      searchBhk: this.state.searchBhk,
      searchBudget: this.state.searchBudget,
    };
    this.props.getPropsByObject(searchObj, searchState);
    this.toggleModal("searchOpen");
  }
  toggleModal = (state) => {
    if (state === "searchOpen") {
      if (Object.keys(this.props.prop.searchState).length > 0) {
        this.setState({ ...this.state, ...this.props.prop.searchState }, () => {
          console.log("new state :>> ", this.state);
          this.setState({
            [state]: !this.state[state],
          });
        });
      } else {
        this.setState({
          [state]: !this.state[state],
        });
      }
    } else {
      this.setState({
        [state]: !this.state[state],
      });
    }
  };
  componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    }
    this.props.getAllProps();
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  render() {
    const bedsBubble = (
      <BubbleRadio
        label="Number of Bedrooms"
        option1="1 BHK"
        option2="2 BHK"
        option3="3 BHK"
        option4="3+ BHK"
        def_options={this.state.searchBhk}
        //Array of options selected will reach here
        onOptionClick={(bhkArr) => {
          console.log("bhkArr=", bhkArr);
          this.setState({ searchBhk: bhkArr });
        }}
      />
    );
    const furnishedBubble = (
      <BubbleRadio
        label="Furnished Status"
        option1="Fully Furnished"
        option2="Semi Furnished"
        option3="Not Furnished"
        def_options={this.state.searchFurnishedStatus}
        //Array of options selected will reach here
        onOptionClick={(furnishedArr) => {
          console.log("furnishedArr=", furnishedArr);
          this.setState({ searchFurnishedStatus: furnishedArr });
        }}
      />
    );
    const residentialBubbleRadio = (
      <BubbleRadio
        label="Property"
        option1={PropertyTypes.house}
        option2={PropertyTypes.land}
        option3={PropertyTypes.villa}
        option4={PropertyTypes.apartment}
        def_options={this.state.searchPropertyType}
        //Array of options selected will reach here
        onOptionClick={(Deal) => {
          console.log("Deal==", Deal);
          this.setState({ searchPropertyType: Deal });
        }}
      />
    );
    const commercialBubbleRadio = (
      <BubbleRadio
        label="Property"
        option1={PropertyTypes.shop}
        option2={PropertyTypes.office_space}
        def_options={this.state.searchPropertyType}
        //Array of options selected will reach here
        onOptionClick={(Deal) => {
          console.log("Deal==", Deal);
          this.setState({ searchPropertyType: Deal });
        }}
      />
    );
    const rental_range = (
      <BubbleRadio
        label="Rental Range"
        option1="Below 15K"
        option2="15K-25K"
        option3="25K-40K"
        option4="Above 40K"
        def_options={this.state.searchBudget}
        //Array of options selected will reach here
        onOptionClick={(budgetArr) => {
          console.log("budgetArr=", budgetArr);
          this.setState({ searchBudget: budgetArr });
        }}
      />
    );
    const budget_range = (
      <BubbleRadio
        label="Budget Range"
        option1="Below 50L"
        option2="50L-75L"
        option3="75L-1Cr"
        option4="Above 1Cr"
        def_options={this.state.searchBudget}
        //Array of options selected will reach here
        onOptionClick={(budgetArr) => {
          console.log("budgetArr=", budgetArr);
          this.setState({ searchBudget: budgetArr });
        }}
      />
    );
    const tick_mark = (
      <>
        {" "}
        <span className="my-2">
          <i
            class="fa fa-check-circle mr-2 text-success d-inline"
            style={{ fontSize: "20px" }}
          ></i>
          <span className="d-inline text-muted mb-3">Visit Completed</span>
        </span>
      </>
    );
    const { properties, loading } = this.props.prop;
    let rentPropertiesContent;
    let sellPropertiesContent;
    let otherPropertiesContent;

    if (properties === null || loading) {
      rentPropertiesContent = <Spinner />;
      sellPropertiesContent = <Spinner />;
      otherPropertiesContent = <Spinner />;
    } else {
      if (Object.keys(properties).length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        rentPropertiesContent = properties.map(
          (p) => (
            //   p.purpose === "rent" ? (
            <Col sm="12" md="6" lg="6" xl="4">
              <Property
                user="pm"
                property_type={p.property_type}
                floor_number={p.floor_number}
                total_floor_number={p.total_floor_number}
                land_area={p.land_area}
                id={p._id}
                area={p.area}
                name={p.name}
                rent={p.rent_monthly_basic}
                purpose={p.purpose}
                price={p.price}
                bedroom={p.bedroom}
                bathroom={p.bathroom}
                place={p.locality}
                city={p.city}
                remarks={p.remarks}
                requested={p.requested}
                inspection_status={p.inspection_status}
                is_active={p.is_active}
                //hero_image={p.gallery[0]}
                hero_image={
                  p.thumbnail
                    ? p.thumbnail
                    : Array.isArray(p.gallery) && p.gallery.length
                    ? p.gallery[0] //this.getImage(p.gallery[0])
                    : default_pic
                } //no++
              />
            </Col>
          )
          // : (
          //   <div></div>
          // )
        );
        sellPropertiesContent = properties.map((p) =>
          p.purpose === "sell" ? (
            <Col sm="12" md="6" lg="6" xl="4">
              <Property
                user="pm"
                id={p._id}
                property_type={p.property_type}
                floor_number={p.floor_number}
                total_floor_number={p.total_floor_number}
                land_area={p.land_area}
                purpose={p.purpose}
                price={p.price}
                area={p.area}
                name={p.name}
                rent={p.rent_monthly_basic}
                bedroom={p.bedroom}
                bathroom={p.bathroom}
                place={p.locality}
                city={p.city}
                requested={p.requested}
                inspection_status={p.inspection_status}
                is_active={p.is_active}
                hero_image={
                  p.thumbnail
                    ? p.thumbnail
                    : Array.isArray(p.gallery) && p.gallery.length
                    ? p.gallery[0] //this.getImage(p.gallery[0])
                    : default_pic
                } //no++
              />
            </Col>
          ) : (
            <div></div>
          )
        );
        otherPropertiesContent = properties.map((p) =>
          p.purpose === "later" ? (
            <Col sm="12" md="6" lg="6" xl="4">
              <Property
                user="pm"
                property_type={p.property_type}
                floor_number={p.floor_number}
                total_floor_number={p.total_floor_number}
                land_area={p.land_area}
                id={p._id}
                area={p.area}
                name={p.name}
                rent={p.rent_monthly_basic}
                purpose={p.purpose}
                price={p.price}
                bedroom={p.bedroom}
                bathroom={p.bathroom}
                place={p.locality}
                city={p.city}
                requested={p.requested}
                hero_image={
                  p.thumbnail
                    ? p.thumbnail
                    : Array.isArray(p.gallery) && p.gallery.length
                    ? p.gallery[0] //this.getImage(p.gallery[0])
                    : default_pic
                }
              />
            </Col>
          ) : (
            <div></div>
          )
        );
      }
    }

    return (
      <>
        <Navbar />
        <Modal
          className="modal-dialog-centered" //"modal-dialog-top"
          isOpen={this.state.searchOpen}
          toggle={() => this.toggleModal("searchOpen")}
        >
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.toggleModal("searchOpen")}
          >
            <span
              style={{ float: "right", padding: "2px 5px 0px 0px" }}
              aria-hidden="true"
            >
              ×
            </span>
          </button>
          <span
            className="d-block text-uppercase mt-3 mb-2 text-primary"
            style={{ textAlign: "center" }}
          >
            Search Your Property
          </span>
          {/* <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            Location
          </small> */}
          <div className="modal-body px-3 py-1 mx-lg-5">
            <div className="pb-3">
              {/* <BubbleRadio
                label="Locality"
                option1="Kakkanad"
                option2="Idappilly"
                // option3="Decide Later"
                def_options={
                  this.state.searchLocality
                  // currProp === null
                  //   ? 1
                  //   : currProp.purpose === "rent"
                  //   ? 1
                  //   : currProp.purpose === "sell"
                  //   ? 2
                  //   : currProp.purpose === "later"
                  //   ? 3
                  //   : 1
                }
                //Array of options selected will reach here
                onOptionClick={(localityArr) => {
                  console.log("localityArr=", localityArr);
                  this.setState({ searchLocality: localityArr });
                }}
              /> */}
              {/* <BubbleRadioAuto
                label="Locality"
                optionArray={locs}
                // option3="Decide Later"
                def_options={
                  this.state.searchLocality
                  // currProp === null
                  //   ? 1
                  //   : currProp.purpose === "rent"
                  //   ? 1
                  //   : currProp.purpose === "sell"
                  //   ? 2
                  //   : currProp.purpose === "later"
                  //   ? 3
                  //   : 1
                }
                //Array of options selected will reach here
                onOptionClick={(localityArr) => {
                  console.log("localityArr=", localityArr);
                  this.setState({ searchLocality: localityArr });
                }}
              /> */}

              <BubbleRadioSingle
                label="Deal"
                option1="Rental"
                option2="Sale"
                // option3="Decide Later"
                def_option={this.state.searchDeal}
                //Array of options selected will reach here
                onOptionClick={(Deal) => {
                  console.log("Deal==", Deal);
                  this.setState({ searchDeal: Deal });
                }}
              />

              <BubbleRadioSingle
                label="Offer Type"
                option1="Residential"
                option2="Commmercial"
                // option3="Decide Later"
                def_option={this.state.searchOfferType}
                //Array of options selected will reach here
                onOptionClick={(OfferType) => {
                  console.log("OfferType=", OfferType);
                  this.setState({ searchOfferType: OfferType });
                }}
              />

              {this.state.searchOfferType === "Residential"
                ? residentialBubbleRadio
                : commercialBubbleRadio}

              {/* this.state.searchPropertyType !== PropTypes.land || */}
              {this.state.searchOfferType === "Residential" ? (
                furnishedBubble
              ) : (
                <></>
              )}
              {this.state.searchOfferType === "Residential" &&
              !this.state.searchPropertyType.includes(PropertyTypes.land) ? (
                bedsBubble
              ) : (
                <></>
              )}

              {this.state.searchDeal === "Rental" ? rental_range : budget_range}
              <Button
                block
                onClick={() => this.searchSubmit()}
                className="mt-5 btn-round"
                color="primary"
                style={{
                  margin: "0px auto",
                  maxWidth: "80px",
                  padding: "5px 3px",
                  borderRadius: "5px",
                  background: "#4A5BEA",
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </Modal>
        <Container className="container-lg py-md" style={{ minHeight: "90vh" }}>
          <h3 className="text-primary mt-lg-5 mt-4 mb-3 mb-lg-4">
            {/* All Properties */}
          </h3>
          <Col className="nav-wrapper mt-2 mt-lg-2 pb-0 pb-lg-4" md="4">
            <Input
              className="mb-3 input_icon"
              placeholder="Search"
              style={{ cursor: "pointer" }}
              type="text"
              name="searchbox"
              value={"Search Your Property"}
              onChange={() => {
                this.toggleModal("searchOpen");
              }}
              onClick={() => {
                this.toggleModal("searchOpen");
              }}
              required
            />
            {/* <Nav
              className="nav-fill flex-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 1,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  FOR RENT
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 2,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  FOR SALE
                </NavLink>
              </NavItem>
             
            </Nav> */}
          </Col>
          <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
            <TabPane tabId="plainTabs1">
              <Row>{rentPropertiesContent}</Row>
            </TabPane>
            {/* <TabPane tabId="plainTabs2">
              <Row>{sellPropertiesContent}</Row>
            </TabPane>
            <TabPane tabId="plainTabs3">
              <Row>{otherPropertiesContent}</Row>
            </TabPane> */}
          </TabContent>

          {/* <Row className="mb-2 mt-5">
            <nav
              aria-label="Page navigation example"
              style={{ margin: "0px auto" }}
            >
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-angle-left" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="active">
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    4
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    5
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-angle-right" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Row> */}
        </Container>
        <Footer />
      </>
    );
  }
}
Properties.propTypes = {
  getAllProps: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired,
  getPropsByObject: PropTypes.func,
};

const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllProps, getPropsByObject })(
  Properties
);
