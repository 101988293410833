export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_PROPS = "GET_PROPS";
export const GET_PROP = "GET_PROP";
export const PROP_LOADING = "PROP_LOADING";
export const CLEAR_CURRENT_PROP = "CLEAR_CURRENT_PROP";
export const BOOK_PROP_VISIT = "BOOK_PROP_VISIT";
export const SAVE_VISIT_DATE = "SAVE_VISIT_DATE";
export const SAVE_VISIT_TIME = "SAVE_VISIT_TIME";
export const SAVE_VISIT_USER = "SAVE_VISIT_USER";
export const SAVE_NEW_PROP_INFO = "SAVE_NEW_PROP_INFO";
export const SAVE_NEW_PROP_OWNER = "SAVE_NEW_PROP_OWNER";
export const SAVE_NEW_PROP_KEY_HOLDER = "SAVE_NEW_PROP_KEY_HOLDER";
export const REGISTER_NEW_PROP_OWNER = "REGISTER_NEW_PROP_OWNER";
export const VISIT_LOADING = "VISIT_LOADING";
export const GET_TENANT_DASHS = "GET_TENANT_DASHS";
export const GET_OWNER_PROPS = "GET_OWNER_PROPS";
export const GET_OWNER_VISITS = "GET_OWNER_VISITS";
export const SAVE_UPLOADED_IMGS = "SAVE_UPLOADED_IMGS";
export const GET_PROP_DASHS = "GET_PROP_DASHS";
export const GET_NOTIFS = "GET_NOTIFS";
export const NOTIF_LOADING = "NOTIF_LOADING";
export const GET_F_PROPS = "GET_F_PROPS";
export const SET_ADMIN_CONFIG = "SET_ADMIN_CONFIG";
export const SET_SEARCH_OBJECT = "SET_SEARCH_OBJECT";
export const GET_USERS = "GET_USERS";
export const USER_LOADING = "USER_LOADING";
export const SET_OWNER_PROPS = "SET_OWNER_PROPS";
