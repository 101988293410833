import React, { Component } from "react";
import DarkNavbar from "components/Navbars/DarkNavbar";
import { Row, Col, Container } from "reactstrap";
import VisitorInfoCard from "components/Cards/VisitorInfoCard";

export default class PropertyViews extends Component {
  render() {
    return (
      <>
        <DarkNavbar />
        <Container className="container-lg py-lg">
          <h3 className="text-primary mt-3 mb-sm-3 mb-xs-0 mb-lg-4 d-inline-block">
            Property Name{" "}
          </h3>
          <span>
            <span className="d-inline-block text-muted ml-sm-4 mb-3">
              Inspection Completed
            </span>
            <i
              class="fa fa-check-circle ml-2 text-success d-inline-block"
              style={{ fontSize: "20px" }}
            ></i>
          </span>

          <Row>
            <Col sm="12" md="6" lg="4" xl="4">
              <VisitorInfoCard
                tenant="John Doe"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
                status="Considering"
                depositStatus="Not Received"
              />
            </Col>
            <Col sm="12" md="6" lg="4" xl="4">
              <VisitorInfoCard
                tenant="John Doe"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
                status="Considering"
                depositStatus="Not Received"
              />
            </Col>
            <Col sm="12" md="6" lg="4" xl="4">
              <VisitorInfoCard
                tenant="John Doe"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
                status="Considering"
                depositStatus="Not Received"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
