import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import currencyFormat from "../../utils/currencyFormat";
import { UncontrolledTooltip } from "reactstrap";
import { PropertyTypes } from "utils/consts";
const configProps = [PropertyTypes.single_family, PropertyTypes.vacant_land];
const commercialProps = [
  PropertyTypes.industrial,
  PropertyTypes.agriculture,
  PropertyTypes.retail,
];

class PropertyCard extends Component {
  render() {
    const inspect_tick = (
      <>
        <i
          className="fa fa-check-circle ml-0 text-success d-inline"
          style={{ fontSize: "20px" }}
          id="tooltip100000"
        ></i>
        <UncontrolledTooltip delay={0} target="tooltip100000">
          Property Inspected
        </UncontrolledTooltip>
      </>
    );
    const hidden_tick = (
      <>
        <i
          className="fa fa-eye-slash ml-2 mb-1 text-warning d-inline"
          style={{ fontSize: "20px" }}
          id="tooltip100000"
        ></i>
        <UncontrolledTooltip delay={0} target="tooltip100000">
          Property Hidden
        </UncontrolledTooltip>
      </>
    );
    const exclamation = (
      <>
        <i
          className="fa fa-exclamation-circle ml-0 text-warning d-inline"
          style={{ fontSize: "20px" }}
          id="tooltip100001"
        ></i>
        <UncontrolledTooltip delay={0} target="tooltip100001">
          Inspection Pending
        </UncontrolledTooltip>
      </>
    );
    const imageClick = () => {
      // this.props.history.push(`/property${this.props.id}`);
      if (this.props.user === "pm") {
        // window.location = `/pm-property${this.props.id}`;
        window.open(`/property${this.props.id}`, "_blank");
      } else {
        //  window.location = `/property${this.props.id}`;
        window.open(`/property${this.props.id}`, "_blank");
      }
    };
    return (
      <>
        <div className="text-center mt-3 shadow rounded">
          <div
            className="mt-2 mr-0"
            style={{ overflow: "hidden", maxWidth: "100%" }}
          >
            <img
              onClick={() => imageClick()}
              alt="..."
              className="img-fluid rounded-top shadow img-center shadow-lg--hover"
              src={this.props.hero_image.replace("uploads", "thumbnails")}
              style={{
                width: "100%",
                height: "200px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
            {/* <div
              className={
                this.props.purpose === "rent" ? "rent_tag" : "sale_tag"
              }
            ></div> */}
            <div
              style={{
                width: "144px",
                height: "36px",
                padding: "5px 5px",
                color: "#ffffff",
                borderRadius: "5px 0px 0px 0px",
                position: "absolute",
                top: "180px",
                right: "15px",
                fontFamily: "HomepageBaukasten-Book",
                fontWeight: "800",
              }}
              className="bg-primary"
            >
              {this.props.price
                ? "  C$ " + currencyFormat(this.props.price)
                : "N/A"}
              {/* {this.props.purpose === "rent"
                ? currencyFormat(this.props.rent)
                : this.props.purpose === "sell"
                ? currencyFormat(this.props.price)
                : ""}
              {this.props.purpose === "rent" ? (
                <small>/ month</small>
              ) : this.props.purpose === "sell" ? (
                ""
              ) : (
                ""
              )} */}
              {/* <small>/ month</small> */}
            </div>
          </div>
          <div
            className="pt-3 px-3 pb-1 text-left"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="overflow-hidden text-left">
              <div className="overflow-hidden">
                <h5 className="title mb-1">
                  <span className="d-inline pr-3 text-capitalize">
                    {this.props.name}
                  </span>
                  {(this.props.auth.user.role ||
                    this.props.auth.user.super_role) &&
                  this.props.inspection_status === "completed"
                    ? inspect_tick
                    : ""}

                  {(this.props.auth.user.role ||
                    this.props.auth.user.super_role) &&
                  this.props.is_active === "false"
                    ? hidden_tick
                    : ""}
                  {this.props.auth.user.role === "owner" &&
                  this.props.inspection_status === "pending"
                    ? exclamation
                    : ""}
                </h5>

                <div className="text-muted pb-1">
                  <span className="fa fa-map-marker mr-2"></span>
                  {this.props.place}, {this.props.city}
                </div>
              </div>

              <span className="float-left">
                <ul className="list-inline-item pl-0">
                  <span>
                    <small className="text-muted pr-2">
                      {this.props.property_type === PropertyTypes.vacant_land
                        ? this.props.land_area + " cents"
                        : this.props.area + " sq.ft"}
                    </small>
                  </span>
                  <small>
                    <li className="list-inline-item px-2 font-weight-bold">
                      {configProps.includes(this.props.property_type) ? (
                        this.props.bedroom + " Beds"
                      ) : commercialProps.includes(this.props.property_type) ? (
                        "Floor: " +
                        this.props.floor_number +
                        " of " +
                        this.props.total_floor_number
                      ) : (
                        <></>
                      )}
                    </li>
                  </small>
                  <small>
                    <li className="list-inline-item px-2 font-weight-bold">
                      {configProps.includes(this.props.property_type) ? (
                        this.props.bathroom + " Bath"
                      ) : (
                        <></>
                      )}
                    </li>
                  </small>
                </ul>
              </span>
              {/* <span className="float-right text-primary ml-2">
                <i className="fa fa-users primary mr-2"></i>
                <small>{this.props.requested} watching</small>
              </span> */}
            </div>
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}
PropertyCard.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(PropertyCard);
