import React, { Component } from "react";
import "./PropertyInfoCard.scss";
import { Col, Row, Button, Modal } from "reactstrap";
import SignupCard from "components/Cards/SignupCard";
import Datetime from "components/Cards/DateTime";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfoWithCta from "components/Modals/InfoWithCta";
import LoginCard from "components/Cards/LoginCard";
import currencyFormat from "../../../utils/currencyFormat";

import NotificationRequestPopup from "components/Modals/NotificationRequestPopup/NotificationRequestPopup";
import { subscribeUser } from "../../../notifications/subscription";

import {
  toggleBookPropVisit,
  registerVisit,
} from "../../../actions/visitActions";
import { updateUserRole } from "../../../actions/authActions";
import {
  mailVisitBookUser,
  mailVisitBookOwner,
  mailVisitBookTenant,
} from "../../../actions/mailActions/visitMails";
import { notifVisitBookAdmin } from "../../../actions/notifActions/visitNotifs";
import "./couch.svg";
import { PropertyTypes } from "utils/consts";
const commercial_prop_types = [PropertyTypes.shop, PropertyTypes.office_space];
class PropertyInfoCard extends Component {
  state = {};
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
    this.props.toggleBookPropVisit(!this.state[state]);
  };

  render() {
    const furnishing_topic = (
      <>
        <Row className="mb-4">
          <Col className="text-center" xs="1">
            <h4 className="d-inline">
              <img
                className="fa text-muted "
                src={require("./couch.svg")}
                alt="furnishing-icon"
              ></img>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Furnishing</h6>
            <small className="d-block">{this.props.furnishing}</small>
          </Col>
        </Row>
      </>
    );
    const pm_spl = (
      <>
        <Row className="mb-4">
          <Col className="text-center" xs="1">
            <h4 className="d-inline">
              <i className="fa fa-user text-muted ml-1"></i>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Owner Details</h6>
            <small className="d-block">
              Name: {this.props.owner_name}
              <br />
              Phone : {this.props.owner_phone}
              <br />
              Email : {this.props.owner_email}
            </small>
          </Col>
        </Row>
      </>
    );
    const pm_kh = (
      <Row className="mb-4">
        <Col className="text-center" xs="1">
          <h4 className="d-inline">
            <i className="fa fa-key text-muted ml-1"></i>
          </h4>
        </Col>
        <Col className="pl-4" xs="11">
          <h6 className="text-primary d-inline">Keyholder Details</h6>
          <small className="d-block">
            {" "}
            Name: {this.props.kh_name}
            <br />
            Phone : {this.props.kh_phone}
          </small>
        </Col>
      </Row>
    );
    const inspection_completed = (
      <Row className="mb-4">
        <Col>
          <i
            class="fa fa-check-circle mr-2 text-success d-inline"
            style={{ fontSize: "20px" }}
          ></i>
          <span className="d-inline text-muted mb-3">Inspection Completed</span>
        </Col>
      </Row>
    );
    const inspection_pending = (
      <Row className="mb-4">
        <Col>
          <i
            class="fa fa-exclamation-circle mr-2 text-warning d-inline"
            style={{ fontSize: "20px" }}
          ></i>
          <span className="d-inline text-muted mb-3">Inspection Pending</span>
        </Col>
      </Row>
    );
    const rent_content = (
      <>
        <Row className="mb-4">
          <Col xs="1">
            <h4 className="d-inline">
              <i className="fa fa-rupee text-muted ml-1"></i>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Rent in detail</h6>
            <small className="d-block">
              C$ {this.props.rent_detail_monthly_basic}/month (Negotiable)
              <br />
              Maintenance : C$ {this.props.rent_detail_monthly_maintenance}/-
              (Monthly)
              <br />
              Deposit : C${" "}
              {(parseInt(this.props.rent_detail_monthly_basic) +
                parseInt(this.props.rent_detail_monthly_maintenance)) *
                3}
              /-
            </small>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center" xs="1">
            <h4 className="d-inline">
              <i className="fa fa-users text-muted"></i>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Available for</h6>
            <small className="d-block text-capitalize">
              {this.props.available_for === "Both"
                ? "Family & Bachelors"
                : this.props.available_for}
            </small>
          </Col>
        </Row>
      </>
    );
    const sell_content = (
      <>
        <Row className="mb-4">
          <Col xs="1">
            <h4 className="d-inline">
              <i className="fa fa-rupee text-muted ml-1"></i>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Price</h6>
            <small className="d-block">
              {this.props.price ? currencyFormat(this.props.price) : ""}{" "}
              (Negotiable)
            </small>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs="1">
            <h4 className="d-inline">
              <i className="fa fa-map text-muted "></i>
            </h4>
          </Col>
          <Col className="pl-4" xs="11">
            <h6 className="text-primary d-inline">Land Area</h6>
            <small className="d-block">{this.props.land_area} cents</small>
          </Col>
        </Row>
      </>
    );
    const visitBookButton = (
      <Button
        color="primary"
        size="lg"
        type="button"
        className="book-cta"
        onClick={() => {
          this.props.auth.isAuthenticated
            ? this.toggleModal("selectDate")
            : this.toggleModal("defaultModal");
        }}
      >
        Book Property Visit
      </Button>
    );
    const editPropertyButton = (
      <Button
        className="btn-icon btn-neutral btn-round ml-auto my-2 mr-3"
        color="primary"
        type="button"
        onClick={() => {
          this.props.history.push({
            pathname: "/pm-edit-property",
          });
        }}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-edit mr-0" />
        </span>
        <span className="btn-inner--text">Edit Property</span>
      </Button>
    );
    const water_source_info = (
      <Row className="mb-4">
        <Col className="text-center" xs="1">
          <h4 className="d-inline">
            <i className="fa fa-tint text-muted ml-1"></i>
          </h4>
        </Col>
        <Col className="pl-4" xs="11">
          <h6 className="text-primary d-inline">Water Source</h6>
          <small className="d-block">{this.props.water_source}</small>
        </Col>
      </Row>
    );
    const floor_number_info = (
      <Row className="mb-4">
        <Col className="text-center" xs="1">
          <h4 className="d-inline">
            <i className="fa fa-arrow-circle-up text-muted ml-1"></i>
          </h4>
        </Col>
        <Col className="pl-4" xs="11">
          <h6 className="text-primary d-inline">Floor Number</h6>
          <small className="d-block">{this.props.floor_number}</small>
        </Col>
      </Row>
    );
    const floor_height_component = (
      <>
        Floor {this.props.floor_number ? this.props.floor_number : "0"}{" "}
        &nbsp;out of &nbsp;{this.props.total_floor_number},&nbsp;
      </>
    );
    const configuration_component = (
      <>
        {this.props.bedroom ? this.props.bedroom : "0"}
        &nbsp;Bedrooms,&nbsp;
        {this.props.bathroom ? this.props.bathroom : "0"}
        &nbsp;Bathrooms,&nbsp;
        {this.props.parking ? this.props.parking : "0"}
        &nbsp;Parking&nbsp;
      </>
    );
    const parking_number_component = (
      <>
        {this.props.parking_number ? this.props.parking_number : "0"}
        &nbsp;Vehicles,&nbsp;
      </>
    );
    return (
      <>
        <a
          className="rounded-circle mb-4  icon icon-shape shadow share-btn"
          style={{ backgroundColor: "#25D366" }}
          href={
            "//api.whatsapp.com/send?text=Stay%20Local%20|%20Have%20a%20look%20at%0D%0Ahttp://www.staylocal.ca/property" +
            this.props.id
          }
        >
          <i className="fa fa-whatsapp text-white" />
        </a>
        <Col lg="6">
          <div className="cardContainer">
            <Row className="mb-1 mt-3">
              <Col>
                <h4>{this.props.name}</h4>
                <h6>
                  {this.props.locality} | {this.props.city}
                </h6>
              </Col>
              <Col className="text-center">
                <h5 className="text-primary font-weight-bold mt-3 price">
                  C${" "}
                  {this.props.purpose === "rent"
                    ? currencyFormat(this.props.rent_detail_monthly_basic)
                    : currencyFormat(this.props.price)}
                  <br />
                  <sub className="font-weight-light">
                    {this.props.purpose === "rent" ? "/month" : ""}
                  </sub>
                </h5>
              </Col>
            </Row>
            {(this.props.auth.user.role === "owner" ||
              this.props.auth.user.super_role === "admin") &&
            this.props.inspection_status === "completed"
              ? inspection_completed
              : ""}
            {(this.props.auth.user.role === "owner" ||
              this.props.auth.user === "admin") &&
            this.props.inspection_status === "pending"
              ? inspection_pending
              : ""}

            {/* <Row className="mb-4 mt-3">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-building text-muted"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">House Features</h6>
                <small className="d-block">{this.props.property_type}</small>
              </Col>
            </Row> */}
            <Row className="mb-4 mt-3">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-home text-muted"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Property Features</h6>
                <small className="d-block">
                  {this.props.area ? this.props.area : "0"}
                  &nbsp;sqft,&nbsp;
                  {[
                    PropertyTypes.apartment,
                    PropertyTypes.shop,
                    PropertyTypes.office_space,
                  ].includes(this.props.property_type) ? (
                    floor_height_component
                  ) : (
                    <></>
                  )}
                  {[
                    PropertyTypes.apartment,
                    PropertyTypes.villa,
                    PropertyTypes.house,
                  ].includes(this.props.property_type) ? (
                    configuration_component
                  ) : (
                    <></>
                  )}
                  {[PropertyTypes.shop].includes(this.props.property_type) ? (
                    parking_number_component
                  ) : (
                    <></>
                  )}
                  {/* {this.props.kitchen ? this.props.kitchen : "0"}
                  &nbsp;Kitchen */}
                </small>
              </Col>
            </Row>
            {this.props.purpose === "rent"
              ? rent_content
              : this.props.purpose === "sell"
              ? sell_content
              : ""}
            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <img
                    className="fa text-muted "
                    src={require("./calendar.svg")}
                    alt="calendar-icon"
                  ></img>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Available from</h6>
                <small className="d-block">{this.props.available_from}</small>
              </Col>
            </Row>
            {this.props.property_type === PropertyTypes.land ||
            commercial_prop_types.includes(this.state.propertyType) ? (
              <></>
            ) : (
              furnishing_topic
            )}
            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-address-book text-muted ml-1"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Address</h6>
                <small className="d-block">{this.props.address}</small>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-map-marker text-muted ml-1"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Parking</h6>
                <small className="d-block">{this.props.parking_number}</small>
              </Col>
            </Row>

            {this.props.property_type === "House" ? water_source_info : <></>}

            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-sticky-note text-muted ml-1"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Remarks</h6>
                <small className="d-block">
                  {this.props.remarks ? this.props.remarks : "No Remarks"}
                </small>
              </Col>
            </Row>
            {this.props.user_role === "pm" ? pm_spl : ""}
            {this.props.user_role === "pm" &&
            this.props.kh_name !== "igen" &&
            this.props.kh_name !== "" &&
            this.props.kh_name
              ? pm_kh
              : ""}
            <div className="mt-5 mb-5 text-center">
              {/* {this.props.user_role === "pm" ? "" : visitBookButton} */}

              {/* <Link
                className="rounded-circle mb-4 bg-primary icon icon-shape shadow share-btn"
                to="google.com"
              >
                <i className="fa fa-share-alt text-white" />
              </Link> */}
              {/* <WhatsappShareButton
                title="Stay Local"
                className="rounded-circle mb-4 bg-primary icon icon-shape shadow share-btn"
              >
                <i className="fa fa-share-alt text-white" />
              </WhatsappShareButton> */}
              {/* <WhatsappIcon className="mb-4" size={32} round={true} /> */}

              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.notifReq}
                toggle={() => this.toggleModal("notifReq")}
                style={{ zIndex: 100 }}
              >
                <NotificationRequestPopup
                  clickAction={() => {
                    subscribeUser(this.props.auth.user.id);
                    this.toggleModal("notifReq");
                  }}
                  clickAction2={() => {
                    this.toggleModal("notifReq");
                    window.location = "/dashboard";
                  }}
                />
              </Modal>

              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span
                    style={{ float: "right", padding: "2px 5px 0px 0px" }}
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </button>
                <SignupCard
                  onDone={() => {
                    this.toggleModal("defaultModal");
                    this.toggleModal("selectDate");
                    //this.toggleModal("notifReq");
                  }}
                  onLoginClick={() => {
                    this.toggleModal("logModal");
                    this.toggleModal("defaultModal");
                  }}
                  return_url={"/property" + this.props.id}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.selectDate}
                toggle={() => this.toggleModal("selectDate")}
              >
                <div className="modal-header border-0">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("selectDate")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <Datetime
                  onDateTime={() => {
                    this.toggleModal("selectDate");
                    const newVisit = {
                      prop_id: this.props.prop.property._id,
                      tenant_id: this.props.auth.user.id,
                      date: this.props.visit.visitDate.toString(),
                      time_interval: this.props.visit.visitTime.toString(),
                      status: "pending",
                      deposit_received: false,
                      consideration: "no_response",
                    };

                    this.props.registerVisit(newVisit, this.props.history);
                    const visitInfo = {
                      u_id: this.props.auth.user.id,
                      prop_id: this.props.prop.property._id,
                      prop_name: this.props.prop.property.name,
                      date: this.props.visit.visitDate.toString(),
                      time_interval: this.props.visit.visitTime.toString(),
                      owner_name: this.props.prop.property.owner_name,
                      owner_email: this.props.prop.property.owner_email,
                      owner_phone: this.props.prop.property.owner_phone,
                      kh_name: this.props.prop.property.kh_name,
                      kh_phone: this.props.prop.property.kh_phone,
                      status: "pending",
                      dateTime: new Date(Date.now()),
                    };
                    //mail to abhay: new visit book
                    notifVisitBookAdmin(visitInfo);
                    mailVisitBookUser(visitInfo);
                    //mail to owner: new visit book
                    //  mailVisitBookOwner(visitInfo);
                    //mail to tenant: new visit book
                    mailVisitBookTenant(visitInfo);

                    //change the user role to tenant
                    const roleChange = {
                      ...this.props.auth.user,
                      role: "tenant",
                    };
                    this.props.updateUserRole(roleChange);
                    // this.toggleModal("success");
                    if (!this.props.auth.user.notifStatus) {
                      this.toggleModal("notifReq");
                    } else {
                      this.toggleModal("success");
                    }
                  }}
                  onBack={() => {
                    if (this.props.auth.isAuthenticated) {
                      this.toggleModal("selectDate");
                    } else {
                      this.toggleModal("selectDate");
                      this.toggleModal("defaultModal");
                    }
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.success}
                toggle={() => this.toggleModal("success")}
              >
                <div className="modal-header border-0">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("success")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <InfoWithCta
                  icon="fa-check-square"
                  title="Visit Request submitted!"
                  action2="GO TO DASHBOARD"
                  clickAction2={() => {
                    window.location = "/dashboard";
                  }}
                  cta="CONTINUE"
                  clickAction={() => {
                    window.location.href = "/properties";
                  }}
                >
                  <p>
                    Our property manager will contact you shortly on the mobile
                    number you provided. <br />
                    Please check the dashboard for progress.
                  </p>
                </InfoWithCta>
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.logModal}
                toggle={() => this.toggleModal("logModal")}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.toggleModal("logModal")}
                >
                  <span
                    style={{ float: "right", padding: "2px 5px 0px 0px" }}
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </button>
                <LoginCard
                  onDone={() => {
                    this.toggleModal("logModal");
                    this.toggleModal("selectDate");
                  }}
                  onSignupClick={() => {
                    this.toggleModal("defaultModal");
                    this.toggleModal("logModal");
                  }}
                  return_url={"/property" + this.props.id}
                />
              </Modal>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

PropertyInfoCard.propTypes = {
  toggleBookPropVisit: PropTypes.func.isRequired,
  registerVisit: PropTypes.func,
  updateUserRole: PropTypes.func,
  auth: PropTypes.object,
  prop: PropTypes.object,
  visit: PropTypes.object,
  errors: PropTypes.object,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
  visit: state.visit,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  toggleBookPropVisit,
  registerVisit,
  updateUserRole,
})(PropertyInfoCard);
