import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import DarkNavbar from "components/Navbars/DarkNavbar";
import OwnerDashboardCard from "components/Cards/OwnerDashboardCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Footer from "components/Footers/Footer/Footer";
class OwnerDashboard extends Component {
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      window.location = "/login-page";
    }
  }
  render() {
    return (
      <>
        <DarkNavbar />
        <Container className="py-lg container-lg" style={{ minHeight: "90vh" }}>
          <h3 className="text-primary mt-3 mb-3 mb-lg-4">My Properties</h3>
          <Row>
            <OwnerDashboardCard />
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
OwnerDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(OwnerDashboard);
