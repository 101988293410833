import React, { Component } from "react";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, UncontrolledCarousel, Container } from "reactstrap";
import OwnerPropertyInfoCard from "../../components/Cards/PropertyInfoCard/OwnerPropertyInfoCard";
import Navbar from "../../components/Navbars/DarkNavbar.jsx";
import Footer from "../../components/Footers/Footer/Footer";
const items = [
  {
    src:
      "https://cdn.staticmb.com/property/img/jumbobanner/spotlight-rhythmccounty1.jpg",
    altText: "",
    caption: "",
    header: "",
  },
  {
    src:
      "https://m.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/11/15/Pictures/dream-house-front_8843d09c-ab15-11e6-b961-04ee4fa7b706.jpg",
    altText: "",
    caption: "",
    header: "",
  },
];

class Property extends Component {
  state = {};
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  render() {
    const { property, loading } = this.props.prop;
    let propInfoCard;
    if (property === null || loading) {
      propInfoCard = <Spinner />;
    } else {
      propInfoCard = (
        <OwnerPropertyInfoCard
          id={property.id}
          area={property.area}
          name={property.name}
          rent={property.rent_monthly_basic}
          bedroom={property.experience.bedroom}
          bathroom={property.experience.bathroom}
          balcony={property.experience.balcony}
          kitchen={property.experience.kitchen}
          place={property.place}
          city={property.city}
          rent_detail_monthly_basic={property.rent_detail.monthly_basic}
          rent_detail_monthly_maintenance={
            property.rent_detail.monthly_maintenance
          }
          available_from={property.available_from}
          available_for={property.available_for}
          furnishing={property.furnishing}
          address={property.address}
          requested={property.requested}
        />
      );
    }
    return (
      <>
        <Navbar />
        <Container className="container-lg py-md">
          <Row className="mt-5 mb-3">
            <Col lg="6">
              <div className="overflow-hidden rounded">
                <UncontrolledCarousel items={items} />
              </div>
            </Col>
            {propInfoCard}
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
Property.propTypes = {
  prop: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
});
export default connect(mapStateToProps, {})(Property);
