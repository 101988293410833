import React, { Component } from "react";
import PMNavbar from "components/Navbars/PMNavbar";
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import VisitRequestCard from "components/Cards/VisitRequestCard";
import VisitRequestApprovedCard from "components/Cards/VisitRequestApprovedCard";
import VisitRequestCompleteCard from "components/Cards/VisitRequestCompleteCard";
import Spinner from "../../components/Common/Spinner";
import { getVisitDash } from "../../actions/visitActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class VisitRequests extends Component {
  state = {
    plainTabs: 1,
  };
  async componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    }
    const tenObject = {
      //status: "pending"
    };

    console.log("tenObject in visit request page", tenObject);
    //get all visits by passing empty object as param
    await this.props.getVisitDash(tenObject);
  }
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  render() {
    const { visitDashs, loading } = this.props.visit;
    let visitReqCard, visitApprovedCard, visitCompletedCard;

    if (visitDashs === null || visitDashs === undefined || loading) {
      visitReqCard = <Spinner />;
      visitApprovedCard = <Spinner />;
      visitCompletedCard = <Spinner />;
    } else {
      if (Object.keys(visitDashs).length > 0) {
        visitReqCard = visitDashs.map((v) =>
          v.visit_status === "pending" ? (
            <Col md="4" lg="4" sm="12">
              <VisitRequestCard
                tenant_id={v.tenant_id}
                prop_id={v.prop_id}
                owner_id={v.owner_id}
                visit_id={v.visit_id}
                propName={v.prop_name}
                tenant={v.ten_name}
                timeSlot={v.visit_time}
                tenantMobile={v.ten_phone}
                bookDate={v.visit_date}
                owner_name={v.owner_name}
                owner_phone={v.owner_phone}
                owner_email={v.owner_email}
                kh_name={v.kh_name}
                kh_phone={v.kh_phone}
              />
            </Col>
          ) : (
            <></>
          )
        );
        visitApprovedCard = visitDashs.map((v) =>
          v.visit_status === "approved" ? (
            <Col md="4" lg="4" sm="12">
              <VisitRequestApprovedCard
                tenant_id={v.tenant_id}
                prop_id={v.prop_id}
                owner_id={v.owner_id}
                visit_id={v.visit_id}
                propName={v.prop_name}
                tenant={v.ten_name}
                timeSlot={v.visit_time}
                tenantMobile={v.ten_phone}
                bookDate={v.visit_date}
                owner_name={v.owner_name}
                owner_phone={v.owner_phone}
                owner_email={v.owner_email}
                kh_name={v.kh_name}
                kh_phone={v.kh_phone}
              />
            </Col>
          ) : (
            <></>
          )
        );
        visitCompletedCard = visitDashs.map((v) =>
          v.visit_status === "completed" ? (
            <Col md="4" lg="4" sm="12">
              <VisitRequestCompleteCard
                consideration={
                  v.visit_consideration ? v.visit_consideration : "no_response"
                }
                deposit_received={
                  v.visit_deposit_received ? v.visit_deposit_received : false
                }
                tenant_id={v.tenant_id}
                prop_id={v.prop_id}
                owner_id={v.owner_id}
                visit_id={v.visit_id}
                propName={v.prop_name}
                tenant={v.ten_name}
                timeSlot={v.visit_time}
                tenantMobile={v.ten_phone}
                bookDate={v.visit_date}
                pr_date={v.pr_date}
                owner_name={v.owner_name}
                owner_phone={v.owner_phone}
                owner_email={v.owner_email}
                kh_name={v.kh_name}
                kh_phone={v.kh_phone}
              />
            </Col>
          ) : (
            <></>
          )
        );
      }
    }

    return (
      <>
        <PMNavbar />
        <Container className="container-lg py-md">
          <h3 className="text-primary mt-lg-5 mt-4 mb-3 mb-lg-4">
            Property Visit Requests
          </h3>
          <Col className="nav-wrapper mt-2 mt-lg-2 pb-0 pb-lg-4" md="6">
            <Nav
              className="nav-fill flex-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 1,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  PENDING
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 2,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  APPROVED
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 3}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 3,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 3)}
                  href="#pablo"
                  role="tab"
                >
                  COMPLETE
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
            <TabPane tabId="plainTabs1">
              <Row>{visitReqCard}</Row>
            </TabPane>
            <TabPane tabId="plainTabs2">
              <Row>{visitApprovedCard}</Row>
            </TabPane>
            <TabPane tabId="plainTabs3">
              <Row>{visitCompletedCard}</Row>
            </TabPane>
          </TabContent>
        </Container>
      </>
    );
  }
}
VisitRequests.propTypes = {
  getVisitDash: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  visit: state.visit,
  auth: state.auth,
});
export default connect(mapStateToProps, { getVisitDash })(VisitRequests);
