import React, { Component } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";

class InfoWithCta extends Component {
  render() {
    return (
      <>
        <div className="modal-body">
          <div className="pb-3 text-center">
            <i
              className={classnames("fa fa-4x text-primary ", this.props.icon)}
            />
            <h4 className="heading mt-4">{this.props.title}</h4>
            {this.props.children}
            {this.props.action2 != null ? (
              <Button
                className="btn-secondary btn-round mt-4 btn-block"
                color="secondary"
                type="button"
                onClick={this.props.clickAction2}
              >
                <small>{this.props.action2}</small>
              </Button>
            ) : null}
            <Button
              className="btn-primary btn-round mt-4 btn-block"
              color="primary"
              type="button"
              onClick={this.props.clickAction}
            >
              <small>{this.props.cta}</small>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default InfoWithCta;
