import React from "react";
import { Col } from "reactstrap";
import "./OptionSelector.scss";
import "../../../assets/fonts/HomepageBaukasten-Book.ttf";
import classnames from "classnames";

class OptionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { optionNumber: this.props.def_option };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  // This function takes a value indicative of the option that's selected. The option that's selected is given the classname selected
  handleClick(val) {
    this.setState({
      optionNumber: val
    });
    this.props.onOptionClick(val);
  }
  render() {
    return (
      <>
        <div className="mt-2">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            {this.props.label}
          </small>
          <div className="d-flex optionSelectorBox">
            <Col className="pr-2 pl-2 align-self-center text-center">
              <button
                type="button"
                className={classnames("option", {
                  selected: this.state.optionNumber === 1
                })}
                onClick={this.handleClick.bind(this, 1)}
                style={{ cursor: "pointer" }}
              >
                {this.props.option1}
                <br />
                <small>{this.props.option1sub}</small>
              </button>
            </Col>
            {/* text-muted class makes the text grayed out to show that the option isn't available */}
            <Col className="pr-1 pl-1 align-self-center text-center">
              <button
                type="button"
                className={classnames("option", {
                  selected: this.state.optionNumber === 2
                })}
                onClick={this.handleClick.bind(this, 2)}
                style={{ cursor: "pointer" }}
              >
                {this.props.option2}
                <br />
                <small>{this.props.option2sub}</small>
              </button>
            </Col>
            {/* selected class highlights the selected option */}
            {this.props.option3 != null ? (
              <Col className="pr-2 pl-2 align-self-center text-center">
                <button
                  type="button"
                  className={classnames("option", {
                    selected: this.state.optionNumber === 3
                  })}
                  onClick={this.handleClick.bind(this, 3)}
                  style={{ cursor: "pointer" }}
                >
                  {this.props.option3}
                  <br />
                  <small>{this.props.option3sub}</small>
                </button>
              </Col>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default OptionSelector;
