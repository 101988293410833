export const PropertyTypes = {
  industrial: "Industrial",
  vacant_land: "Vacant Land",
  agriculture: "Agriculture",
  single_family: "Single Family",
  other: "Other",
  business: "Business",
  retail: "Retail",
};

export const cities = [
  "Bancroft",
  "Barrie",
  "Belleville",
  "Brampton",
  "Brantford",
  "Brockville",
  "Burlington",
  "Cambridge",
  "Chatham",
  "Chatham-Kent",
  "Cornwall",
  "Elliot Lake",
  "Etobicoke",
  "Fort Erie",
  "Fort Frances",
  "Gananoque",
  "Guelph",
  "Hamilton",
  "Iroquois Falls",
  "Kapuskasing",
  "Kawartha Lakes",
  "Kenora",
  "Kingston",
  "Kirkland Lake",
  "Kitchener",
  "Laurentian Hills",
  "London",
  "Midland",
  "Milton",
  "Mississauga",
  "Moose Factory",
  "Moosonee",
  "Niagara Falls",
  "Niagara-on-the-Lake",
  "North Bay",
  "North York",
  "Oakville",
  "Orillia",
  "Oshawa",
  "Ottawa",
  "Parry Sound",
  "Perth",
  "Peterborough",
  "Picton",
  "Port Colborne",
  "Saint Catharines",
  "Saint Thomas",
  "Sarnia-Clearwater",
  "Sault Sainte Marie",
  "Scarborough",
  "Simcoe",
  "Stratford",
  "Sudbury",
  "Temiskaming Shores",
  "Thorold",
  "Thunder Bay",
  "Timmins",
  "Toronto",
  "Trenton",
  "Waterloo",
  "Welland",
  "West Nipissing",
  "Windsor",
  "Woodstock",
  "York",
];
