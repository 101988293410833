import React, { Component } from "react";
import PMNavbar from "components/Navbars/PMNavbar";
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import InspectionRequestCard from "components/Cards/InspectionRequestCard";
import InspectionRequestCompleteCard from "components/Cards/InspectionRequestCompleteCard";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPropDashs } from "../../actions/propActions";

class InspectionRequests extends Component {
  state = {
    plainTabs: 1,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  async componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    }

    await this.props.getPropDashs();
  }
  render() {
    const { prop_dashs, loading } = this.props.prop;
    let request_card, completed_card;

    if (prop_dashs === null || loading) {
      request_card = <Spinner />;
      completed_card = <Spinner />;
    } else {
      if (Object.keys(prop_dashs).length > 0) {
        request_card = prop_dashs.map((p) =>
          p.inspection_status === "pending" && !p.inspection_card_deleted ? (
            <Col md="4" lg="4" sm="12">
              <InspectionRequestCard
                owner_phone={p.owner_phone}
                owner_name={p.owner_name}
                prop_id={p._id}
                locality={p.locality}
                owner_id={p.owner_id}
                prop_name={p.name}
                // owner="John Doe"
                furnishing={p.furnishing}
                address={p.address}
                keyholder={p.kh_name}
                holdertype={p.kh_rel}
                keyholderMobile={p.kh_phone}
                is_active={p.is_active}
                tabChange={() => this.setState({ plainTabs: 2 })}
              />
            </Col>
          ) : (
            <div></div>
          )
        );
        completed_card = prop_dashs.map((p) =>
          p.inspection_status === "completed" && !p.inspection_card_deleted ? (
            <Col md="4" lg="4" sm="12">
              <InspectionRequestCompleteCard
                owner_phone={p.owner_phone}
                owner_name={p.owner_name}
                locality={p.locality}
                prop_id={p._id}
                prop_name={p.name}
                owner_id={p.owner_id}
                // owner="John Doe"
                is_active={p.is_active}
                furnishing={p.furnishing}
                address={p.address}
                keyholder={p.kh_name}
                holdertype={p.kh_rel}
                keyholderMobile={p.kh_phone}
              />
            </Col>
          ) : (
            <div></div>
          )
        );
      }
    }
    return (
      <>
        <PMNavbar />
        <Container className="container-lg py-md">
          <h3 className="text-primary mt-lg-5 mt-4 mb-3 mb-lg-4">
            Property Inspection Requests
          </h3>
          <Col className="nav-wrapper mt-2 mt-lg-2 pb-0 pb-lg-4" md="6">
            <Nav
              className="nav-fill flex-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 1,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  PENDING
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 2,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  COMPLETE
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
            <TabPane tabId="plainTabs1">
              <Row>
                <Col>
                  {/* Date on which the following requests arrived. If it's the same date, then it should say Today */}
                  <h6 className="text-primary text-uppercase mt-4 mb-3">
                    {/* Today */}
                  </h6>
                </Col>
              </Row>
              <Row>{request_card}</Row>
            </TabPane>
            <TabPane tabId="plainTabs2">
              <Row>
                <Col>
                  {/* Date on which the following requests arrived. If it's the same date, then it should say Today */}
                  <h6 className="text-primary text-uppercase mt-4 mb-3">
                    {/* Today */}
                  </h6>
                </Col>
              </Row>
              <Row>{completed_card}</Row>
            </TabPane>
          </TabContent>
        </Container>
      </>
    );
  }
}
InspectionRequests.propTypes = {
  getPropDashs: PropTypes.func,
  auth: PropTypes.object,
  prop: PropTypes.object,
};

const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPropDashs })(InspectionRequests);
