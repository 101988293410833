import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import Footer from "components/Footers/Footer/Footer";
import SignupCard from "components/Cards/SignupCard";
import DarkNavbar from "components/Navbars/DarkNavbar";

class Register extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DarkNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="">
              <Row className="justify-content-center">
                <Col lg="5">
                  <SignupCard return_url={"/properties"} />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default Register;
