import React from "react";
import logo from "./logo_beta.svg";
import "./Logo.scss";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Montserrat:300,400,700", "sans-serif"],
  },
});
class Logo extends React.Component {
  render() {
    const isDesktopOnly = this.props.isDesktopOnly;
    if (isDesktopOnly)
      return (
        <div className="logo desktoponly">
          <img src={logo} alt="company logo"></img>
          <p className="logo-text">Stay Local</p>
        </div>
      );
    else
      return (
        <div className="logo">
          <img src={logo} alt="company logo"></img>
          <p className="logo-text">Stay Local</p>
        </div>
      );
  }
}

export default Logo;
