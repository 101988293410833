import React from "react";

// core components
import DarkNavbar from "components/Navbars/DarkNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";
import PropertyCard from "components/Cards/PropertyCard";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import Carousel from "views/IndexSections/Carousel";
import PhoneInputnew from "components/Inputs/PhoneInput/PhoneInput";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import Alerts from "views/IndexSections/Alerts";
import Buttons from "views/IndexSections/Buttons";
import CustomControls from "views/IndexSections/CustomControls";
import Datepicker from "views/IndexSections/Datepicker";
import Inputs from "views/IndexSections/Inputs";
import Labels from "views/IndexSections/Labels";
import LoginCard from "components/Cards/LoginCard";
import SignupCard from "components/Cards/SignupCard";
import Modals from "views/IndexSections/Modals";
import PaginationSection from "views/IndexSections/Pagination";
import Pills from "views/IndexSections/Pills";
import ProgressSection from "views/IndexSections/Progress";
import TabsSection from "views/IndexSections/Tabs";
import TooltipPopover from "views/IndexSections/TooltipPopover";
import Typography from "views/IndexSections/Typography";
import PropertyInfoCard from "components/Cards/PropertyInfoCard/OwnerPropertyInfoCard";
import PropProgress from "views/TenantPropProgress/TenantPropProgress";
import TenantDashPropCardNew from "components/Cards/TenantDashPropCardNew/TenantDashPropCardNew";
import AddPropSuccess from "views/AddPropertySections/AddPropSuccess";
import VisitRequestCard from "components/Cards/VisitRequestCard";
import NotificationRequestPopup from "components/Modals/NotificationRequestPopup/NotificationRequestPopup";

const items = [
  {
    src:
      "https://m.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/11/15/Pictures/dream-house-front_8843d09c-ab15-11e6-b961-04ee4fa7b706.jpg",
    altText: "",
    caption: "",
    header: ""
  },
  {
    src:
      "https://m.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/11/15/Pictures/dream-house-front_8843d09c-ab15-11e6-b961-04ee4fa7b706.jpg",
    altText: "",
    caption: "",
    header: ""
  }
];

class Information extends React.Component {
  render() {
    return (
      <>
        <DarkNavbar />
        <Container className="container-lg py-md">
          <Row>
            <Col sm="12" md="8" lg="6">
              <NotificationRequestPopup />
            </Col>
          </Row>
          <Row className="mt-2 mb-5">
            <Col lg="4" md="4" sm="12" className="mt-3">
              <VisitRequestCard
                propName="Purva Eternity"
                tenant="John Doe"
                timeSlot="MORNING ( 9AM to 12PM)"
                tenantMobile="+91 8943551467"
                bookDate="14th June, 2019"
              />
            </Col>
            <Col sm="12" lg="4" md="6">
              <PropertyCard
                area="1200"
                name="Trinity Mercury"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad"
                city="Kochi"
                requested="3"
                hero_image={"1"}
              />
            </Col>
            <Col sm="12" lg="4" md="6">
              <PropertyCard
                area="1200"
                name="Trinity Mercury Elite"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad Thrikkakkara"
                city="Kochi"
                requested="3"
                hero_image={"2"}
              />
            </Col>
            <Col sm="12" lg="4" md="6">
              <PropertyCard
                area="1200"
                name="Trinity Mercury Elite"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad Thrikkakkara"
                city="Kochi"
                requested="3"
                hero_image={"3"}
              />
            </Col>
          </Row>
          <Row className="mb-5">
            <AddPropSuccess />
            {/* <Col sm="12" lg="4" md="6">
              <TenantDashPropCard
                area="1200"
                name="Trinity Mercury"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad"
                city="Kochi"
                requested="3"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
              />
            </Col> */}
          </Row>
          <Row>
            <Col sm="12" lg="5" md="6" xl="12">
              <TenantDashPropCardNew
                area="1200"
                name="Trinity Mercury"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad"
                city="Kochi"
                requested="3"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" xl="7">
              <TenantDashPropCardNew
                area="1200"
                name="Trinity Mercury"
                rent="10000"
                bedroom="3"
                bathroom="2"
                place="Kakkanad"
                city="Kochi"
                requested="3"
                timeSlot="MORNING ( 9AM to 12PM)"
                pmMobile="+91 8943551467"
                bookDate="14th June, 2019"
              />
            </Col>

            <Col md="6" sm="12" lg="7" xl="5" className="mt-3">
              <PropProgress />
            </Col>
          </Row>
          <Buttons />
          <Alerts />
          <CustomControls />
          <Datepicker />
          <Row>
            <Col lg="6" md="6" sm="12" className="mt-3">
              <OptionSelector
                label="SELECT TIME"
                option1="MORNING"
                option1sub="09AM-12PM"
                option2="AFTERNOON"
                option2sub="12PM-05PM"
              />
            </Col>
            <Col lg="6" md="6" sm="12" className="mt-4">
              <p className="text-muted mb-4">PHONE INPUT</p>
              <PhoneInputnew />
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <Col lg="5" className="mt-3">
              <SignupCard />
            </Col>
            <Col lg="2"></Col>
            <Col lg="5" className="mt-3">
              <LoginCard />
            </Col>
          </Row>
          <Row>
            <Labels />
          </Row>

          <div className="mt-5 mb-5">
            <Modals />
          </div>

          <Row className="mt-5 mb-5">
            <PaginationSection />
            <Pills />
          </Row>
          <Row className="mt-5 mb-5">
            <ProgressSection />
          </Row>
          <TabsSection />
          <TooltipPopover />
          <Typography />
          <Row className="mt-5 mb-5">
            <Col lg="6">
              <div className="overflow-hidden rounded">
                <UncontrolledCarousel items={items} />
              </div>
            </Col>
            <PropertyInfoCard />
          </Row>
        </Container>
        <Carousel />
        <Inputs />

        <CardsFooter />
      </>
    );
  }
}

export default Information;
