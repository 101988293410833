import React, { Component } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Col,
  Button,
  Row,
  Modal,
} from "reactstrap";
import "./ContactCard.scss";
import isEmpty from "../../../validation/is-empty";
import isValidEmail from "../../../validation/is-valid-email";
import { mailSendEnq } from "../../../actions/mailActions/propMails";
export default class ContactCard extends Component {
  constructor() {
    super();
    this.state = {
      notificationModal: "true",
      enq_name: "",
      enq_email: "",
      enq_msg: "",
      mailSent: false,
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: {} });
  }
  async enqValidate(ed) {
    // this.setState({ errors: {} });
    console.log("this.state.errors after emptying :", this.state.errors);
    console.log("ed :", ed);
    var val = true;
    if (isEmpty(ed.enq_name)) {
      val = false;
      this.setState((state) => ({
        errors: { ...state.errors, name: "Please enter a valid name" },
      }));
      //this.setState({ errors: "Please enter a valid name" });
    }

    if (isEmpty(ed.enq_msg)) {
      val = false;
      this.setState((state) => ({
        errors: {
          ...state.errors,
          msg: "Please enter a valid message",
        },
      }));
    }
    if (isEmpty(ed.enq_email) || !isValidEmail(ed.enq_email)) {
      val = false;
      this.setState((state) => ({
        errors: {
          ...state.errors,
          email: "Please enter a valid email ID",
        },
      }));
    }
    console.log("this.state.errors :", this.state.errors);
    return val;
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ errors: {} }, async () => {
      const enq_data = {
        enq_name: this.state.enq_name,
        enq_email: this.state.enq_email,
        enq_msg: this.state.enq_msg,
      };
      console.log("enq_data :", enq_data);
      if (await this.enqValidate(enq_data)) {
        mailSendEnq(enq_data);
        this.setState({
          mailSent: true,
          enq_name: "",
          enq_email: "",
          enq_msg: "",
        });
      }
    });
  }
  toggleModal = (state) => {
    this.setState({
      notificationModal: !this.state.notificationModal,
    });
  };
  render() {
    const { errors } = this.state;
    return (
      <>
        <Card className="shadow contact-card" style={{ borderRadius: "15px" }}>
          <CardBody className="p-lg-5">
            <Row className="justify-content-center">
              <Col md="6" className="d-flex flex-column align-self-center">
                <div className="px-lg-5">
                  <h1 className="mb-1 text-white">Get in Touch</h1>
                  <p className="mt-0 text-white">
                    Thank you for visiting us and we are always keen to hear
                    from you. Please fill the following application form and our
                    Property Manager will reply to all your enquiries in under
                    24 hours.
                  </p>
                </div>
              </Col>
              <Col md="6" className="py-3 px-lg-5">
                <form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Input
                      placeholder="Full Name"
                      type="text"
                      name="enq_name"
                      value={this.state.enq_name}
                      onChange={this.onChange}
                    />

                    {errors.name && (
                      <div className="text-warning text-center">
                        <small>{errors.name}</small>
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="E-mail"
                      type="email"
                      name="enq_email"
                      value={this.state.enq_email}
                      onChange={this.onChange}
                    />

                    {errors.email && (
                      <div className="text-warning text-center">
                        <small>{errors.email}</small>
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Input
                      className="form-control-alternative"
                      cols="80"
                      name="enq_msg"
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                      value={this.state.enq_msg}
                      onChange={this.onChange}
                    />
                    {errors.msg && (
                      <div className="text-warning text-center">
                        <small>{errors.msg}</small>
                      </div>
                    )}
                  </FormGroup>
                  {this.state.mailSent === true && (
                    <Modal
                      className="modal-dialog-centered modal-danger"
                      contentClassName="bg-gradient-success"
                      isOpen={this.state.notificationModal}
                      toggle={() => this.toggleModal("notificationModal")}
                    >
                      <div className="modal-header">
                        <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => this.toggleModal("notificationModal")}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="py-3 text-center">
                          <i className="ni ni-check-bold ni-3x" />
                          <h4 className="heading mt-4">
                            Message Sent Successfully !
                          </h4>
                          <p>
                            Thank you for your message.
                            <br />
                            We will get back to you at the earliest.
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <Button
                          className="btn-white"
                          color="default"
                          type="button"
                          onClick={() => this.toggleModal("notificationModal")}
                        >
                          Ok, Got it
                        </Button>
                        <Button
                          className="text-white ml-auto"
                          color="link"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => this.toggleModal("notificationModal")}
                        >
                          Close
                        </Button>
                      </div>
                    </Modal>
                  )}
                  <div>
                    <Button
                      block
                      className="btn-round form-cta"
                      size="lg"
                      type="submit"
                    >
                      Send Message
                    </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}
