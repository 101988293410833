import axios from "../../client";
export const mailVisitBookUser = (visitInfo) => {
  axios
    .post("/api/visitMails/mailVisitBookUser", visitInfo)
    .then((res) => {})
    .catch((err) => {});
};
export const mailVisitBookOwner = (visitInfo) => {
  axios
    .post("/api/visitMails/mailVisitBookOwner", visitInfo)
    .then((res) => {})
    .catch((err) => {});
};
export const mailVisitBookTenant = (visitInfo) => {
  axios
    .post("/api/visitMails/mailVisitBookTenant", visitInfo)
    .then((res) => {})
    .catch((err) => {});
};
export const mailVisitBookApprovedOwner = (visitInfo) => {
  console.log("mailVisitBookApprovedOwner :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitBookApprovedOwner", visitInfo)
    .then((res) => {
      console.log("mailVisitBookApprovedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailVisitBookApprovedTenant = (visitInfo) => {
  console.log("mailVisitBookApprovedTenant :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitBookApprovedTenant", visitInfo)
    .then((res) => {
      console.log("mailVisitBookApprovedTenant", res.data);
    })
    .catch((err) => {});
};

//done till now 26 mar 1:34 AM
export const mailPaymentRequest = (visitInfo) => {
  console.log("mailPaymentRequest :", visitInfo);
  axios
    .post("/api/visitMails/mailPaymentRequest", visitInfo)
    .then(async (res) => {
      console.log("mailPaymentRequest", res.data);
    })
    .catch((err) => {});
};
export const mailDepositReceivedTenant = (visitInfo) => {
  console.log("mailDepositReceivedTenant :", visitInfo);
  axios
    .post("/api/visitMails/mailDepositReceivedTenant", visitInfo)
    .then(async (res) => {
      console.log("mailDepositReceivedTenant", res.data);
    })
    .catch((err) => {});
};
export const mailDepositReceivedOwner = (visitInfo) => {
  console.log("mailDepositReceivedOwner :", visitInfo);
  axios
    .post("/api/visitMails/mailDepositReceivedOwner", visitInfo)
    .then(async (res) => {
      console.log("mailDepositReceivedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailVisitBookRescheduledTenant = (visitInfo) => {
  console.log("mailVisitBookRescheduledTenant :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitBookRescheduledTenant", visitInfo)
    .then(async (res) => {
      console.log("mailVisitBookRescheduledTenant", res.data);
    })
    .catch((err) => {});
};
export const mailVisitBookRescheduledOwner = (visitInfo) => {
  console.log("mailVisitBookRescheduledOwner :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitBookRescheduledOwner", visitInfo)
    .then(async (res) => {
      console.log("mailVisitBookRescheduledOwner", res.data);
    })
    .catch((err) => {});
};
export const mailChangeConsiderationTenant = (visitInfo) => {
  console.log("mailChangeConsiderationTenant :", visitInfo);
  axios
    .post("/api/visitMails/mailChangeConsiderationTenant", visitInfo)
    .then(async (res) => {
      console.log("mailChangeConsiderationTenant", res.data);
    })
    .catch((err) => {});
};
export const mailVisitCompletedOwner = (visitInfo) => {
  console.log("mailVisitCompletedOwner :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitCompletedOwner", visitInfo)
    .then(async (res) => {
      console.log("mailVisitCompletedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailVisitCompletedTenant = (visitInfo) => {
  console.log("mailVisitCompletedTenant :", visitInfo);
  axios
    .post("/api/visitMails/mailVisitCompletedTenant", visitInfo)
    .then(async (res) => {
      console.log("mailVisitCompletedTenant", res.data);
    })
    .catch((err) => {});
};
export const mailChangeConsiderationOwner = (visitInfo) => {
  console.log("mailChangeConsiderationOwner :", visitInfo);
  axios
    .post("/api/visitMails/mailChangeConsiderationOwner", visitInfo)
    .then(async (res) => {
      console.log("mailChangeConsiderationOwner", res.data);
    })
    .catch((err) => {});
};
export const mailChangeConsiderationAdmin = (visitInfo) => {
  console.log("mailChangeConsiderationAdmin :", visitInfo);
  axios
    .post("/api/visitMails/mailChangeConsiderationAdmin", visitInfo)
    .then(async (res) => {
      console.log("mailChangeConsiderationAdmin", res.data);
    })
    .catch((err) => {});
};
