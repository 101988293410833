import React, { useState, Component } from "react";
import Navbar from "components/Navbars/DarkNavbar";
import PropertyCard from "components/Cards/PropertyCard";
import AddPropertyCTA from "components/Cards/AddPropertyCTA";
import "./LandingTenant.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Badge,
  Card,
  CardBody,
} from "reactstrap";
import Footer from "components/Footers/Footer/Footer";
import { getFeaturedProps } from "../../../actions/propActions";
import Spinner from "../../../components/Common/Spinner";
import ContactCard from "../../../components/Cards/ContactCard/ContactCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const items = [
  {
    id: 1,
    heading: "Discover Your Dream Home",
    content: "We connect genuine homeowners to tenants and tenants to homes.",
    altText: "Slide 1",
  },
  {
    id: 2,
    heading: "Property Search",
    content:
      "Browse through a range of housing options that are available in the location of your choice.",
    altText: "Slide 2",
  },
  {
    id: 3,
    heading: "Guided Apartment Visit",
    content:
      "Inspect your potential home with our Property Manager. You will be eligible for a booking amount refund, if you wish to choose another property.",
    altText: "Slide 3",
  },
  {
    id: 4,
    heading: "Sign in and Move in",
    content:
      "Sit back and relax while we get all the paperwork sorted. All you need to do is to sign in and move in.",
    altText: "Slide 4",
  },
];

const Slider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={
            (require("./../../../assets/img/illustrations/hero.webp"),
            require("./../../../assets/img/illustrations/hero.jpg"))
          }
          alt={item.altText}
          className="hero-image"
        />
        <Container>
          <Row className="text-center hero-container">
            <Col md="12" lg="12" className="text-lg-center">
              <h1 className="display-3 text-white">{item.heading}</h1>
              <p className="lead text-white">{item.content}</p>
              <div className="btn-wrapper mx-auto">
                <Button
                  className="btn-white mb-3 mb-sm-0 ml-1 mx-lg-auto"
                  color="default"
                  href="/properties"
                >
                  VIEW ALL PROPERTIES
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

class LandingTenant extends Component {
  componentDidMount() {
    this.props.getFeaturedProps();
  }

  render() {
    const { featured_props, loading } = this.props.prop;
    let featuredPropertiesContent;

    if (featured_props === null || loading) {
      featuredPropertiesContent = (
        <div className="mx-auto">
          <Spinner />
        </div>
      );
    } else {
      if (Object.keys(featured_props).length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        featuredPropertiesContent = featured_props.map((p) => (
          <Col sm="12" lg="4" md="6">
            <PropertyCard
              area={p.area}
              name={p.name}
              rent={p.rent_monthly_basic}
              price={p.price}
              bedroom={p.bedroom}
              bathroom={p.bathroom}
              place={p.locality}
              city={p.city}
              requested={p.requested}
              purpose={p.purpose}
              hero_image={
                Array.isArray(p.gallery) && p.gallery.length
                  ? p.gallery[0] //this.getImage(p.gallery[0])
                  : default_pic
              }
            />
          </Col>
        ));
      }
    }

    return (
      <>
        <Navbar transparent tenant />
        <main ref="main" className="tenant">
          <div className="hero-holder">
            <div className="position-relative">
              <section className="section section-md section-shaped hero">
                <div className="shape bg-primary"></div>
                <Container className="py-lg-md d-flex hero-text">
                  <div className="col px-0">
                    <Row>
                      <Col lg="12" className="text-lg-center">
                        <h1 className="display-3 text-white">
                          Discover Your Dream Home
                        </h1>
                        <p className="lead text-white">
                          We connect genuine homeowners to tenants and tenants
                          to homes.
                        </p>
                        <div className="btn-wrapper mx-lg-auto">
                          <Button
                            className="btn-white mb-sm-0 ml-1 mx-lg-auto"
                            color="default"
                            href="/properties"
                          >
                            <span className="btn-inner--text">
                              View All Properties
                            </span>
                          </Button>
                          <div className="ml-1 mt-2">
                            <small>
                              <a
                                className="text-white text-underline"
                                href="/properties"
                              >
                                Or Want to Buy/Rent a Property?
                              </a>
                            </small>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div className="separator separator-bottom separator-skew hero-separator">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                  >
                    <polygon
                      className="fill-white"
                      points="2560 0 2560 100 0 100"
                    />
                  </svg>
                </div>
              </section>
            </div>
          </div>
          <Container>
            <section className="section section-md featured-properties">
              <h2 className="text-center mb-3 mb-lg-4">Featured Properties</h2>

              <a href="/properties">
                <Row>{featuredPropertiesContent}</Row>
              </a>
              <div className="btn-wrapper text-center mx-auto">
                <Button
                  color="primary"
                  size="lg"
                  className="mx-auto my-3 my-lg-5"
                  block
                  href="/properties"
                >
                  View All Properties
                </Button>
              </div>
            </section>
          </Container>
          <section className="section section-md bg-primary">
            <Container>
              <div>
                <h3 className="text-white mb-3">Who We Are</h3>
                <p className="text-white quote font-weight-100">
                  iGen is a property management company that connects genuine
                  homeowners to tenants and tenants to homes than just concrete
                  buildings. Our portfolio includes properties that can suit
                  different lifestyles and we strive to excel in our services
                  through our professional and technologically-driven approach.
                </p>
              </div>
            </Container>
          </section>
          <section className="section section-md">
            <Container>
              <Row>
                <Col sm="12" md="4">
                  <Card className="shadow shadow-lg--hover mt-3 feature-card">
                    <CardBody className="py-5">
                      <div className="text-center">
                        <i className="fa fa-compass fa-4x text-primary" />
                      </div>
                      <div className="text-center">
                        <h5 className="title text-uppercase pt-3">
                          Property Search
                        </h5>
                        <p className="pt-1">
                          Browse through a range of housing options that are
                          available in the location of your choice.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="4">
                  <Card className="shadow shadow-lg--hover mt-3 feature-card">
                    <CardBody className="py-3">
                      <div className="text-center">
                        <i className="fa fa-home fa-4x text-primary" />
                      </div>
                      <div className="text-center">
                        <h5 className="title text-uppercase pt-3">
                          Guided Apartment Visit
                        </h5>
                        <p className="pt-1">
                          Inspect your potential home with our Property Manager.
                          You will be eligible for a booking amount refund, if
                          you wish to choose another property.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="4">
                  <Card className="shadow shadow-lg--hover mt-3 feature-card">
                    <CardBody className="py-5">
                      <div className="text-center">
                        <i className="fa fa-truck fa-4x text-primary" />
                      </div>
                      <div className="text-center">
                        <h5 className="title text-uppercase pt-3">
                          Sign in and Move in
                        </h5>
                        <p className="pt-1">
                          Sit back and relax while we get all the paperwork
                          sorted. All you need to do is to sign in and move in.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-md">
            <Container>
              <h2 className="mb-4">Why iGen?</h2>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("./../../../assets/img/illustrations/why-igen.svg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-like-2" />
                    </div>
                    <h3>Partner That You Can Bank Upon</h3>
                    <p>
                      iGen can partner with you to find the right rental
                      property and our partnership doesn’t end there. We will
                      address all your queries and maintenance requests quickly
                      and effectively throughout the lease term.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-credit-card" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Zero Brokerage Fees</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-favourite-28" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Sealed With Integrity</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Fair Contract Terms</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <div className="btn-wrapper text-center mx-auto">
                <Button
                  color="primary"
                  size="lg"
                  className="mx-auto my-2 my-lg-4"
                  href="/properties"
                  block
                >
                  Get Started
                </Button>
              </div>
            </Container>
          </section>
          <section className="section pt-lg-0 section-contact-us">
            <div className="container-fluid">
              <ContactCard />
            </div>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <AddPropertyCTA />
            </Container>
          </section>
          <Footer />
        </main>
      </>
    );
  }
}
LandingTenant.propTypes = {
  getFeaturedProps: PropTypes.func,
  auth: PropTypes.object,
  prop: PropTypes.object,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getFeaturedProps,
})(LandingTenant);
