const getCurrentDate = (separator = "-") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hrs = newDate.getHours();
  let mins = newDate.getMinutes();
  let secs = newDate.getSeconds();
  let time24 = hrs + ":" + mins + ":" + secs;
  let time12 = tConvert(time24);

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year} ${time12}`;
};

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export default getCurrentDate;

// ${hrs}${separator}${
//     month < 10 ? `0${mins}` : `${mins}`
//   }${separator}${secs}`
