import axios from "../../client";

export const sendTest = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function(registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function(existedSubscription) {
            console.log("Existed subscription detected.");
            const userData = JSON.stringify(existedSubscription);
            return axios
              .post("/api/notifications/sendNotifTest", userData, {
                headers: { "Content-Type": "application/json" }
              })
              .then(res => {})
              .catch(err => {});
          });
      })
      .catch(function(e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
};
