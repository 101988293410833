import React, { Component } from "react";
import "../TenantPropProgress/TenantPropProgress.scss";

class TenantPropProgress extends Component {
  render() {
    return (
      <>
        <div className="d-flex flex-column bg-secondary px-4 py-2">
          <h4 className="text-primary mt-3 mb-3 mb-lg-4">
            {this.props.prop_name}
          </h4>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="d-inline-block">Confirm this property</p>
            <label className="custom-toggle">
              <input type="checkbox" />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="d-inline-block text-black">Considering</p>
            <label className="custom-toggle">
              <input type="checkbox" />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>
          <div className="my-3">
            <ul class="StepProgress">
              <li class="StepProgress-item is-done">
                <i className="fa fa-home text-primary" />
                Property visit approved
              </li>
              <li class="StepProgress-item current">
                <i className="fa fa-inr text-primary ml-1 mr-3" />
                Transfer 5k holding deposit
              </li>
              <li class="StepProgress-item text-muted">
                <i className="fa fa-money text-primary ml-0 mr-2" />
                Holding deposit received
              </li>
              <li class="StepProgress-item text-muted">
                <i className="fa fa-question ml-1 mr-3" />
                Next Step
              </li>
              <li class="StepProgress-item text-muted">
                <i className="fa fa-question ml-1 mr-3" />
                Next Step
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default TenantPropProgress;
