import axios from "../client";
import {
  BOOK_PROP_VISIT,
  SAVE_VISIT_DATE,
  SAVE_VISIT_TIME,
  SAVE_VISIT_USER,
  VISIT_LOADING,
  GET_TENANT_DASHS,
  GET_OWNER_VISITS,
  GET_ERRORS,
} from "./types";

export const toggleBookPropVisit = (val) => {
  return {
    type: BOOK_PROP_VISIT,
    payload: val,
  };
};
export const saveVisitDate = (val) => {
  return {
    type: SAVE_VISIT_DATE,
    payload: val,
  };
};
export const saveVisitTime = (val) => {
  return {
    type: SAVE_VISIT_TIME,
    payload: val,
  };
};
export const saveVisitUser = (val) => {
  return {
    type: SAVE_VISIT_USER,
    payload: val,
  };
};
export const registerVisit = (visitData, history) => (dispatch) => {
  axios
    .post("/api/visits/register", visitData)
    .then((res) => history.push("/dashboard"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: {},
      })
    );
};

//card data for tenant dashboard
export const getVisitDash = (tenant_object) => (dispatch) => {
  dispatch(setVisitLoading());
  axios
    .post("/api/visits/dashInput", tenant_object)
    .then((res) => {
      dispatch({
        type: GET_TENANT_DASHS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_TENANT_DASHS,
        payload: { noData: "no Data" },
      })
    );
};

export const getPropVisits = (propData) => (dispatch) => {
  dispatch(setVisitLoading());
  axios
    .post("/api/visits/propVisits", propData)
    .then((res) => {
      dispatch({
        type: GET_OWNER_VISITS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_OWNER_VISITS,
        payload: { noData: "no Data" },
      })
    );
};
export const setVisitLoading = () => {
  return {
    type: VISIT_LOADING,
  };
};
export const rescheduleVisit = (visitDateTime) => (dispatch) => {
  axios
    .post("/api/visits/reschedule", visitDateTime)
    .then((res) => {
      dispatch(getVisitDash({}));
    })
    .catch((err) => console.log(err));
};
export const changeStatus = (statusData) => (dispatch) => {
  axios
    .post("/api/visits/changeStatus", statusData)
    .then((res) => {
      console.log("status change result", res.data);
      dispatch(getVisitDash({}));
    })
    .catch((err) => console.log(err));
};
export const changeConsideration = (considData) => (dispatch) => {
  const cData = {
    id: considData.id,
    updateBody: { consideration: considData.consideration },
  };

  axios
    .post("/api/visits/visitUpdate", cData)
    .then((res) => {
      console.log("consideration change result", res.data);
      dispatch(
        getVisitDash(
          considData.tenant_id ? { tenant_id: considData.tenant_id } : {}
        )
      );
    })
    .catch((err) => console.log(err));
};
export const changeDepositReceived = (depositData) => (dispatch) => {
  const cData = {
    id: depositData.id,
    updateBody: { deposit_received: depositData.deposit_received },
  };
  console.log("cData of deposit_received", cData);
  axios
    .post("/api/visits/visitUpdate", cData)
    .then((res) => {
      dispatch(getVisitDash({}));
    })
    .catch((err) => console.log(err));
};
export const deleteVisit = (dat, redirectTo = "/visit-requests") => {
  axios
    .delete("/api/visits/delete_visit", dat)
    .then((res) => {
      console.log("delete visit res", res.data);
      window.location = redirectTo;
    })
    .catch((err) => console.log("error"));
};
