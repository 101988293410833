import React, { Component } from "react";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import "./EditProperty.scss";

import {
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Form,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  updateProp,
  updateGallery,
  setThumbnail,
} from "../../../actions/propActions";
import { deleteImages, uploadImages } from "../../../actions/imageActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateInput from "components/Inputs/DateInput";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import PhoneInputnew from "components/Inputs/PhoneInput/PhoneInput";
import axios from "axios";
import Gallery from "react-grid-gallery";
import Navbar from "components/Navbars/PMNavbar";
import imageCompression from "browser-image-compression";
import "./EditProperty.scss";
import Swal from "sweetalert2";
import Spinner from "../../../components/Common/Spinner";
import { PropertyTypes } from "../../../utils/consts";
const commercial_prop_types = [PropertyTypes.shop, PropertyTypes.office_space];

const residential_prop_types = [
  PropertyTypes.land,
  PropertyTypes.apartment,
  PropertyTypes.house,
  PropertyTypes.villa,
];

class EditProperty extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      name: "",
      area: "",
      land_area: "",
      price: "",
      city: "",
      locality: "",
      address: "",
      bedroom: "",
      bathroom: "",
      parking: "",
      purpose: "",
      furnishing: "",
      rent_monthly_basic: "",
      rent_monthly_maintenance: "",
      available_from: "",
      available_for: "",
      image_objects: [],
      pictures: [],
      gallery: [],
      remarks: "",
      kh_rel: "",
      kh_phone: "",
      kh_name: "",
      img_selected: false,
      is_active: "",
      is_rented_out: "",
      is_sold: "",
      inspection_status: "",
      dupFileNames: [],
      loading: false,
      one_sel_image: false,
      property_id: "",
      property_type: "",
      total_floor_number: "",
      parking_number: "",
      floor_number: "",
      water_source: "",
      landmark: "",

      //key_holder: { kh: "igen", key_holder_phone: "0" },
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    // this.onDrop = this.onDrop.bind(this);
  }
  onDropDown(propertyType) {
    var offerType = commercial_prop_types.includes(propertyType)
      ? "Commercial"
      : "Residential";
    this.setState({ property_type: propertyType, offerType: offerType });
  }
  getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  delFn = () => {
    var selImages = this.getSelectedImages();
    Swal.fire({
      title: "Delete " + selImages.length + " images?",
      text: "Selected image(s) will be permanantly deleted from the system!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        var imgKeys = [];
        for (let i = 0; i < selImages.length; i++) {
          imgKeys = imgKeys.concat(selImages[i].src.substring(54));
        }
        var del_imgs_obj = { del_imgs: imgKeys };
        deleteImages(del_imgs_obj);
        //code to delete these elments from state.gallery[]
        updateGallery({ id: this.state._id, gallery: this.state.gallery });
        var gal = this.state.gallery;

        var index = -1;
        for (var j = 0; j < gal.length; j++) {
          for (var i = 0; i < selImages.length; i++) {
            if (gal[j] === selImages[i].src) {
              console.log("index  :", j);
              gal.splice(j, 1);
            }
          }
        }
        console.log("gal", gal);
        this.setState({ gallery: gal }, () => {
          console.log("gallery after proceed", this.state.gallery);
          var image_objects = [];
          for (let i = 0; i < this.state.gallery.length; i++) {
            image_objects = image_objects.concat({
              src: this.state.gallery[i],
              thumbnail: this.state.gallery[i].replace("uploads", "thumbnails"),
              thumbnailWidth: 320,
              thumbnailHeight: 212,
              isSelected: false,
            });
          }
          this.setState({ image_objects: image_objects });
        });
      } else {
        console.log("Deletion Failed", "Please try again !");
      }
    });
  };

  makeThumbnail() {
    var selImages = this.getSelectedImages();
    const thumbnail_obj = {
      thumbnail: selImages[0].thumbnail,
      id: this.props.prop.property._id,
    };
    console.log(
      "this.props.prop.property.id :>> ",
      this.props.prop.property._id
    );
    setThumbnail(thumbnail_obj);
  }
  async componentDidMount() {
    var auth = this.props.auth;
    if (!auth.isAuthenticated) {
      window.location = "pm-login";
    } else if (auth.user.super_role !== "admin") {
      window.location = "pm-login";
    } else {
      if (this.props.prop.property != null) {
        this.setState({ ...this.state, ...this.props.prop.property }, () => {
          var image_objects = this.state.image_objects;
          for (let i = 0; i < this.state.gallery.length; i++) {
            image_objects = image_objects.concat({
              src: this.state.gallery[i],
              thumbnail: this.state.gallery[i].replace("uploads", "thumbnails"),
              thumbnailWidth: 320,
              thumbnailHeight: 212,
              isSelected: false,
            });
          }
          console.log("image object Component did mount", image_objects);
          this.setState({ image_objects: image_objects });
        });
      }
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async onSubmit(e) {
    e.preventDefault();
    const propInfo = this.state;
    this.props.updateProp(this.props.prop.property._id, propInfo);
  }

  handleImageUpload(event) {
    if (this.state.gallery.length + event.target.files.length > 10) {
      Swal.fire(
        "You can only upload upto 10 pictures!",
        "Please retry!",
        "error"
      );
      event.target.value = "";
    } else {
      this.setState({ loading: true });
      var newName = "",
        dupFile;
      var Cfiles = [];
      const files = event.target.files;
      var lp = new Promise((resolve, reject) => {
        Array.from(files).forEach((imageFile, index, array) => {
          console.log(
            "originalFile instanceof Blob",
            imageFile instanceof Blob
          ); // true
          console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

          var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          imageCompression(imageFile, options)
            .then(function (compressedFile) {
              console.log(
                "compressedFile instanceof Blob",
                compressedFile instanceof Blob
              ); // true
              console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
              ); // smaller than maxSizeMB

              Cfiles.push(compressedFile);
              console.log("cfiles", Cfiles, array.length);
              if (Cfiles.length === array.length) resolve();
            })
            .catch(function (error) {
              console.log("error", error.message);
            });
        });
      });
      lp.then(() => {
        const data = new FormData();
        var str = [];
        var pics = [];
        var pic_urls2 = [];

        this.setState(
          {
            pictures: Cfiles,
          },
          () => {
            console.log("pictures:", this.state.pictures);
          }
        );
        var getRandomNumber = (min, max) => {
          return Math.floor(Math.random() * (max - min)) + min;
        };
        for (var x = 0; x < Cfiles.length; x++) {
          str = Cfiles[x].name.split(".");
          newName = getRandomNumber(100000, 999999).toString() + "." + str[1];
          pics = pics.concat(newName);
          dupFile = new File([Cfiles[x]], newName);
          this.setState({
            dupFileNames: this.state.dupFileNames.concat(newName),
          });
          data.append("file", dupFile);
        }

        axios
          .post("/api/images/upload", data, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                loadProgressBar();
              }
            },
          })
          .then((res) => {
            console.log(
              "images_data from image api: " + JSON.stringify(res.data)
            ); //res.locationArray); //
            for (var x = 0; x < res.data.locationArray.length; x++) {
              pic_urls2 = pic_urls2.concat(res.data.locationArray[x]);
            }
            console.log("this.state.gallery", this.state.gallery);
            console.log(
              "{ ...this.state.gallery, pic_urls2 }  :",
              this.state.gallery.concat(...pic_urls2)
            );
            this.setState(
              { gallery: this.state.gallery.concat(...pic_urls2) },
              () => {
                updateGallery({
                  id: this.state._id,
                  gallery: this.state.gallery,
                });
              }
            );
            var thumbFiles = [];
            var lp = new Promise((resolve, reject) => {
              var tFiles = files;
              console.log("this.state.dupFileNames :", this.state.dupFileNames);
              Array.from(tFiles).forEach((imageFile, index, array) => {
                dupFile = new File([Cfiles[x]], newName);

                console.log(
                  "originalFile instanceof Blob",
                  imageFile instanceof Blob
                ); // true
                console.log(
                  `originalFile size ${imageFile.size / 1024 / 1024} MB`
                );

                var options = {
                  maxSizeMB: 0.05,
                  maxWidthOrHeight: 300,
                  useWebWorker: true,
                };

                imageCompression(imageFile, options)
                  .then(function (compressedFile) {
                    console.log(
                      "compressedFile instanceof Blob",
                      compressedFile instanceof Blob
                    ); // true
                    console.log(
                      `compressedFile size ${
                        compressedFile.size / 1024 / 1024
                      } MB`
                    ); // smaller than maxSizeMB

                    thumbFiles.push(compressedFile);
                    console.log("imageFile :", imageFile);

                    console.log("thumbFiles", thumbFiles, array.length);
                    if (thumbFiles.length === array.length) resolve();
                  })
                  .catch(function (error) {
                    console.log("error", error.message);
                  });
              });
            });

            lp.then(() => {
              const thumbData = new FormData();
              var dupThumb;
              for (var y = 0; y < thumbFiles.length; y++) {
                dupThumb = new File(
                  [thumbFiles[y]],
                  this.state.dupFileNames[y]
                );
                thumbData.append("file", dupThumb);
              }

              axios
                .post("/api/images/uploadThumbnails", thumbData, {
                  onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                      loadProgressBar();
                    }
                  },
                })
                .then((res) => {
                  // console.log("thumbnail uploaded");
                  this.setState({ loading: false });
                });
            });
          });
      });
    }
  }
  getSelectedImages = () => {
    var selected = [];
    for (var i = 0; i < this.state.image_objects.length; i++)
      if (this.state.image_objects[i].isSelected === true)
        selected.push(this.state.image_objects[i]);
    return selected;
  };

  onSelectImage(index, image) {
    var images = this.state.image_objects.slice();
    var img = images[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;

    this.setState({
      image_objects: images,
    });

    var sel = this.getSelectedImages();

    sel.length === 1
      ? this.setState({ one_sel_image: true })
      : this.setState({ one_sel_image: false });

    sel.length < 1
      ? this.setState({ img_selected: false })
      : this.setState({ img_selected: true });

    console.log("state after sel", this.state);
  }

  render() {
    let currProp = this.props.prop.property; // this.state; //

    const saveButton = !this.state.loading ? (
      <>
        {" "}
        <Button
          block
          type="submit"
          className="mt-5 btn-round"
          color="primary"
          //onClick={() => this.props.onProceed()}
          style={{
            margin: "0px auto",
            maxWidth: "300px",
            padding: "15px 10px",
            borderRadius: "24px",
          }}
        >
          Save
        </Button>
      </>
    ) : (
      <>
        <Spinner />
        <p className="text-center">Pictures Uploading. Please Wait...</p>
      </>
    );
    const kh_content = (
      <>
        <OptionSelector
          label="Select the Keyholder"
          option1="HOUSE OWNER"
          option2="CARETAKER/ NEIGHBOUR/ FRIEND"
          option3="APARTMENT SECURITY"
          def_option={
            currProp === null
              ? null
              : currProp.kh_rel === "igen"
              ? null
              : currProp.kh_rel === "Caretaker"
              ? 2
              : currProp.kh_rel === "Apartment Security"
              ? 3
              : currProp.kh_rel === "House Owner"
              ? 1
              : null
          }
          onOptionClick={(val) => {
            switch (val) {
              case 1:
                this.setState({ kh_rel: "House Owner" });
                break;
              case 2:
                this.setState({ kh_rel: "Caretaker" });
                break;
              case 3:
                this.setState({
                  kh_rel: "Apartment Security",
                });
                break;
              default:
                this.setState({ kh_rel: "" });
            }
          }}
        />

        <div className="mt-4 mb-2">
          <small className="text-muted text-uppercase">
            Key Holder's Details
          </small>
        </div>
        <PhoneInputnew
          onNumberChange={(value) => {
            this.setState({ kh_phone: value });
          }}
          def_phone={this.state.kh_phone ? this.state.kh_phone : ""}
        />
        <Input
          className="mb-3 mt-4"
          placeholder="Keyholder's Name"
          type="text"
          name="kh_name"
          value={this.state.kh_name}
          onChange={this.onChange}
        />
      </>
    );
    const rent_content = (
      <>
        <div className="my-4">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            Rent in detail
          </small>
          <Input
            className="mb-3"
            placeholder="Rent Amount"
            type="number"
            name="rent_monthly_basic"
            value={this.state.rent_monthly_basic}
            onChange={this.onChange}
            required
          />
          <Input
            className="mb-3"
            placeholder="Monthly Maintenance Charges"
            type="number"
            name="rent_monthly_maintenance"
            value={this.state.rent_monthly_maintenance}
            onChange={this.onChange}
            required
          />
        </div>
        <OptionSelector
          label="Available for"
          option1="Bachelor"
          option2="Family"
          option3="Both"
          def_option={
            currProp === null
              ? 3
              : currProp.available_for === "bachelor"
              ? 1
              : currProp.available_for === "family"
              ? 2
              : currProp.available_for === "both"
              ? 3
              : 3
          }
          onOptionClick={(val) => {
            switch (val) {
              case 1:
                this.setState({ available_for: "bachelor" });
                break;
              case 2:
                this.setState({ available_for: "family" });
                break;
              case 3:
                this.setState({ available_for: "both" });
                break;
              default:
                this.setState({ available_for: null });
            }
          }}
        />
      </>
    );
    const sell_content = (
      <div className="my-4">
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Price and Land area
        </small>
        <Input
          className="mb-3"
          placeholder="Price Amount"
          type="number"
          name="price"
          value={this.state.price}
          onChange={this.onChange}
          required
        />
        <Input
          className="mb-3"
          placeholder="Land Area in Cents"
          type="number"
          name="land_area"
          value={this.state.land_area}
          onChange={this.onChange}
        />
      </div>
    );
    const img_del_btn = (
      <div className="overflow-hidden d-block my-4">
        <Button
          outline
          type="button"
          className="mt-0 btn-round"
          color="warning"
          onClick={() => this.delFn()}
          style={{
            margin: "0px auto",
            maxWidth: "150px",
            padding: "8px 15px",
            borderRadius: "24px",
          }}
        >
          Delete
        </Button>
      </div>
    );
    const thumbnail_btn = (
      <div className="overflow-hidden d-block my-4">
        <Button
          outline
          type="button"
          className="mt-0 btn-round"
          color="warning"
          onClick={() => this.makeThumbnail()}
          style={{
            margin: "0px auto",
            maxWidth: "150px",
            padding: "8px 15px",
            borderRadius: "24px",
          }}
        >
          Make Thumbnail
        </Button>
      </div>
    );
    const gal_content = (
      <>
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Images Uploaded
        </small>
        <p>To delete, select the images first and press on the delete button</p>
      </>
    );
    const config_edit = (
      <>
        <div className="my-4">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            Configuration
          </small>
          <div className="d-flex flex-row mb-2 flex-wrap flex-md-nowrap justify-content-between">
            <div className="d-flex mr-md-2 mb-2 flex-grow-1">
              <div
                className="d-flex align-self-center flex-grow-1"
                style={{ flexBasis: "120px" }}
              >
                <p className="mb-0">
                  <i class="fa fa-bed mr-2 text-primary"></i>
                  Bedrooms&nbsp;
                </p>
              </div>
              <div
                className="d-flex flex-grow-1"
                style={{ flexBasis: "100px" }}
              >
                <Input
                  placeholder="How many?"
                  type="number"
                  name="bedroom"
                  value={this.state.bedroom}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>

            <div className="d-flex ml-md-2 flex-grow-1">
              <div
                className="d-flex align-self-center flex-grow-1"
                style={{ flexBasis: "120px" }}
              >
                <p className="mb-0">
                  <i class="fa fa-shower mr-2 text-primary"></i>
                  Bathrooms
                </p>
              </div>
              <div
                className="d-flex flex-grow-1"
                style={{ flexBasis: "100px" }}
              >
                <Input
                  placeholder="How many?"
                  type="number"
                  name="bathroom"
                  value={this.state.bathroom}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mb-2">
            <div
              className="d-flex align-self-center flex-grow-1 flex-md-grow-0 mr-md-4"
              style={{ flexBasis: "120px" }}
            >
              <p className="mb-0">
                <i class="fa fa-car mr-2 text-primary"></i>
                Parking
              </p>
            </div>
            <div className="d-flex flex-grow-1" style={{ flexBasis: "100px" }}>
              <Input
                placeholder="How many?"
                type="number"
                name="parking"
                value={this.state.parking}
                onChange={this.onChange}
                required
              />
            </div>
          </div>
        </div>
      </>
    );
    const furnishing_option = (
      <>
        <OptionSelector
          label="Furnishing Details"
          option1="Fully Furnished"
          option2="Semi Furnished"
          option3="Not Furnished"
          def_option={
            currProp === null
              ? 2
              : currProp.furnishing === "Fully Furnished"
              ? 1
              : currProp.furnishing === "Semi Furnished"
              ? 2
              : currProp.furnishing === "Not Furnished"
              ? 3
              : 2
          }
          onOptionClick={(val) => {
            switch (val) {
              case 1:
                this.setState({ furnishing: "Fully Furnished" });

                break;
              case 2:
                this.setState({ furnishing: "Semi Furnished" });
                break;
              case 3:
                this.setState({ furnishing: "Not Furnished" });
                break;
              default:
                this.setState({ furnishing: null });
            }
          }}
        />
      </>
    );
    const floor_comps = (
      <>
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Floor Number
        </small>
        <Input
          className="mb-3 mt-2"
          placeholder="Floor Number"
          type="number"
          name="floor_number"
          value={this.state.floor_number}
          onChange={this.onChange}
        />
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Total Floor Number
        </small>
        <Input
          className="mb-3 mt-2"
          placeholder="Total Floor Number"
          type="number"
          name="total_floor_number"
          value={this.state.total_floor_number}
          onChange={this.onChange}
        />
      </>
    );
    return (
      <>
        <Navbar />
        <Container className="py-lg">
          <Row>
            <h3 className="text-black mt-lg-2 mb-3 mb-lg-4 mx-auto text-center">
              Property Info
            </h3>
          </Row>
          <Row>
            <Col sm="12" lg="6" md="4">
              <div
                // onContextMenu={e => e.preventDefault()}
                className="overflow-hidden d-block"
              >
                {this.state.image_objects.length > 0 ? gal_content : ""}
                {this.state.img_selected && this.state.gallery.length > 0
                  ? img_del_btn
                  : ""}

                <Gallery
                  images={this.state.image_objects}
                  onSelectImage={this.onSelectImage}
                  showLightboxThumbnails={true}
                  enableLightbox={false}
                />
              </div>
              {this.state.img_selected && this.state.gallery.length > 0
                ? img_del_btn
                : ""}
              {this.state.one_sel_image ? thumbnail_btn : ""}

              <div className="my-4">
                <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                  Upload Pictures (maximum 10 pictures)
                </small>
                <Input
                  type="file"
                  accept=".webp,.jpg,.jpeg,.png"
                  multiple
                  onChange={this.handleImageUpload}
                />
              </div>
            </Col>
            <Col sm="12" lg="6" md="8">
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <Input
                    className="mb-3 mt-5"
                    placeholder="Property Name"
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    required
                  />
                  <Input
                    className="mb-3"
                    placeholder="Area in sqft"
                    type="number"
                    name="area"
                    value={this.state.area}
                    onChange={this.onChange}
                  />
                  {this.state.property_type === PropertyTypes.land ||
                  commercial_prop_types.includes(this.state.property_type) ? (
                    <></>
                  ) : (
                    config_edit
                  )}
                  {/* rent or sale details */}
                  <OptionSelector
                    label="PURPOSE OF POSTING YOUR PROPERTY"
                    option1="To Rent"
                    option2="To Sell"
                    def_option={
                      currProp === null
                        ? 1
                        : currProp.purpose === "rent"
                        ? 1
                        : currProp.purpose === "sell"
                        ? 2
                        : currProp.purpose === "later"
                        ? 3
                        : 1
                    }
                    onOptionClick={(val) => {
                      switch (val) {
                        case 1:
                          this.setState({ purpose: "rent" });
                          break;
                        case 2:
                          this.setState({ purpose: "sell" });
                          break;
                        case 3:
                          this.setState({ purpose: "later" });
                          break;
                        default:
                          this.setState({ purpose: null });
                      }
                    }}
                  />
                  {this.state.purpose === "rent" ? rent_content : sell_content}

                  <div className="my-4">
                    <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                      Locality Details
                    </small>
                    <Input
                      className="my-3"
                      placeholder="Locality"
                      type="text"
                      name="locality"
                      value={this.state.locality}
                      onChange={this.onChange}
                      required
                    />
                    <Input
                      className="mb-3"
                      placeholder="City"
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.onChange}
                    />

                    <Input
                      className="my-3"
                      placeholder="Address"
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.onChange}
                      required
                    />
                    <Input
                      className="my-3"
                      placeholder="Remarks"
                      type="text"
                      name="remarks"
                      value={this.state.remarks}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="my-4">
                    <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                      Available from
                    </small>
                    <DateInput
                      onDateSelect={(val) => {
                        console.log("date from info", val);
                        this.setState({ available_from: val });
                      }}
                      def_date={this.state.available_from}
                    />
                  </div>
                  {this.state.property_type === PropertyTypes.land ||
                  commercial_prop_types.includes(this.state.property_type) ? (
                    <></>
                  ) : (
                    furnishing_option
                  )}

                  <h3 className="text-black mt-3 mt-lg-4 mb-3 mb-lg-4 text-center">
                    Key Info
                  </h3>
                  <OptionSelector
                    label="Ready to hand over the keys to iGen?"
                    option1="YES"
                    option2="NO"
                    default="2"
                    def_option={
                      currProp === null ? 1 : currProp.kh_rel === "igen" ? 1 : 2
                    }
                    onOptionClick={(val) => {
                      switch (val) {
                        case 1:
                          this.setState({
                            kh_rel: "igen",
                          });
                          break;
                        case 2:
                          this.setState({
                            kh_rel: "not igen",
                          });
                          break;
                        case 3:
                          break;
                        default:
                          this.setState({ kh_rel: null });
                      }
                    }}
                  />

                  {currProp
                    ? this.state.kh_rel === "igen"
                      ? ""
                      : kh_content
                    : ""}
                  <Input
                    className="mb-3 mt-5"
                    placeholder="Property ID"
                    type="text"
                    name="property_id"
                    value={this.state.property_id}
                    onChange={this.onChange}
                  />

                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Property Type
                  </small>
                  <UncontrolledDropdown className="mb-3">
                    <DropdownToggle caret>
                      {this.state.property_type}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Residential</DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.land);
                        }}
                      >
                        {PropertyTypes.land}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.apartment);
                        }}
                      >
                        {PropertyTypes.apartment}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.villa);
                        }}
                      >
                        {PropertyTypes.villa}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.house);
                        }}
                      >
                        {PropertyTypes.house}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem header>Commericial</DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.shop);
                        }}
                      >
                        {PropertyTypes.shop}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.onDropDown(PropertyTypes.office_space);
                        }}
                      >
                        {PropertyTypes.office_space}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Land Mark
                  </small>
                  <Input
                    className="mb-3 mt-2"
                    placeholder="Land Mark"
                    type="text"
                    name="landmark"
                    value={this.state.landmark}
                    onChange={this.onChange}
                  />
                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Water Source
                  </small>
                  <Input
                    className="mb-3 mt-2"
                    placeholder="Water Source"
                    type="text"
                    name="water_source"
                    value={this.state.water_source}
                    onChange={this.onChange}
                  />

                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Parking Number
                  </small>
                  <Input
                    className="mb-3 mt-2"
                    placeholder="Parking Number"
                    type="text"
                    name="parking_number"
                    value={this.state.parking_number}
                    onChange={this.onChange}
                  />
                  {[
                    PropertyTypes.land,
                    PropertyTypes.villa,
                    PropertyTypes.house,
                  ].includes(this.state.property_type) ? (
                    <></>
                  ) : (
                    floor_comps
                  )}

                  <div>{saveButton}</div>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  img: state.img,
  prop: state.prop,
  auth: state.auth,
  errors: state.errors,
});
EditProperty.propTypes = {
  updateProp: PropTypes.func,
  uploadImages: PropTypes.func,
};
export default connect(mapStateToProps, {
  updateProp,
  uploadImages,
})(EditProperty);
