import React, { Component } from "react";
import { Button } from "reactstrap";
import "./NotificationRequestPopup.scss";

class NotificationRequestPopup extends Component {
  render() {
    return (
      <>
        <div className="modal-body">
          <div className="pb-3 text-center">
            <span className="fa fa-4x text-primary bell fa-bell" />
            <h4 className="heading mt-4">Receive Notifications ?</h4>
            Would you like to receive real-time notifications for updates
            related to your property ?
            <Button
              className="btn-primary btn-round mt-4 btn-block"
              color="primary"
              type="button"
              onClick={this.props.clickAction}
            >
              <small>NOTIFY ME</small>
            </Button>
            <Button
              className="btn-secondary btn-round mt-4 btn-block"
              color="secondary"
              type="button"
              onClick={this.props.clickAction2}
            >
              <small>Don't Notify Me</small>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default NotificationRequestPopup;
