import axios from "../client";
import {
  GET_ERRORS,
  SET_ADMIN_CONFIG,
  GET_USERS,
  USER_LOADING,
  SET_OWNER_PROPS,
} from "./types";
export const updateAdminConfig = (adminConfigData) => (dispatch) => {
  axios
    .post("/api/admin/updateConfig", adminConfigData)
    .then((res) => {
      dispatch({
        type: SET_ADMIN_CONFIG,
        payload: res.data.newConfig,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getAllConfig = () => (dispatch) => {
  axios
    .get("/api/admin/getConfig")
    .then((res) => {
      console.log("getConfig res :>> ", res);
      dispatch({
        type: SET_ADMIN_CONFIG,
        payload: res.data[0],
      });
    })
    .catch((err) =>
      dispatch({
        type: SET_ADMIN_CONFIG,
        payload: {},
      })
    );
};
export const getAllUsers = () => (dispatch) => {
  dispatch(setUserLoading());
  axios
    .get("/api/users/all")
    .then((res) =>
      dispatch({
        type: GET_USERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_USERS,
        payload: {},
      })
    );
};
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
export const saveUserRemarks = (remarkData) => (dispatch) => {
  axios
    .post("/api/admin/addPmRemarks", remarkData)
    .then((res) => {
      dispatch(getAllUsers());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getOwnerProps = (ownerDat) => (dispatch) => {
  axios
    .post("/api/admin/getOwnerProps", ownerDat)
    .then((res) => {
      dispatch({
        type: SET_OWNER_PROPS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
