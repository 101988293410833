import React, { Component } from "react";
import Navbar from "components/Navbars/DarkNavbar";
import { Container } from "reactstrap";
import PropertyInfo from "views/AddPropertySections/PropertyInfo";
import OwnerDetails from "views/AddPropertySections/OwnerDetails";
import KeyDetails from "views/AddPropertySections/KeyDetails";
import SignupModal from "components/Modals/SignupModal";
import AddPropSuccess from "views/AddPropertySections/AddPropSuccess";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AddProperty extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
    this.currentStep = this.currentStep.bind(this);
  }

  currentStep(val) {
    this.setState({
      step: val,
    });
  }
  render() {
    return (
      <>
        <Navbar />
        <Container className="container-lg py-lg">
          {this.state.step === 1 ? (
            <PropertyInfo
              onProceed={() => {
                this.currentStep(2);
              }}
            />
          ) : null}
          {this.state.step === 2 ? (
            <OwnerDetails
              onProceed={() => {
                const new_prop = this.props.prop.new_prop_info;
                console.log(`new_prop.property_type`, new_prop.property_type);
                if (new_prop.property_type === "Land") {
                  this.currentStep(5);
                } else {
                  this.currentStep(3);
                }
              }}
              onBack={() => {
                this.currentStep(1);
              }}
            />
          ) : null}
          {this.state.step === 3 ? (
            <KeyDetails
              onProceed={() => {
                this.currentStep(5);
              }}
              onBack={() => {
                this.currentStep(2);
              }}
            />
          ) : null}
          {this.state.step === 4 ? <SignupModal /> : null}
          {this.state.step === 5 ? <AddPropSuccess /> : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});
AddProperty.propTypes = {
  auth: PropTypes.object,
  prop: PropTypes.object,
};
export default connect(mapStateToProps, {})(AddProperty);
