import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { subscribeUser } from "../../notifications/subscription";
import { sendTest } from "../../actions/notifActions/test";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import Logo from "./Logo/logo";

import { syncUnreadNotifs } from "../../actions/authActions";

class DarkNavbar extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    this.props.syncUnreadNotifs(this.props.auth.user);
  }

  constructor(props) {
    super(props);
    this.state = { showPopup: false, closeMenu: null };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
      closeMenu: true,
    });
  }

  render() {
    let showhide = this.state.closeMenu ? "hide" : "";
    const { isAuthenticated, user } = this.props.auth;
    const outer_bell = (
      <>
        <NavLink
          className="nav-link-icon text-white"
          id="tooltip356693867"
          target="_blank"
          onClick={() => {
            window.location = "/notifications";
          }}
        >
          <div
            className="notification-icon d-lg-none"
            style={{ display: "flex", maxWidth: "2px" }}
          >
            <i className="fa fa-bell mt-1 display-inline" />
            {this.props.auth.user.unread_notifs &&
            this.props.auth.user.unread_notifs !== "0" ? (
              <span
                className="d-inline-block floating-sm"
                style={{
                  marginTop: "-10px",
                  height: "20px",
                  width: "20px",
                  fontSize: "13px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  padding: "0px 6px",
                }}
              >
                {this.props.auth.user.unread_notifs}
              </span>
            ) : null}
          </div>
        </NavLink>
      </>
    );
    const authLinks = (
      <Nav className="navbar-nav-hover ml-lg-auto" navbar>
        <NavItem>
          <NavLink href="/">Home</NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            to={
              this.props.auth.user.role
                ? this.props.auth.user.role === null
                  ? "/empty-dashboard"
                  : this.props.auth.user.role === "tenant"
                  ? "/dashboard"
                  : "/owner-dashboard"
                : "/empty-dashboard"
            }
            tag={Link}
          >
            Dashboard
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className="nav-link-icon"
            //href=""
            id="tooltip356693867"
            target="_blank"
            onClick={() => {
              // subscribeUser(this.props.auth.user.id);
              window.location = "/notifications";
            }}
          >
            <div className=" d-xs-none d-lg-flex" style={{ minWidth: "5px" }}>
              <i className="fa fa-bell mt-1 display-inline" />
              {/* {!this.props.read ? ( */}
              {this.props.auth.user.unread_notifs &&
              this.props.auth.user.unread_notifs !== "0" ? (
                <span
                  className="d-inline-block floating-sm"
                  style={{
                    marginTop: "-10px",
                    height: "20px",
                    width: "20px",
                    fontSize: "13px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "0px 6px",
                  }}
                >
                  {this.props.auth.user.unread_notifs}
                </span>
              ) : null}
            </div>
            <span className="d-lg-none">Notifications</span>
          </NavLink>
          <UncontrolledTooltip delay={0} target="tooltip356693867">
            See your notifications
          </UncontrolledTooltip>
        </NavItem>

        {/* <NavItem>
          <NavLink className={this.props.tenant ? "active" : ""} href="/tenant">
            For Tenant
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={this.props.owner ? "active" : ""} href="/owner">
            For Owner
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink href="/about-us">About StayLocal</NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className="nav-link-icon"
            //href=""
            target="_blank"
            onClick={() => {
              subscribeUser(this.props.auth.user.id);
              //sendTest();
            }}
          >
            <i className="fa fa-key desktoponly mt-1" />
            <span className="d-lg-none">NotifTest</span>
          </NavLink>
        </NavItem> */}
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <i className="fa fa-user mr-2 text-primary" />
            <span className="nav-link-inner--text">
              {this.props.auth.user.name}
            </span>
            <img
              className="fa ml-2"
              src={require("./arrow-down.svg")}
              alt="arrow-down"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="px-2 pl-4" to="/profile" tag={Link}>
              Profile
            </DropdownItem>
            <DropdownItem to="" tag={Link}>
              <Button
                className="btn-round btn-mb-black"
                color="white"
                type="link"
                onClick={this.onLogoutClick.bind(this)}
              >
                Logout
              </Button>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/add-property"
            //onClick={e => e.preventDefault()}
          >
            <Button
              className="btn-icon btn-neutral btn-round"
              color="primary"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="fa fa-home mr-0" />
              </span>
              <span className="btn-inner--text">Post Your Property</span>
            </Button>
          </NavLink>
        </NavItem>
      </Nav>
    );
    const guestLinks = (
      <Nav className="navbar-nav-hover ml-lg-auto" navbar>
        <NavItem>
          <NavLink href="/">Home</NavLink>
        </NavItem>

        {/* <NavItem>
          <NavLink className={this.props.tenant ? "active" : ""} href="/tenant">
            For Tenant
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={this.props.owner ? "active" : ""} href="/owner">
            For Owner
          </NavLink>
        </NavItem> */}
        <NavItem>
          {/* <NavLink href="/about-us">About StayLocal</NavLink> */}
          <NavLink href="/search/1">Search</NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink href="#pablo">
            Contact
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="" target="_blank">
            About
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/add-property"
            // onClick={e => e.preventDefault()}
          >
            {/* <Button
              className="btn-icon btn-neutral btn-round"
              color="primary"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="fa fa-home mr-0" />
              </span>
              <span className="btn-inner--text">Post Your Property</span>
            </Button> */}
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/login-page"
            // onClick={this.togglePopup.bind(this)}
          >
            <Button
              className="btn-round btn-mb-black"
              color="white"
              outline
              type="button"
            >
              Login/SignUp
            </Button>
          </NavLink>
        </NavItem> */}
      </Nav>
    );
    return (
      <>
        <style></style>
        <header className="header-global">
          <Navbar
            className={
              this.props.transparent
                ? "navbar-transparent navbar-light"
                : "navbar-dark bg-primary"
            }
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand href="/">
                <Logo />
              </NavbarBrand>
              {this.props.auth.isAuthenticated ? outer_bell : ""}
              <button className="navbar-toggler" id="navbar-primary">
                <span className="navbar-toggler-icon" />
              </button>

              <UncontrolledCollapse
                navbar
                toggler="#navbar-primary"
                className={showhide}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("./Logo/logo_primary.svg")}
                          style={{ height: "auto", width: "229px" }}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar-primary">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                {isAuthenticated ? authLinks : guestLinks}
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          {/* Navbar success */}
        </header>
        {/* {this.state.showPopup ? (
          <div>
            <SignupModal />
          </div>
        ) : null} */}
      </>
    );
  }
}

DarkNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  syncUnreadNotifs: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, syncUnreadNotifs })(
  DarkNavbar
);
