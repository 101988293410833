import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import propReducer from "./propReducer";
import visitReducer from "./visitReducer";
import imageReducer from "./imageReducer";
import notifReducer from "./notifReducer";
import adminReducer from "./adminReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  visit: visitReducer,
  prop: propReducer,
  img: imageReducer,
  notif: notifReducer,
  admin: adminReducer,
});
