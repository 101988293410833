import React, { Component } from "react";
import { Badge, Card, CardBody } from "reactstrap";
import classnames from "classnames";

export default class NotificationCard extends Component {
  render() {
    return (
      <div>
        <Card
          className={classnames("card-lift--hover shadow border-0", {
            "bg-secondary": this.props.read,
          })}
          style={{ borderRadius: "0.55rem", cursor: "pointer" }}
        >
          {/* {!this.props.read ? (
            <span
              className="d-inline-block"
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "red",
                borderRadius: "50%",
                position: "absolute",
                top: "8px",
                left: "10px"
              }}
            ></span>
          ) : null} */}
          <span
            className="d-inline-block font-weight-800 text-muted"
            style={{
              position: "absolute",
              top: "2px",
              right: "12px",
              fontSize: "1.2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.onCloseClick();
            }}
          >
            x
          </span>
          <CardBody className="pb-3" onClick={this.props.onClick}>
            <div className="d-flex">
              <Badge
                color="primary"
                circle
                className="mr-1"
                style={{ paddingTop: "7px" }}
              >
                {this.props.title}
              </Badge>
              <div className="d-inline">
                <i class="fa fa-clock-o ml-3 text-muted"></i>
                <small className="ml-1 text-muted font-weight-600">
                  {/* {this.props.timeNumerical} {this.props.timeUnit} ago */}
                  {this.props.timeSince} ago
                </small>
              </div>
            </div>
            <div className="mt-2 mt-lg-3" style={{ fontSize: "0.9rem" }}>
              {/* <b>Rajeev Shukla</b> has requested the inspection of their
              property <b>Wuthering Heights</b> in <b>Kakkanad, Kochi</b>. */}
              {this.props.description}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
