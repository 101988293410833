import React, { Component } from "react";
import Navbar from "components/Navbars/DarkNavbar";

import "./LandingLandlord.scss";
import Footer from "components/Footers/Footer/Footer";
import { Container, Row, Col, Button, Card, CardBody, Badge } from "reactstrap";
import ContactCard from "components/Cards/ContactCard/ContactCard";
import AddPropertyCTA from "components/Cards/AddPropertyCTA";

export default class LandingLandlord extends Component {
  render() {
    return (
      <>
        <Navbar transparent owner />
        <main ref="main" className="landlord">
          <div className="position-relative">
            <section className="section section-md section-shaped hero">
              <div className="shape bg-primary"></div>
              <Container className="py-lg-md d-flex hero-text">
                <div className="col px-0">
                  <Row>
                    <Col lg="12" className="text-lg-center">
                      <h1 className="display-3 text-white">
                        Increase the Value of Your Investment
                      </h1>
                      <p className="lead text-white">
                        Before handing the keys to your house over to a complete
                        stranger for care, think of how a competent property
                        manager can make a big impact on the success of your
                        rental.
                      </p>
                      <div className="btn-wrapper mx-lg-auto">
                        <Button
                          className="btn-white mb-sm-0 ml-1 mx-lg-auto"
                          color="default"
                          href="/add-property"
                        >
                          <span className="btn-inner--text">
                            POST YOUR PROPERTY
                          </span>
                        </Button>
                        <div className="ml-1 mt-2">
                          <small>
                            <a
                              className="text-white text-underline"
                              href="/properties"
                            >
                              Or Want to Buy/Rent a Property?
                            </a>
                          </small>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              <div className="separator separator-bottom separator-skew hero-separator">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
          </div>

          <a
            className="rounded-circle mb-4 bg-primary icon icon-shape shadow"
            style={{
              position: "fixed",
              bottom: "2%",
              right: "10%",
              height: "60px",
              width: "60px",
              zIndex: "6",
            }}
            href="/add-property"
          >
            <img
              className="fa white pb-1 pl-1"
              src={require("./../../../assets/img/icons/common/add-property-icon.svg")}
              alt="add-property-icon"
            ></img>
          </a>
          <section className="section section-md who-we-are">
            <Container>
              <div>
                <h3 className="mb-3">Who We Are</h3>
                <p className="quote font-weight-100">
                  iGen is a property management company that connects genuine
                  homeowners to tenants and tenants to homes than just concrete
                  buildings. Our portfolio includes properties that can suit
                  different lifestyles and we strive to excel in our services
                  through our professional and technologically-driven approach.
                </p>
              </div>
            </Container>
          </section>
          <section className="section section-md">
            <div className="bg-primary pt-md pb-lg">
              <Container>
                <h3 className="text-white mb-md-4">Why iGen ?</h3>
              </Container>
            </div>
            <Container fluid>
              <Row className="mt--100">
                <Col sm="12" md="6">
                  <Card className="shadow shadow-lg--hover mt-3">
                    <CardBody className="py-5">
                      <div className="d-flex px-3 flex-md-row flex-column">
                        <div className="align-self-center align-self-md-start pb-3 pb-md-0">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="fa fa-home" />
                          </div>
                        </div>
                        <div className="pl-md-4 text-center text-md-left">
                          <h5 className="title text-uppercase">
                            Get Your Home Evaluated
                          </h5>
                          <p>
                            Browse through a range of housing options that are
                            available in the location of your choice.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="6">
                  <Card className="shadow shadow-lg--hover mt-3">
                    <CardBody className="py-5">
                      <div className="d-flex px-3 flex-md-row flex-column">
                        <div className="align-self-center align-self-md-start pb-3 pb-md-0">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="fa fa-building" />
                          </div>
                        </div>
                        <div className="pl-md-4 text-center text-md-left">
                          <h5 className="title text-uppercase">
                            End to End Property Management
                          </h5>
                          <p>
                            Browse through a range of housing options that are
                            available in the location of your choice.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6">
                  <Card className="shadow shadow-lg--hover mt-3">
                    <CardBody className="py-5">
                      <div className="d-flex px-3 flex-md-row flex-column">
                        <div className="align-self-center align-self-md-start pb-3 pb-md-0">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="fa fa-shield" />
                          </div>
                        </div>
                        <div className="pl-md-4 text-center text-md-left">
                          <h5 className="title text-uppercase">
                            Experience Excellence
                          </h5>
                          <p>
                            Browse through a range of housing options that are
                            available in the location of your choice.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="6">
                  <Card className="shadow shadow-lg--hover mt-3">
                    <CardBody className="py-5">
                      <div className="d-flex px-3 flex-md-row flex-column">
                        <div className="align-self-center align-self-md-start pb-3 pb-md-0">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="fa fa-star" />
                          </div>
                        </div>
                        <div className="pl-md-4 text-center text-md-left">
                          <h5 className="title text-uppercase">
                            Your Investment is Our #1 Priority
                          </h5>
                          <p>
                            Browse through a range of housing options that are
                            available in the location of your choice.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-md">
            <Container>
              <h2 className="mb-4">iGen’s Step-by Step Rental Guide</h2>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("./../../../assets/img/illustrations/building.svg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-like-2" />
                    </div>
                    <h3>Rental Application Assessment</h3>
                    <p className="reading-comfort">
                      We consider tenant screening and analysis to be our
                      topmost priority. This is why we have established strong
                      background check procedures and personalized referenced
                      checks by our property managers.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-credit-card" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">On-time Rent</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-watch-time" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Periodic Inspection</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Quality Maintenance</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <div className="btn-wrapper text-center mx-auto">
                <Button
                  color="primary"
                  size="lg"
                  className="mx-auto my-2 my-lg-4"
                  href="/add-property"
                  block
                >
                  Get Started
                </Button>
              </div>
            </Container>
          </section>
          <section className="section pt-lg-0 section-contact-us">
            <div className="container-fluid">
              <ContactCard />
            </div>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <AddPropertyCTA />
            </Container>
          </section>
          <Footer />
        </main>
      </>
    );
  }
}
