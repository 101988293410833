import axios from "../../client";

export const sendTest = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            console.log("Existed subscription detected.");
            const userData = JSON.stringify(existedSubscription);
            return axios
              .post("/api/visitNotifs/sendNotifTest", userData, {
                headers: { "Content-Type": "application/json" },
              })
              .then((res) => {})
              .catch((err) => {});
          });
      })
      .catch(function (e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
};

export const notifVisitBookApprovedOwner = (visitInfo) => {
  axios
    .post("/api/visitNotifs/notifVisitBookApprovedOwner", visitInfo)
    .then((res) => {})
    .catch((err) => {});
};

export const notifVisitBookApprovedTenant = (visitInfo) => {
  console.log("notifVisitBookApprovedTenant :", visitInfo);
  axios
    .post("/api/visitNotifs/notifVisitBookApprovedTenant", visitInfo)
    .then((res) => {
      console.log("notifVisitBookApprovedTenant", res.data);
    })
    .catch((err) => {});
};
export const notifPaymentRequest = (visitInfo) => {
  console.log("notifPaymentRequest :", visitInfo);
  axios
    .post("/api/visitNotifs/notifPaymentRequest", visitInfo)
    .then(async (res) => {})
    .catch((err) => {});
};
export const notifDepositReceivedTenant = (visitInfo) => {
  console.log("notifDepositReceivedTenant :", visitInfo);
  axios
    .post("/api/visitNotifs/notifDepositReceivedTenant", visitInfo)
    .then(async (res) => {
      console.log("notifDepositReceivedTenant", res.data);
    })
    .catch((err) => {});
};
export const notifDepositReceivedOwner = (visitInfo) => {
  console.log("notifDepositReceivedOwner :", visitInfo);
  axios
    .post("/api/visitNotifs/notifDepositReceivedOwner", visitInfo)
    .then(async (res) => {
      console.log("notifDepositReceivedOwner", res.data);
    })
    .catch((err) => {});
};
export const notifVisitBookRescheduledTenant = (visitInfo) => {
  console.log("notifVisitBookRescheduledTenant :", visitInfo);
  axios
    .post("/api/visitNotifs/notifVisitBookRescheduledTenant", visitInfo)
    .then(async (res) => {
      console.log("notifVisitBookRescheduledTenant", res.data);
    })
    .catch((err) => {});
};
export const notifVisitBookRescheduledOwner = (visitInfo) => {
  console.log("notifVisitBookRescheduledOwner :", visitInfo);
  axios
    .post("/api/visitNotifs/notifVisitBookRescheduledOwner", visitInfo)
    .then(async (res) => {
      console.log("notifVisitBookRescheduledOwner", res.data);
    })
    .catch((err) => {});
};
export const notifChangeConsiderationTenant = (visitInfo) => {
  console.log("notifChangeConsiderationTenant :", visitInfo);
  axios
    .post("/api/visitNotifs/notifChangeConsiderationTenant", visitInfo)
    .then(async (res) => {
      console.log("notifChangeConsiderationTenant", res.data);
    })
    .catch((err) => {});
};
export const notifVisitCompletedOwner = (visitInfo) => {
  console.log("notifVisitCompletedOwner :", visitInfo);
  axios
    .post("/api/visitNotifs/notifVisitCompletedOwner", visitInfo)
    .then(async (res) => {
      console.log("notifVisitCompletedOwner", res.data);
    })
    .catch((err) => {});
};
export const notifVisitCompletedTenant = (visitInfo) => {
  console.log("notifVisitCompletedTenant :", visitInfo);
  axios
    .post("/api/visitNotifs/notifVisitCompletedTenant", visitInfo)
    .then(async (res) => {
      console.log("notifVisitCompletedTenant", res.data);
    })
    .catch((err) => {});
};
export const notifChangeConsiderationOwner = (visitInfo) => {
  console.log("notifChangeConsiderationOwner :", visitInfo);
  axios
    .post("/api/visitNotifs/notifChangeConsiderationOwner", visitInfo)
    .then(async (res) => {
      console.log("notifChangeConsiderationOwner", res.data);
    })
    .catch((err) => {});
};
export const notifChangeConsiderationAdmin = (visitInfo) => {
  console.log("notifChangeConsiderationAdmin :", visitInfo);
  axios
    .post("/api/visitNotifs/notifChangeConsiderationAdmin", visitInfo)
    .then(async (res) => {
      console.log("notifChangeConsiderationAdmin", res.data);
    })
    .catch((err) => {});
};
export const notifVisitBookAdmin = (visitInfo) => {
  axios
    .post("/api/visitNotifs/notifVisitBookAdmin", visitInfo)
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {});
};
