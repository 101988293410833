import React, { Component } from "react";
import { Button } from "reactstrap";
import "./TenantDashPropCardNew.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  mailChangeConsiderationOwner,
  mailChangeConsiderationAdmin,
  mailChangeConsiderationTenant,
} from "../../../actions/mailActions/visitMails";

import {
  notifChangeConsiderationOwner,
  notifChangeConsiderationAdmin,
  notifChangeConsiderationTenant,
} from "../../../actions/notifActions/visitNotifs";

import {
  changeConsideration,
  deleteVisit,
} from "../../../actions/visitActions";
import currencyFormat from "../../../utils/currencyFormat";
import Swal from "sweetalert2";

class PropertyCardDesktop extends Component {
  constructor() {
    super();
    this.state = {
      consideration: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log("consideration :", this.state.consideration);
      this.props.changeConsideration({
        id: this.props.visit_id,
        consideration: this.state.consideration,
        tenant_id: this.props.auth.user.id,
      });
      // window.location.reload();
      const mail_content = {
        owner_id: this.props.owner_id,
        prop_id: this.props.prop_id,
        tenant_id: this.props.tenant_id,
        owner_email: this.props.owner_email,
        owner_name: this.props.owner_name,
        owner_phone: this.props.owner_phone,
        ten_name: this.props.ten_name,
        prop_name: this.props.prop_name,
        time: this.props.timeSlot,
        date: this.props.bookDate,
        consideration: this.state.consideration,
        dateTime: new Date(Date.now()),
      };
      console.log("mail_content :", mail_content);
      mailChangeConsiderationOwner(mail_content);
      mailChangeConsiderationAdmin(mail_content);
      mailChangeConsiderationTenant(mail_content);

      notifChangeConsiderationOwner(mail_content);
      notifChangeConsiderationAdmin(mail_content);
      notifChangeConsiderationTenant(mail_content);
    });
  }
  componentDidMount() {
    this.setState({ consideration: this.props.consideration });
  }
  render() {
    const consideration_selector = (
      <>
        <div className="mx-auto mt-3">
          <select
            name="consideration"
            style={{
              color: "white",
              height: "45px",
              background: "#4a5bea",
              padding: "10px 10px",
            }}
            onChange={this.onChange}
          >
            <option
              selected={this.state.consideration === "no_response"}
              value="no_response"
            >
              Respond Now
            </option>
            <option
              selected={this.state.consideration === "confirmed"}
              value="confirmed"
            >
              Confirmed
            </option>
            <option
              selected={this.state.consideration === "considering"}
              value="considering"
            >
              Considering
            </option>
            <option
              selected={this.state.consideration === "not_considering"}
              value="not_considering"
            >
              Not Considering
            </option>
          </select>
        </div>
      </>
    );
    const tick_mark = (
      <>
        {" "}
        <span className="my-2">
          <i
            class="fa fa-check-circle mr-2 text-success d-inline"
            style={{ fontSize: "20px" }}
          ></i>
          <span className="d-inline text-muted mb-3">Visit Completed</span>
        </span>
      </>
    );
    const rent = (
      <>
        {" "}
        ₹ {this.props.rent ? currencyFormat(this.props.rent) : ""}{" "}
        <small>/ month</small>
      </>
    );
    const price = (
      <>
        {" "}
        ₹ {this.props.price ? currencyFormat(this.props.price) : ""}{" "}
        <small></small>
      </>
    );
    return (
      <>
        <div
          onClick={() => this.props.onTenantDashCardClick()}
          className="text-center mt-3 shadow rounded d-flex flex-lg-row flex-column"
        >
          <div
            className="mr-0 d-flex flex-grow-0"
            style={{ overflow: "hidden" }}
          >
            <img
              alt="..."
              className="img-fluid rounded-top shadow img-center shadow-lg--hover"
              //src={require("assets/img/property/1.jpg")}
              src={this.props.hero_image}
              style={{
                width: "100%",
                height: "200px",
                maxWidth: "400px",
              }}
            />
          </div>
          <div
            style={{
              width: "50%",
              maxWidth: "200px",
              height: "36px",
              padding: "5px 5px",
              color: "#ffffff",
              fontFamily: "HomepageBaukasten-Book",
              fontWeight: "800",
            }}
            className="bg-primary price-tag"
          >
            {this.props.rent
              ? this.props.rent.length > 0
                ? rent
                : price
              : price}
          </div>
          <div className="pt-3 px-3 pb-1 text-left d-flex flex-lg-row flex-column align-content-center flex-grow-1">
            <div
              className="overflow-hidden d-flex flex-column pl-lg-3 flex-grow-1"
              style={{ justifyContent: "space-evenly", flexBasis: "51%" }}
            >
              <div className="overflow-hidden">
                <h5 className="title mb-1">
                  <span className="d-inline pr-3">{this.props.prop_name}</span>
                </h5>
                <div className="text-muted pb-1">
                  <span className="fa fa-map-marker mr-2"></span>
                  {this.props.place}, {this.props.city}
                </div>
              </div>
              {this.props.auth.user.role &&
              this.props.visit_status === "completed"
                ? tick_mark
                : ""}

              <div className="d-flex flex-row flex-wrap">
                <span className="d-flex float-left">
                  <ul className="list-inline-item pl-0">
                    <span>
                      <small className="text-muted pr-2">
                        {this.props.area} sq.ft
                      </small>
                    </span>
                    <small>
                      <li className="list-inline-item pr-2 font-weight-bold">
                        {this.props.bedroom} Beds
                      </li>
                    </small>
                    <small>
                      <li className="list-inline-item pr-2 font-weight-bold">
                        {this.props.bathroom} Bath
                      </li>
                    </small>
                  </ul>
                </span>
                <span>
                  <small className="text-muted pr-2">
                    Status :
                    <small className="text-primary">
                      {" " + this.props.visit_status}
                    </small>
                  </small>
                </span>

                {/* <span className="d-flex float-right text-primary ml-1 mt-1">
                  <i className="fa fa-users primary ml-2 mr-2"></i>
                  <small>{this.props.requested} watching</small>
                </span> */}
              </div>
            </div>
            <div
              className="d-flex flex-column pt-lg-0 pt-1 flex-grow-1"
              style={{ justifyContent: "space-evenly" }}
            >
              <div className="mt-0 text-left d-flex mb-2 flex-wrap pt-lg-0">
                <div className="mt-0 mb-1 text-center">
                  <small>Visit booked on : </small>{" "}
                </div>
                <div className="d-flex flex-column">
                  <div className="text-left">
                    <i className="fa fa-calendar primary ml-2 mr-2 text-primary"></i>
                    <small className="text-primary">
                      {this.props.bookDate}
                    </small>
                  </div>
                  <div className="mt-0 mb-2 ml-2 text-right mt-1">
                    <button className="btn btn-secondary btn-round py-1 px-3">
                      <small style={{ fontSize: "0.6em" }}>
                        {this.props.timeSlot}
                      </small>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-2 text-center d-flex flex-wrap pb-lg-2">
                <div className="mt-0 text-center">
                  <small>Property Manager :</small>{" "}
                </div>
                <div className="text-center">
                  <i className="fa fa-phone primary ml-2 mr-2 text-primary"></i>
                  <small className="text-primary">{this.props.pmMobile}</small>
                </div>
                {this.props.auth.user.role &&
                this.props.visit_status === "completed"
                  ? consideration_selector
                  : ""}
              </div>
              <row>
                <Button
                  className="btn-icon btn-neutral btn-round  my-2 mr-1"
                  color="primary"
                  type="button"
                  style={{ float: "right" }}
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "This visit request will be deleted permanantly!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        // console.log("this.props", this.props.prop.property._id);
                        var dat1 = {
                          _id: this.props.visit_id,
                        };
                        deleteVisit(dat1, "/dashboard");
                      }
                    });
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-trash mr-0" />
                  </span>
                  {/* <span className="btn-inner--text">Delete Property</span> */}
                </Button>
              </row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PropertyCardDesktop.propTypes = {
  changeConsideration: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { changeConsideration })(
  PropertyCardDesktop
);
