import axios from "../client";
import { loadProgressBar } from "axios-progress-bar";
import { SAVE_UPLOADED_IMGS } from "./types";

export const deleteImages = (del_imgs) => {
  axios
    .post("/api/images/delete", del_imgs)
    .then((res) => {
      console.log("delete images result", res.data);
    })
    .catch((err) => {
      console.log("del images error client", err);
    });
};

export const uploadImages = (up_imgs) => (dispatch) => {
  axios
    .post("/api/images/upload", up_imgs, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          loadProgressBar();
        }
      },
    })
    .then((res) => {
      dispatch(saveGalImgs(res.data.locationArray));
      console.log("upload images result", res.data);
    })
    .catch((err) => {
      console.log("del images error client", err);
    });
};
// set props here
export const saveGalImgs = (img_urls) => {
  return {
    type: SAVE_UPLOADED_IMGS,
    payload: img_urls,
  };
};
