import React, { Component } from "react";
import { Card, Row, Col, Button } from "reactstrap";

export default class AddPropertyCTA extends Component {
  render() {
    return (
      <>
        <Card className="bg-gradient-blue shadow-lg border-0">
          <div className="p-5">
            <Row className="align-items-center">
              <Col lg="8">
                <h3 className="text-white">Do You Own a Property ?</h3>
                <p className="lead text-white mt-3">
                  Increase the value of your investment by posting your property
                  with us.
                </p>
              </Col>
              <Col className="ml-lg-auto" lg="3">
                <Button
                  block
                  className="btn-white shadow-lg text-primary"
                  color="white"
                  href="/add-property"
                  size="lg"
                >
                  Post Your Property
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}
