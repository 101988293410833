import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import { Modal } from "reactstrap";
import SignupCard from "../Cards/SignupCard";

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = { openState: true };
  }
  state = {};
  toggleModal = state => {
    this.setState({
      openState: false
    });
  };
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.openState}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.toggleModal("formModal")}
            >
              <span
                style={{ float: "right", padding: "2px 5px 0px 0px" }}
                aria-hidden="true"
              >
                ×
              </span>
            </button>
            <SignupCard />
          </div>
        </Modal>
      </>
    );
  }
}

export default SignupModal;
