import React from "react";
import { Button } from "reactstrap";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    return (
      <Button
        className="btn-icon mt-2 mb-2 mr-2 btn-round"
        color={this.props.color}
        onClick={this.props.triggerLogin}
        {...this.props}
      >
        <span className="btn-inner--icon mr-1">
          <i className={this.props.icon} />
        </span>
        <span className="btn-inner--text">{this.props.children}</span>
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
