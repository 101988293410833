import React, { Component } from "react";
import DarkNavbar from "components/Navbars/DarkNavbar";
import { Container, Row, Col, Button } from "reactstrap";

export default class DashboardEmpty extends Component {
  render() {
    return (
      <>
        <DarkNavbar />
        <Container className="container-lg py-md">
          <Row className="mt-5">
            <Col sm="12" md="5">
              <img
                className="mx-auto d-block pb-4"
                alt="..."
                src={require("./../../assets/img/illustrations/book-visit.svg")}
                style={{ height: "auto", width: "229px" }}
              />
              <Button
                block
                color="primary"
                onClick={() => {
                  window.location = "/properties";
                }}
              >
                Visit a Property
              </Button>
            </Col>
            <Col sm="12" md="2" className="text-center align-self-center">
              <h2 className="my-4">Or</h2>
            </Col>
            <Col sm="12" md="5" className="align-self-end">
              <img
                className="mx-auto d-block pb-4"
                alt="..."
                src={require("./../../assets/img/illustrations/add-property.svg")}
                style={{ height: "auto", width: "229px" }}
              />
              <Button
                block
                color="primary"
                onClick={() => {
                  window.location = "/add-property";
                }}
              >
                Add your Property
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
