import React, { Component } from "react";
import "./PropertyInfoCard.scss";
import { Col, Row, Button, Modal } from "reactstrap";
import SignupCard from "components/Cards/SignupCard";
import Datetime from "components/Cards/DateTime";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfoWithCta from "components/Modals/InfoWithCta";
import {
  toggleBookPropVisit,
  registerVisit
} from "../../../actions/visitActions";
import "./couch.svg";

class OwnerPropertyInfoCard extends Component {
  state = {};
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
    this.props.toggleBookPropVisit(!this.state[state]);
  };
  render() {
    return (
      <>
        <Col lg="6">
          <div className="cardContainer">
            <Row className="mb-1 mt-3">
              <Col>
                <h4>{this.props.name}</h4>
                <h6>
                  {this.props.place} | {this.props.city}
                </h6>
              </Col>
              <Col className="text-center">
                <h5 className="text-primary font-weight-bold mt-3 price">
                  Rs. {this.props.rent_monthly_basic} <br />
                  <sub className="font-weight-light">/ month</sub>
                </h5>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <i
                  class="fa fa-check-circle mr-2 text-success d-inline"
                  style={{ fontSize: "20px" }}
                ></i>
                <span className="d-inline text-muted mb-3">
                  Inspection Completed
                </span>
              </Col>
            </Row>
            <Row className="mb-4 mt-3">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-home text-muted"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Configuration</h6>
                <small className="d-block">
                  {this.props.bedroom ? this.props.bedroom : "0"}
                  &nbsp;Bedrooms,&nbsp;
                  {this.props.bathroom ? this.props.bathroom : "0"}
                  &nbsp;Bathrooms,&nbsp;
                  {this.props.balcony ? this.props.balcony : "0"}
                  &nbsp;Balcony,&nbsp;
                  {this.props.kitchen ? this.props.kitchen : "0"}
                  &nbsp;Kitchen
                </small>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-rupee text-muted ml-1"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Rent in detail</h6>
                <small className="d-block">
                  {this.props.rent_detail_monthly_basic}/month (Negotiable)
                  <br />
                  Maintenance Rs. {this.props.rent_detail_monthly_maintenance}/-
                  (Monthly)
                </small>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <img
                    className="fa text-muted "
                    src={require("./calendar.svg")}
                    alt="calendar-icon"
                  ></img>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Available from</h6>
                <small className="d-block">{this.props.available_from}</small>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-users text-muted"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Available for</h6>
                <small className="d-block">{this.props.available_for}</small>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <img
                    className="fa text-muted "
                    src={require("./couch.svg")}
                    alt="furnishing-icon"
                  ></img>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Furnishing</h6>
                <small className="d-block">{this.props.furnishing}</small>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="text-center" xs="1">
                <h4 className="d-inline">
                  <i className="fa fa-address-book text-muted ml-1"></i>
                </h4>
              </Col>
              <Col className="pl-4" xs="11">
                <h6 className="text-primary d-inline">Address</h6>
                <small className="d-block">{this.props.address}</small>
              </Col>
            </Row>
            <div className="mt-5 mb-5 text-center">
              <Button
                color="warning"
                size="lg"
                type="button"
                className="px-5 py-3"
                onClick={() => this.toggleModal("defaultModal")}
              >
                Delete this Property
              </Button>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
              >
                <SignupCard
                  onVisitProp={() => {
                    this.toggleModal("defaultModal");
                    this.toggleModal("selectDate");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.selectDate}
                toggle={() => this.toggleModal("selectDate")}
              >
                <div className="modal-header border-0">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("selectDate")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <Datetime
                  onDateTime={() => {
                    this.toggleModal("selectDate");
                    const newVisit = {
                      prop_id: this.props.prop.property._id,
                      date: this.props.visit.visitDate.toString(),
                      time_interval: this.props.visit.visitTime.toString()
                    };
                    console.log("new Visit", newVisit);
                    this.props.registerVisit(newVisit, this.props.history);
                    this.toggleModal("success");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.success}
                toggle={() => this.toggleModal("success")}
              >
                <div className="modal-header border-0">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("success")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <InfoWithCta
                  icon="fa-check-square"
                  title="Visit Request submitted!"
                  cta="GO TO DASHBOARD"
                  clickAction={() => {
                    window.location.href = "/dashboard";
                  }}
                >
                  <p>
                    Our property manager will contact you shortly on the mobile
                    number you provided. <br />
                    Please check the dashboard for progress.
                  </p>
                </InfoWithCta>
              </Modal>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

OwnerPropertyInfoCard.propTypes = {
  toggleBookPropVisit: PropTypes.func.isRequired,
  registerVisit: PropTypes.func,

  prop: PropTypes.object,
  visit: PropTypes.object,
  errors: PropTypes.object
};
const mapStateToProps = state => ({
  prop: state.prop,
  visit: state.visit,
  errors: state.errors
});

export default connect(mapStateToProps, { toggleBookPropVisit, registerVisit })(
  OwnerPropertyInfoCard
);
