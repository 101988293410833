import React, { Component } from "react";
import { Button, Row, Col, Modal } from "reactstrap";
import DateTime from "./DateTime";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  rescheduleVisit,
  changeStatus,
  deleteVisit,
} from "../../actions/visitActions";
import Swal from "sweetalert2";
import {
  mailVisitBookApprovedOwner,
  mailVisitBookApprovedTenant,
  mailVisitBookRescheduledTenant,
} from "../../actions/mailActions/visitMails";
import {
  notifVisitBookApprovedOwner,
  notifVisitBookApprovedTenant,
  notifVisitBookRescheduledTenant,
} from "../../actions/notifActions/visitNotifs";
class VisitRequestCard extends Component {
  state = {};
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  render() {
    const kh_det = (
      <>
        <div className="mb-2">
          <div className="d-block mt-1 text-muted">
            <small className="text-uppercase">Keyholder Name</small>
          </div>
          <div className="d-block">{this.props.kh_name}</div>
        </div>
        <div className="mb-2">
          <div className="d-block mt-1 text-muted">
            <small className="text-uppercase">Keyholder Phone</small>
          </div>
          <div className="d-block">{this.props.kh_phone}</div>
        </div>
      </>
    );
    return (
      <>
        <div
          className="shadow-sm shadow-lg--hover rounded my-2"
          style={{ padding: "20px 40px" }}
        >
          <div className="my-2">
            <row>
              <Button
                className="btn-icon btn-neutral btn-round  my-2 mr-1"
                color="primary"
                type="button"
                style={{ float: "right" }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "This visit request will be Deleted permanantly!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      // console.log("this.props", this.props.prop.property._id);
                      var dat1 = {
                        _id: this.props.visit_id,
                      };

                      deleteVisit(dat1);
                    }
                  });
                }}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-trash mr-0" />
                </span>
                {/* <span className="btn-inner--text">Delete Property</span> */}
              </Button>
            </row>
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Property Name</small>
            </div>
            <div className="d-block">{this.props.propName}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Tenant Name</small>
            </div>
            <div className="d-block">{this.props.tenant}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Date of Visit</small>
            </div>
            <div className="d-block">{this.props.bookDate}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Time slot</small>
            </div>
            <div className="d-block text-uppercase">{this.props.timeSlot}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Mobile Number</small>
            </div>
            <div className="d-block">{this.props.tenantMobile}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Name</small>
            </div>
            <div className="d-block">{this.props.owner_name}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Phone</small>
            </div>
            <div className="d-block">{this.props.owner_phone}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Email</small>
            </div>
            <div className="d-block">{this.props.owner_email}</div>
          </div>
          {this.props.kh_name || this.props.kh_phone ? kh_det : ""}
          <div className="mt-4 mb-2">
            <Row>
              <Col>
                <Button
                  block
                  className="btn-round"
                  color="primary"
                  outline
                  type="button"
                  style={{
                    margin: "0px auto",
                    padding: "10px 12px",
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    this.toggleModal("selectDate");
                  }}
                >
                  RESCHEDULE
                </Button>
              </Col>

              <Col>
                <Button
                  block
                  type="submit"
                  className="btn-round"
                  color="primary"
                  //onClick={() => this.props.onProceed()}
                  style={{
                    margin: "0px auto",
                    padding: "10px 12px",
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "The visit will be approved for this date and time",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        this.props.changeStatus({
                          id: this.props.visit_id,
                          status: "approved",
                        });
                        const mail_content = {
                          visit_id: this.props.visit_id,
                          prop_id: this.props.prop_id,
                          tenant_id: this.props.tenant_id,
                          owner_id: this.props.owner_id,
                          owner_name: this.props.owner_name,
                          owner_phone: this.props.owner_phone,
                          owner_email: this.props.owner_email,
                          ten_name: this.props.tenant,
                          prop_name: this.props.propName,
                          time: this.props.timeSlot,
                          date: this.props.bookDate,
                          dateTime: new Date(Date.now()),
                        };
                        mailVisitBookApprovedOwner(mail_content);
                        notifVisitBookApprovedOwner(mail_content);
                        mailVisitBookApprovedTenant(mail_content);
                        notifVisitBookApprovedTenant(mail_content);
                      }
                    });
                  }}
                >
                  APPROVE
                </Button>
              </Col>
            </Row>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.selectDate}
              toggle={() => this.toggleModal("selectDate")}
            >
              <div className="modal-header border-0">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("selectDate")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <DateTime
                onDateTime={() => {
                  this.toggleModal("selectDate");
                  // const newVisit = {
                  //   prop_id: this.props.prop.property._id,
                  //   tenant_id: this.props.auth.user.id,
                  //   date: this.props.visit.visitDate.toString(),
                  //   time_interval: this.props.visit.visitTime.toString()
                  // };
                  // console.log("new Visit", newVisit);
                  // this.props.rescheduleVisit(newVisit, this.props.history);
                  const visitInfo = {
                    id: this.props.visit_id,
                    date: this.props.visit.visitDate.toString(),
                    time_interval: this.props.visit.visitTime.toString(),
                  };
                  this.props.rescheduleVisit(visitInfo);
                  this.props.changeStatus({
                    id: this.props.visit_id,
                    status: "approved",
                  });
                  const mail_content = {
                    prop_id: this.props.prop_id,
                    visit_id: this.props.visit_id,
                    tenant_id: this.props.tenant_id,
                    owner_id: this.props.owner_id,
                    ten_name: this.props.tenant,
                    prop_name: this.props.propName,
                    owner_name: this.props.owner_name,
                    owner_phone: this.props.owner_phone,
                    owner_email: this.props.owner_email,
                    time: this.props.visit.visitTime.toString(),
                    date: this.props.visit.visitDate.toString(),
                    dateTime: new Date(Date.now()),
                  };
                  mailVisitBookApprovedOwner(mail_content);
                  notifVisitBookApprovedOwner(mail_content);
                  mailVisitBookRescheduledTenant(mail_content);
                  notifVisitBookRescheduledTenant(mail_content);

                  this.toggleModal("selectDate");
                }}
                onBack={() => {
                  if (this.props.auth.isAuthenticated) {
                    this.toggleModal("selectDate");
                  } else {
                    this.toggleModal("selectDate");
                    this.toggleModal("defaultModal");
                  }
                }}
              />
            </Modal>
          </div>
        </div>
      </>
    );
  }
}
VisitRequestCard.propTypes = {
  rescheduleVisit: PropTypes.func,
  changeStatus: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  visit: state.visit,
});

export default connect(mapStateToProps, { rescheduleVisit, changeStatus })(
  VisitRequestCard
);
