import {
  SET_ADMIN_CONFIG,
  GET_USERS,
  USER_LOADING,
  SET_OWNER_PROPS,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  config: {},
  users: null,
  loading: false,
  ownerProps: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_OWNER_PROPS:
      return {
        ...state,
        ownerProps: action.payload,
      };
    default:
      return state;
  }
}
