import React, { Component } from "react";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import {
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MultiStepProgressBar from "components/Navbars/MultiStepProgressBar/MultiStepProgressBar";
import { saveNewPropInfo, updateGallery } from "../../actions/propActions";
import { saveGalImgs, deleteImages } from "../../actions/imageActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateInput from "components/Inputs/DateInput";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import axios from "axios";
import Moment from "moment";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import Gallery from "react-grid-gallery";
import "./EditProperty.scss";
import Spinner from "../../components/Common/Spinner";
import SearchBar from "material-ui-search-bar";
import Script from "react-load-script";
import { PropertyTypes } from "../../utils/consts";
//import { getAllConfig } from "../../actions/adminActions";

const commercial_prop_types = [PropertyTypes.shop, PropertyTypes.office_space];
const residential_prop_types = [
  PropertyTypes.land,
  PropertyTypes.apartment,
  PropertyTypes.house,
  PropertyTypes.villa,
];

class PropertyInfo extends Component {
  constructor() {
    super();
    this.state = {
      prop_name: "",
      area: "",
      city: "",
      locality: "Kakkand",
      address: "",
      bedrooms: "",
      bathrooms: "",
      parking: "",
      purpose: "rent",
      furnish: "Semi Furnished",
      rent_amount: "",
      maintenance_amount: "",
      available_from: Moment(Date.now()).format("DD-MM-YYYY"),
      available_for: "Both",
      pictures: [],
      pic_names: [],
      pic_urls: [],
      remarks: "",
      price: "",
      land_area: "",
      is_active: "false",
      is_rented_out: "false",
      is_sold: "false",
      image_objects: [],
      img_selected: false,
      image_urls: [],
      gallery: [],
      dupFileNames: [],
      loading: false,

      propertyType: "Apartment",
      offerType: "Residential",
      waterSource: "Well",
      landmark: "",
      query: "",
      floor_number: null,
      total_floor_number: null,
      parking_number: null,
      localityArr: null,
      errors: {},
    };

    this.handleWaterSourceString = this.handleWaterSourceString.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDropDown = this.onDropDown.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this);
    // this.onDrop = this.onDrop.bind(this);
  }
  handleWaterSourceString(op, str) {
    var res = "";
    if (op === "add") {
      res = this.state.waterSource.concat("," + str);
      this.setState({ waterSource: res }, () => {
        console.log("this.state.waterSource :>> ", this.state.waterSource);
      });
    } else if (op === "delete") {
      res = this.state.waterSource.replace(str, "");
      res = res.replace(/,\s*$/, "");
      res = res.replace(/^,/, "");
      this.setState({ waterSource: res }, () => {
        console.log("this.state.waterSource :>> ", this.state.waterSource);
      });
    }
  }
  getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      window.location = "/login-page";
    }
    if (this.props.prop.new_prop_info != null) {
      this.setState(this.props.prop.new_prop_info);
    }
    if (this.props.img.uploaded_urls.length > 0) {
      this.setState({ image_urls: this.props.img.uploaded_urls });
      let imgs = this.props.img.uploaded_urls;
      var image_objects = this.state.image_objects;
      for (let i = 0; i < imgs.length; i++) {
        image_objects = image_objects.concat({
          src: imgs[i],
          thumbnail: imgs[i],
          thumbnailWidth: 320,
          thumbnailHeight: 212,
          isSelected: false,
          //   customOverlay: customOverlay
        });
      }
      this.setState({ image_objects: image_objects });
    }
    //this.props.getAllConfig();
    axios
      .get("/api/admin/getConfig")
      .then((res) => {
        console.log("getConfig res :>> ", res);
        this.setState({ localityArr: res.data[0].locations.split(",") });
      })
      .catch((err) => console.log(`conf download error`, err));
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onDropDown(propertyType) {
    var offerType = commercial_prop_types.includes(propertyType)
      ? "Commercial"
      : "Residential";
    this.setState({ propertyType: propertyType, offerType: offerType });
  }
  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["(cities)"],
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(["address_components", "formatted_address"]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };
  handlePlaceSelect = () => {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      this.setState({
        city: address[0].long_name,
        query: addressObject.formatted_address,
      });
    }
  };
  onSubmit(e) {
    e.preventDefault();

    const propInfo = {
      prop_name: this.state.prop_name,
      area: this.state.area,
      city: this.state.city,
      locality: this.state.locality,
      address: this.state.address,
      bedrooms: this.state.bedrooms,
      bathrooms: this.state.bathrooms,
      parking: this.state.parking,
      purpose: this.state.purpose,
      furnish: this.state.furnish,
      rent_amount: this.state.rent_amount,
      maintenance_amount: this.state.maintenance_amount,
      available_from: this.state.available_from,
      available_for: this.state.available_for,
      pictures: this.state.pictures,
      price: this.state.price,
      land_area: this.state.land_area,
      // pic_names: pics,
      pic_urls: this.props.img.uploaded_urls,
      is_active: this.state.is_active,
      is_rented_out: this.state.is_rented_out,
      is_sold: this.state.is_sold,
      inspection_status: "pending",
      remarks: this.state.remarks,

      property_type: this.state.propertyType,
      landmark: this.state.landmark,
      water_source: this.state.waterSource,
      floor_number: this.state.floor_number,
      parking_number: this.state.parking_number,
      total_floor_number: this.state.total_floor_number,
      offer_type: this.state.offerType,
    };
    console.log("submitted", propInfo);
    this.props.saveNewPropInfo(propInfo);

    this.props.onProceed();
  }

  handleImageUpload(event) {
    if (event.target.files.length > 15) {
      Swal.fire(
        "You can only upload upto 15 pictures!",
        "Please retry!",
        "error"
      );
      // window.location.reload();
      event.target.value = "";
    } else {
      this.setState({ loading: true });
      var dupFile = null;
      var newName = "";
      var Cfiles = [];
      const files = event.target.files;
      console.log("files", files);
      var lp = new Promise((resolve, reject) => {
        // for (var i = 0; i < event.target.files.length; i++) {
        Array.from(files).forEach((imageFile, index, array) => {
          //var imageFile = event.target.files[i];
          console.log(
            "originalFile instanceof Blob",
            imageFile instanceof Blob
          ); // true
          console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

          var options = {
            maxSizeMB: 4,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          imageCompression(imageFile, options)
            .then(function (compressedFile) {
              console.log(
                "compressedFile instanceof Blob",
                compressedFile instanceof Blob
              ); // true
              console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
              ); // smaller than maxSizeMB

              Cfiles.push(compressedFile);
              console.log("imageFile :", imageFile);

              console.log("cfiles", Cfiles, array.length);
              if (Cfiles.length === array.length) resolve();
            })
            .catch(function (error) {
              console.log("error", error.message);
            });
        });
      });
      lp.then(() => {
        const data = new FormData();
        var str = [];
        var pics = [];
        var pic_urls2 = [];

        this.setState(
          {
            pictures: Cfiles,
          },
          () => {
            console.log("pictures:", this.state.pictures);
          }
        );
        var getRandomNumber = (min, max) => {
          return Math.floor(Math.random() * (max - min)) + min;
        };
        for (var x = 0; x < Cfiles.length; x++) {
          str = Cfiles[x].name.split(".");
          newName = getRandomNumber(100000, 999999).toString() + "." + str[1];
          pics = pics.concat(newName);
          dupFile = new File([Cfiles[x]], newName);
          this.setState({
            dupFileNames: this.state.dupFileNames.concat(newName),
          });
          data.append("file", dupFile);
        }

        axios
          .post("/api/images/upload", data, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                loadProgressBar();
              }
            },
          })
          .then((res) => {
            console.log(
              "images_data from image api: " + JSON.stringify(res.data)
            ); //res.locationArray); //
            for (var x = 0; x < res.data.locationArray.length; x++) {
              pic_urls2 = pic_urls2.concat(res.data.locationArray[x]);
            }
            this.props.saveGalImgs(res.data.locationArray);

            //thumbnail upload
            var thumbFiles = [];
            var lp = new Promise((resolve, reject) => {
              // for (var i = 0; i < event.target.files.length; i++) {
              var tFiles = files;
              console.log("this.state.dupFileNames :", this.state.dupFileNames);
              Array.from(tFiles).forEach((imageFile, index, array) => {
                dupFile = new File([Cfiles[x]], newName);
                //var imageFile = event.target.files[i];
                console.log(
                  "originalFile instanceof Blob",
                  imageFile instanceof Blob
                ); // true
                console.log(
                  `originalFile size ${imageFile.size / 1024 / 1024} MB`
                );

                var options = {
                  maxSizeMB: 0.05,
                  maxWidthOrHeight: 300,
                  useWebWorker: true,
                };

                imageCompression(imageFile, options)
                  .then(function (compressedFile) {
                    console.log(
                      "compressedFile instanceof Blob",
                      compressedFile instanceof Blob
                    ); // true
                    console.log(
                      `compressedFile size ${
                        compressedFile.size / 1024 / 1024
                      } MB`
                    ); // smaller than maxSizeMB

                    thumbFiles.push(compressedFile);
                    console.log("imageFile :", imageFile);

                    console.log("thumbFiles", thumbFiles, array.length);
                    if (thumbFiles.length === array.length) resolve();
                  })
                  .catch(function (error) {
                    console.log("error", error.message);
                  });
              });
            });

            lp.then(() => {
              const thumbData = new FormData();
              var dupThumb;
              for (var y = 0; y < thumbFiles.length; y++) {
                dupThumb = new File(
                  [thumbFiles[y]],
                  this.state.dupFileNames[y]
                );
                thumbData.append("file", dupThumb);
              }

              axios
                .post("/api/images/uploadThumbnails", thumbData, {
                  onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                      loadProgressBar();
                    }
                  },
                })
                .then((res) => {
                  console.log("thumbnail uploaded");
                  this.setState({ loading: false });
                });
            });
          });
      });
    }
  }
  delFn = () => {
    var selImages = this.getSelectedImages();
    Swal.fire({
      title: "Delete " + selImages.length + " images?",
      text: "Selected image(s) will be permanantly deleted from the system!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        console.log("selImages", selImages);
        var imgKeys = [];
        for (let i = 0; i < selImages.length; i++) {
          imgKeys = imgKeys.concat(selImages[i].src.substring(54));
        }
        var del_imgs_obj = { del_imgs: imgKeys };

        deleteImages(del_imgs_obj);
        //code to delete these elments from state.gallery[]
        // updateGallery({ id: this.state._id, gallery: this.state.gallery });
        var gal = this.state.image_urls; //this.state.image_objects;
        console.log("gallery state after delete", gal);
        console.log("sel images", selImages);
        var index = -1;

        for (var j = 0; j < gal.length; j++) {
          for (var i = 0; i < selImages.length; i++) {
            if (gal[j] === selImages[i].src) {
              console.log("index  of selImages:", j);
              gal.splice(j, 1);
            }
          }
        }

        var gal2 = this.state.image_urls;
        for (j = 0; j < gal2.length; j++) {
          for (i = 0; i < selImages.length; i++) {
            if (gal2[j] === selImages[i].src) {
              console.log("index  of selImages:", j);
              gal2.splice(j, 1);
            }
          }
        }

        this.setState({ image_objects: gal }, () => {});
      } else {
        console.log("Deletion Failed", "Please try again !");
      }
    });
  };
  getSelectedImages = () => {
    var selected = [];
    for (var i = 0; i < this.state.image_objects.length; i++)
      if (this.state.image_objects[i].isSelected === true)
        selected.push(this.state.image_objects[i]);
    return selected;
  };

  onSelectImage(index, image) {
    var images = this.state.image_objects.slice();
    var img = images[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;

    this.setState({
      image_objects: images,
    });

    var sel = this.getSelectedImages();
    sel.length < 1
      ? this.setState({ img_selected: false })
      : this.setState({ img_selected: true });

    console.log("state after sel", this.state);
  }

  render() {
    let currProp = this.props.prop.new_prop_info;
    const saveButton = !this.state.loading ? (
      <>
        {" "}
        <Button
          block
          type="submit"
          className="mt-5 btn-round"
          color="primary"
          //onClick={() => this.props.onProceed()}
          style={{
            margin: "0px auto",
            maxWidth: "300px",
            padding: "15px 10px",
            borderRadius: "24px",
          }}
        >
          Save Property Info
        </Button>
      </>
    ) : (
      <>
        <Spinner />
        <p className="text-center">Pictures Uploading. Please Wait...</p>
      </>
    );
    const img_del_btn = (
      <div className="overflow-hidden d-block my-4">
        <Button
          outline
          type="button"
          className="mt-0 btn-round"
          color="warning"
          onClick={() => this.delFn()}
          style={{
            margin: "0px auto",
            maxWidth: "150px",
            padding: "8px 15px",
            borderRadius: "24px",
          }}
        >
          Delete
        </Button>
      </div>
    );
    const gal_content = (
      <>
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Images Uploaded
        </small>
        <p>To delete, select the images first and press on the delete button</p>
      </>
    );
    const available_for_component = (
      <OptionSelector
        label="Available for"
        option1="Bachelor"
        option2="Family"
        option3="Both"
        def_option={
          currProp === null
            ? 3
            : currProp.available_for === "bachelor"
            ? 1
            : currProp.available_for === "family"
            ? 2
            : currProp.available_for === "both"
            ? 3
            : 3
        }
        onOptionClick={(val) => {
          console.log("val=", val);
          switch (val) {
            case 1:
              this.setState({ available_for: "bachelor" });
              break;
            case 2:
              this.setState({ available_for: "family" });
              break;
            case 3:
              this.setState({ available_for: "both" });
              break;
            default:
              this.setState({ available_for: null });
          }
        }}
      />
    );
    const rent_content = (
      <>
        <div className="my-4">
          <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            Rent in detail
          </small>
          <Input
            className="mb-3"
            placeholder="Rent Amount"
            type="number"
            name="rent_amount"
            value={this.state.rent_amount}
            onChange={this.onChange}
            required
          />
          <Input
            className="mb-3"
            placeholder="Monthly Maintenance Charges"
            type="number"
            name="maintenance_amount"
            value={this.state.maintenance_amount}
            onChange={this.onChange}
            required
          />
        </div>
      </>
    );

    // const water_source_input = (
    //   <OptionSelector
    //     label="Water Source"
    //     option1="Well"
    //     option2="Borewell"
    //     option3="Kerala Water Authority"
    //     def_option={
    //       currProp === null
    //         ? 3
    //         : currProp.waterSource === "Well"
    //         ? 1
    //         : currProp.waterSource === "Borewell"
    //         ? 2
    //         : currProp.waterSource === "Kerala Water Authority"
    //         ? 3
    //         : 3
    //     }
    //     onOptionClick={(val) => {
    //       console.log("val=", val);
    //       switch (val) {
    //         case 1:
    //           this.setState({ waterSource: "Well" });
    //           break;
    //         case 2:
    //           this.setState({ waterSource: "Borewell" });
    //           break;
    //         case 3:
    //           this.setState({ waterSource: "Kerala Water Authority" });
    //           break;
    //         default:
    //           this.setState({ waterSource: null });
    //       }
    //     }}
    //   />
    // );

    const water_source_input = (
      <>
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Water Source
        </small>
        <input
          className="mr-1"
          type="checkbox"
          defaultChecked={
            this.props.prop.new_prop_info
              ? this.props.prop.new_prop_info.water_source.includes("Well")
              : true
          }
          value="Well"
          onChange={(e) => {
            if (e.target.checked) {
              this.handleWaterSourceString("add", "Well");
            } else {
              this.handleWaterSourceString("delete", "Well");
            }
          }}
        />
        Well
        <input
          className="ml-3 mr-1"
          type="checkbox"
          defaultChecked={
            this.props.prop.new_prop_info
              ? this.props.prop.new_prop_info.water_source.includes("Borewell")
              : false
          }
          value="Borewell"
          onChange={(e) => {
            if (e.target.checked) {
              this.handleWaterSourceString("add", "Borewell");
            } else {
              this.handleWaterSourceString("delete", "Borewell");
            }
          }}
        />
        Borewell
        <input
          className="ml-3 mr-1"
          type="checkbox"
          defaultChecked={
            this.props.prop.new_prop_info
              ? this.props.prop.new_prop_info.water_source.includes(
                  "Kerala Water Authority"
                )
              : false
          }
          value="Kerala Water Authority"
          onChange={(e) => {
            if (e.target.checked) {
              this.handleWaterSourceString("add", "Kerala Water Authority");
            } else {
              this.handleWaterSourceString("delete", "Kerala Water Authority");
            }
          }}
        />
        Kerala Water Authority
      </>
    );
    const sell_content_with_land_area = (
      <div className="my-4">
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Price {this.state.propertyType === PropertyTypes.land ? "/ Cent" : ""}
        </small>
        <Input
          className="mb-3"
          placeholder="Price"
          type="number"
          name="price"
          value={this.state.price}
          onChange={this.onChange}
          required
        />
        <Input
          className="mb-3"
          placeholder="Land Area in cent"
          type="number"
          name="land_area"
          value={this.state.land_area}
          onChange={this.onChange}
          required
        />
      </div>
    );
    const sell_content_without_land_area = (
      <div className="my-4">
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Price
        </small>
        <Input
          className="mb-3"
          placeholder="Price"
          type="number"
          name="price"
          value={this.state.price}
          onChange={this.onChange}
          required
        />
      </div>
    );
    const floor_number_input = (
      <>
        <Input
          className="mb-3"
          placeholder="Floor Number"
          type="number"
          name="floor_number"
          value={this.state.floor_number}
          onChange={this.onChange}
          required
        />
        <Input
          className="mb-3"
          placeholder="Total Floor Numbers"
          type="number"
          name="total_floor_number"
          value={this.state.total_floor_number}
          onChange={this.onChange}
          required
        />
      </>
    );
    const parking_number_component = (
      <Input
        className="mb-3"
        placeholder="Parking (Number of vehicles)"
        type="number"
        name="parking_number"
        value={this.state.parking_number}
        onChange={this.onChange}
        required
      />
    );
    const sqftArea = (
      <Input
        className="mb-3"
        placeholder="Area in sqft"
        type="number"
        name="area"
        value={this.state.area}
        onChange={this.onChange}
        required
      />
    );
    const configuration = (
      <div className="my-4">
        <small className="d-block text-uppercase mt-3 mb-2 text-primary">
          Configuration
        </small>
        <div className="d-flex flex-row mb-2 flex-wrap flex-md-nowrap justify-content-between">
          <div className="d-flex mr-md-2 mb-2 flex-grow-1">
            <div
              className="d-flex align-self-center flex-grow-1"
              style={{ flexBasis: "120px" }}
            >
              <p className="mb-0">
                <i class="fa fa-bed mr-2 text-primary"></i>
                Bedrooms&nbsp;
              </p>
            </div>
            <div className="d-flex flex-grow-1" style={{ flexBasis: "100px" }}>
              <Input
                placeholder="How many?"
                type="number"
                name="bedrooms"
                value={this.state.bedrooms}
                onChange={this.onChange}
                required
              />
            </div>
          </div>

          <div className="d-flex ml-md-2 flex-grow-1">
            <div
              className="d-flex align-self-center flex-grow-1"
              style={{ flexBasis: "120px" }}
            >
              <p className="mb-0">
                <i class="fa fa-shower mr-2 text-primary"></i>
                Bathrooms
              </p>
            </div>
            <div className="d-flex flex-grow-1" style={{ flexBasis: "100px" }}>
              <Input
                placeholder="How many?"
                type="number"
                name="bathrooms"
                value={this.state.bathrooms}
                onChange={this.onChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row mb-2">
          <div
            className="d-flex align-self-center flex-grow-1 flex-md-grow-0 mr-md-4"
            style={{ flexBasis: "120px" }}
          >
            <p className="mb-0">
              <i class="fa fa-car mr-2 text-primary"></i>
              Parking
            </p>
          </div>
          <div className="d-flex flex-grow-1" style={{ flexBasis: "100px" }}>
            <Input
              placeholder="How many?"
              type="number"
              name="parking"
              value={this.state.parking}
              onChange={this.onChange}
              required
            />
          </div>
        </div>
      </div>
    );
    const furnishing_input = (
      <OptionSelector
        label="Furnishing Details"
        option1="Fully Furnished"
        option2="Semi Furnished"
        option3="Not Furnished"
        def_option={
          currProp === null
            ? 2
            : currProp.furnish === "Fully Furnished"
            ? 1
            : currProp.furnish === "Semi Furnished"
            ? 2
            : currProp.furnish === "Not Furnished"
            ? 3
            : 2
        }
        onOptionClick={(val) => {
          console.log("val=", val);
          switch (val) {
            case 1:
              this.setState({ furnish: "Fully Furnished" });

              break;
            case 2:
              this.setState({ furnish: "Semi Furnished" });
              break;
            case 3:
              this.setState({ furnish: "Not Furnished" });
              break;
            default:
              this.setState({ furnish: null });
          }
        }}
      />
    );
    const later_content = "";
    const localityDropdownItems =
      this.state.localityArr !== null ? (
        this.state.localityArr.map((l) => (
          <DropdownItem
            onClick={() => {
              this.onDropDown(l);
            }}
          >
            {l}
          </DropdownItem>
        ))
      ) : (
        <DropdownItem
          onClick={() => {
            this.onDropDown("Kakkanad");
          }}
        >
          {"Kakkanad"}
        </DropdownItem>
      );
    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <MultiStepProgressBar step="1" />
          <h3 className="text-black mt-3 mt-lg-4 mb-3 mb-lg-4 text-center">
            Property Info
          </h3>
          <Row>
            <Col
              sm="12"
              md="6"
              lg="6"
              className="mt-3"
              style={{ margin: "0px auto" }}
            >
              <FormGroup>
                <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                  Property Type
                </small>
                <UncontrolledDropdown className="mb-3">
                  <DropdownToggle caret>
                    {this.state.propertyType}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Residential</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.land);
                      }}
                    >
                      {PropertyTypes.land}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.apartment);
                      }}
                    >
                      {PropertyTypes.apartment}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.villa);
                      }}
                    >
                      {PropertyTypes.villa}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.house);
                      }}
                    >
                      {PropertyTypes.house}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem header>Commericial</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.shop);
                      }}
                    >
                      {PropertyTypes.shop}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.onDropDown(PropertyTypes.office_space);
                      }}
                    >
                      {PropertyTypes.office_space}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Input
                  className="mb-3"
                  placeholder="Property Name"
                  type="text"
                  name="prop_name"
                  value={this.state.prop_name}
                  onChange={this.onChange}
                  required
                />
                {this.state.propertyType !== PropertyTypes.land ? (
                  sqftArea
                ) : (
                  <></>
                )}
                {[
                  PropertyTypes.apartment,
                  PropertyTypes.shop,
                  PropertyTypes.office_space,
                ].includes(this.state.propertyType) ? (
                  floor_number_input
                ) : (
                  <></>
                )}
                {this.state.propertyType === PropertyTypes.shop ? (
                  parking_number_component
                ) : (
                  <></>
                )}
                <div className="my-4 overflow-hidden">
                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Upload Pictures (maximum 15 pictures)
                  </small>
                  <Input
                    className="my-2"
                    type="file"
                    accept=".webp,.jpg,.jpeg,.png"
                    name="imageUpload"
                    multiple
                    onChange={this.handleImageUpload}
                  />

                  <Gallery
                    images={this.state.image_objects}
                    //onSelectImage={this.onSelectImage}
                    enableImageSelection={false}
                    enableLightbox={false}
                  />
                </div>
                <OptionSelector
                  label="PURPOSE OF POSTING YOUR PROPERTY"
                  option1="To Rent"
                  option2="To Sell"
                  // option3="Decide Later"
                  def_option={
                    currProp === null
                      ? 1
                      : currProp.purpose === "rent"
                      ? 1
                      : currProp.purpose === "sell"
                      ? 2
                      : currProp.purpose === "later"
                      ? 3
                      : 1
                  }
                  onOptionClick={(val) => {
                    console.log("val=", val);
                    switch (val) {
                      case 1:
                        this.setState({ purpose: "rent" });
                        break;
                      case 2:
                        this.setState({ purpose: "sell" });
                        break;
                      case 3:
                        this.setState({ purpose: "later" });
                        break;
                      default:
                        this.setState({ purpose: null });
                    }
                  }}
                />
                {this.state.purpose === "rent"
                  ? rent_content
                  : this.state.purpose === "sell"
                  ? this.state.propertyType === "Apartment"
                    ? sell_content_without_land_area
                    : sell_content_with_land_area
                  : later_content}

                {residential_prop_types.includes(this.state.propertyType) &&
                this.state.purpose !== "sell" &&
                this.state.propertyType !== PropertyTypes.land ? (
                  available_for_component
                ) : (
                  <></>
                )}
                {residential_prop_types.includes(this.state.propertyType) &&
                this.state.propertyType !== PropertyTypes.land ? (
                  configuration
                ) : (
                  <></>
                )}

                {/* {this.state.img_selected ? img_del_btn : ""} */}
                <div className="my-4">
                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Locality Details
                  </small>
                  <UncontrolledDropdown className="mb-3">
                    <DropdownToggle caret>{this.state.locality}</DropdownToggle>
                    <DropdownMenu>{localityDropdownItems}</DropdownMenu>
                  </UncontrolledDropdown>
                  {/*  <Input
                    className="my-3"
                    placeholder="Locality"
                    type="text"
                    name="locality"
                    value={this.state.locality}
                    onChange={this.onChange}
                    required
                  />*/}
                  <Input
                    className="mb-3"
                    placeholder="City"
                    type="text"
                    name="city"
                    value={this.state.city}
                    onChange={this.onChange}
                    required
                  />
                  {/* <div>
                    <Script
                      url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBniy3ccvmhMyIdK9lb7Fmj7rIu7ZIp7DQ&libraries=places"
                      onLoad={this.handleScriptLoad}
                    />
                    <SearchBar
                      id="autocomplete"
                      placeholder=""
                      hintText="Search City"
                      value={this.state.query}
                      style={{
                        margin: "0 auto",
                        maxWidth: 800,
                      }}
                    />
                  </div> */}
                  {this.state.propertyType === PropertyTypes.house ? (
                    water_source_input
                  ) : (
                    <></>
                  )}
                  <Input
                    className="my-3"
                    placeholder="Address"
                    type="text"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange}
                    required
                  />
                  <Input
                    className="my-3"
                    placeholder="Landmark"
                    type="text"
                    name="landmark"
                    value={this.state.landmark}
                    onChange={this.onChange}
                    required
                  />
                  <Input
                    className="my-3"
                    placeholder="Remarks"
                    type="text"
                    name="remarks"
                    value={this.state.remarks}
                    onChange={this.onChange}
                  />
                </div>
                <div className="my-4">
                  <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                    Available from
                  </small>
                  <DateInput
                    onDateSelect={(val) => {
                      console.log("date from info", val);
                      this.setState({ available_from: val });
                    }}
                    def_date={this.state.available_from}
                  />
                </div>
                {this.state.propertyType !== PropertyTypes.land &&
                !commercial_prop_types.includes(this.state.propertyType) ? (
                  furnishing_input
                ) : (
                  <></>
                )}

                <div>{saveButton}</div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prop: state.prop,
  errors: state.errors,
  auth: state.auth,
  img: state.img,
});
PropertyInfo.propTypes = {
  saveNewPropInfo: PropTypes.func,
  saveGalImgs: PropTypes.func,
  // getAllConfig: PropTypes.func,
};
export default connect(mapStateToProps, {
  saveNewPropInfo,
  saveGalImgs,
  //getAllConfig,
})(PropertyInfo);
