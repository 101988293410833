import React, { Component } from "react";
import {
  FormGroup,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Swal from "sweetalert2";
import ReactDatetime from "react-datetime";
import moment from "moment";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "this.props.def_date",
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }
  componentDidMount() {
    console.log("this.props in component did mount :", this.props.def_date);
    this.setState({ date: this.props.def_date });
  }

  valid = (current) => {
    // var yesterday = ReactDatetime.moment().subtract(1, "day");
    var today = ReactDatetime.moment();
    return current.isAfter(today) && moment(current).day() !== 0;
  };

  render() {
    return (
      <>
        <FormGroup className="focused">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              value={this.props.def_date}
              // value={this.state.date}
              input={true}
              onChange={(value) => {
                try {
                  this.setState({ date: value.format("DD-MM-YYYY") });
                  this.props.onDateSelect(value.format("DD-MM-YYYY"));
                } catch {
                  console.log("value.toString()", value.toString());
                  var reg = /^([0-9]|-)$/;
                  if (!value.toString().match(reg)) {
                    Swal.fire({
                      icon: "warning",
                      title: "Invalid Date!",
                      text: "Please enter a Valid Date!",
                    });
                    this.setState({ date: moment().format("DD-MM-YYYY") });
                  }
                }
              }}
              timeFormat={false}
              dateFormat="DD-MM-YYYY"
              closeOnSelect={true}
              isValidDate={this.props.noPastDate && this.valid}
            />
          </InputGroup>
        </FormGroup>
      </>
    );
  }
}

export default DateInput;
