import React, { Component } from "react";
import { Container, Button, Row, Col } from "reactstrap";
import DarkNavbar from "components/Navbars/DarkNavbar";
import Footer from "components/Footers/Footer/Footer";
import Property from "components/Cards/PropertyCard";
import "./About.scss";
import Spinner from "components/Common/Spinner";
import {
  getPropsByObject,
  getAuthToken,
  getAllProps,
} from "actions/propActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
var defSearchObj = {
  pageNo: 1,
  itemsNo: 6,
  cityName: {
    city: "Barrie",
  },
  property_type: {
    in: ["Single Family"],
  },
  bedroom: {
    in: [1],
  },
  price: [
    {
      lt: 5000000,
    },
  ],
};
class About extends Component {
  componentDidMount() {
    var defSearchObj = {
      pageNo: 1,
      itemsNo: 6,
      cityName: {
        city: "Barrie",
      },
      property_type: {
        in: ["Single Family"],
      },
      bedroom: {
        in: [1],
      },
      price: [
        {
          lt: 5000000,
        },
      ],
    };
    console.log("defSearchObj", defSearchObj);
    this.props.getPropsByObject(defSearchObj);

    this.props.getAllProps();
    this.props.getAuthToken();
    // if (
    //   !this.props.auth.user.notifStatus ||
    //   this.props.auth.user.notifStatus === false
    // )
    //   this.props.notifSubscriptionCheck(this.props.auth.user);
  }

  render() {
    var { properties, loading } = this.props.prop;
    let sellPropertiesContent;
    console.log("this.props", "properties", this.props, properties);
    properties = properties ? properties.value : [];

    const no_property_found = (
      <>
        <Row
          className="mt-5"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Col sm="12" md="12" xl="12">
            <img
              className="mx-auto d-block pb-4"
              alt="..."
              src={require("./../../../assets/img/illustrations/no-property-found.svg")}
              style={{ height: "auto", width: "229px" }}
            />
            <div style={{ color: "#4A5BEA" }}>
              No properties meeting your criteria.
            </div>
            <div style={{ color: "#4A5BEA" }}>
              Please change your criteria and search again.
            </div>
          </Col>
        </Row>
      </>
    );

    if (properties === null || loading) {
      sellPropertiesContent = <Spinner />;
    } else {
      // Object.keys(properties)
      if (properties && properties.length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        // p.purpose === "rent" &&
        // (p.is_active !== undefined
        //   ? p.is_active === "true"
        //     ? true
        //     : false
        //   : true) ?
        //rentPropertiesContent
        sellPropertiesContent = properties.map((p, i) => (
          <Col sm="12" md="6" lg="6" xl="4" key={i}>
            <Property
              property_type={p.PropertySubType ? p.PropertySubType : ""}
              // floor_number={p.floor_number}
              //total_floor_number={p.total_floor_number}
              //land_area={p.LivingArea}
              purpose={p.TotalActualRent ? "Rent" : "Sell"}
              user="owner"
              id={p.ListingKey ? p.ListingKey : ""}
              area={p.LivingArea ? p.LivingArea : ""}
              name={p.UnparsedAddress ? p.UnparsedAddress : ""}
              price={p.ListPrice ? p.ListPrice : ""}
              rent={p.TotalActualRent ? p.TotalActualRent : ""}
              bedroom={p.BedroomsTotal ? p.BedroomsTotal : ""}
              bathroom={p.BathroomsTotalInteger ? p.BathroomsTotalInteger : ""}
              place={p.UnparsedAddress ? p.UnparsedAddress : ""}
              city={p.City ? p.City : ""}
              remarks={p.PublicRemarks ? p.PublicRemarks : ""}
              // requested={p.requested}
              // inspection_status={p.inspection_status}
              //hero_image={p.gallery[0]}
              hero_image={p.Media ? p.Media[0].MediaURL : default_pic} //no++
            />
          </Col>
        ));
      } else {
        sellPropertiesContent = <Spinner />;
        this.props.getPropsByObject(defSearchObj);
      }
    }
    const searchButton = (
      <Button
        color="primary"
        size="lg"
        // style={{ marginLeft: "28px " }}
        type="button"
        className="book-cta"
        onClick={() => {
          this.props.history.push({
            pathname: "/search/1",
          });
        }}
      >
        Search Properties
      </Button>
    );
    return (
      <>
        <DarkNavbar />
        <section className="section section-sm bg-primary">
          <Container>
            <div>
              <h1 className="text-white mt-5">Neil Jaison</h1>
            </div>
          </Container>
        </section>
        <Container>
          <section className="py-md about-us">
            {/* <div class="hero">
              <h3 className="quote text-white">
                Helping people to manage their real estate properties
              </h3>
            </div> */}

            <div className="hero">
              <div class="profilePic" style={{ float: "left" }}>
                {/* <h3 className="quote text-white">
                  Helping people to manage their real estate properties
                </h3> */}
              </div>
              <div style={{ float: "right" }}>
                <h4 className="text-primary special-text">
                  3 Daniel Lane,Barrie <br />
                  +1 249-999-1234 <br />
                  neil@staylocal.ca
                </h4>
                {searchButton}
              </div>
            </div>

            <div className="main-body mt-5">
              <p>
                Neil Jaison is your trusted partner for all your real estate
                needs in Ontario. With years of experience and a deep-rooted
                commitment to excellence, Neil has earned his reputation as the
                go-to real estate agent and property manager in the beautiful
                city of Barrie and the wider Ontario region. Whether you're a
                seasoned investor, a first-time homebuyer, or a property owner
                seeking professional management services, Neil Jaison is here to
                provide you with unmatched expertise and personalized attention.
              </p>
              <p>
                Neil's extensive knowledge of the Ontario real estate market,
                combined with his dedication to client satisfaction, sets him
                apart in the industry. His comprehensive understanding of local
                neighborhoods, property values, and market trends ensures that
                you make informed decisions, whether you're buying, selling, or
                seeking property management services. Neil Jaison is not just a
                real estate agent; he's your advocate, your advisor, and your
                partner on your journey through Ontario's dynamic real estate
                landscape. Explore our website to learn more about Neil's
                services and discover how he can help you achieve your real
                estate goals in Ontario. Your dream property is just a click
                away with Neil Jaison by your side.
              </p>
              <h4 className="text-primary special-text">
                Neil's vision as a realtor is to be the foremost authority in
                Ontario's real estate market, offering unparalleled expertise
                and service to clients. He aims to help clients achieve their
                property goals while building lasting relationships based on
                trust and satisfaction.
              </h4>
              <h3 className="sub-title">My Listings</h3>
              <Row>
                {sellPropertiesContent != null
                  ? sellPropertiesContent
                  : no_property_found}
              </Row>
              {/* {searchButton} */}
              <h3 className="sub-title">Why Choose Neil Jaison?</h3>
              <ul>
                <li>
                  <span>
                    <b>Local Expertise: </b> Tailored advice for GTA.
                  </span>
                </li>
                <li>
                  <span>
                    <b>Exceptional Client Service:</b> Hassle-free, enjoyable
                    experience.
                  </span>
                </li>
                <li>
                  <span>
                    <b>Proven Track Record: </b> Outstanding results in real
                    estate.
                  </span>
                </li>
                <li>
                  <span>
                    <b>Comprehensive Services:</b> Buying, selling, management,
                    investments.
                  </span>
                </li>

                <li>
                  <span>
                    <b>Trust and Integrity: </b> Prioritizing your best
                    interests.
                  </span>
                </li>
              </ul>
              <p className="mt-5">
                Hire our professional services for the top-notch experience.
                Call us on
                <a href="tel:​+1 2499991234">​ +1 249-999-1234</a> or drop your
                enquiry to
                <a href="mailto:info@staylocal.ca"> info@staylocal.ca</a>
              </p>
            </div>
            {/* <iframe
              id="youtube-video"
              width="100%"
              src="https://www.youtube.com/embed/tFvxbVkN5Sg?controls=1"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            ></iframe> */}

            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/03GiqYW5I6c?si=RiyyPi8EVQpnUXhY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </section>
        </Container>
        <Footer />
      </>
    );
  }
}
About.propTypes = {
  getAllProps: PropTypes.func.isRequired,
  getAllConfig: PropTypes.func,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired,
  getPropsByObject: PropTypes.func,
  getAuthToken: PropTypes.func,
};

const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllProps,
  getPropsByObject,

  getAuthToken,
})(About);
