import axios from "../client";
import Axios from "axios";
import Swal from "sweetalert2";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  GET_PROPS,
  GET_PROP,
  PROP_LOADING,
  CLEAR_CURRENT_PROP,
  SAVE_NEW_PROP_INFO,
  SAVE_NEW_PROP_OWNER,
  SAVE_NEW_PROP_KEY_HOLDER,
  REGISTER_NEW_PROP_OWNER,
  GET_OWNER_PROPS,
  GET_PROP_DASHS,
  GET_ERRORS,
  GET_F_PROPS,
  SET_SEARCH_OBJECT,
} from "./types";

// Get current prop by url
export const getPropByUrl = (url) => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_PROP,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_PROP,
        payload: {},
      })
    );
};

// Get current prop by url
export const getPropByKey = (propKey) => (dispatch) => {
  dispatch(setPropLoading());
  var qOb = { propKey: propKey, token: localStorage.getItem("accessToken") };
  axios
    .post("user/getPropertyByKey", qOb)
    .then((res) => {
      console.log("keyRes", res);
      dispatch({
        type: GET_PROP,
        payload: res.data.value[0],
      });
    })
    .catch((err) => {
      console.log("keyRes err", err);
      dispatch({
        type: GET_PROP,
        payload: {},
      });
    });
};
// Get current prop by object
export const getPropByObject = (ob) => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .post("/api/properties/filter", ob)
    .then((res) =>
      dispatch({
        type: GET_PROP,
        payload: res.data[0],
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROP,
        payload: {},
      })
    );
};
export const getPropsByObject =
  (ob, state1 = {}) =>
  (dispatch) => {
    dispatch(setPropLoading());
    var qOb = { searchObj: ob, token: localStorage.getItem("accessToken") };
    console.log("qOb", qOb);
    axios
      .post("user/getProperties", qOb, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("getProps res", res);
        dispatch({
          type: GET_PROPS,
          payload: res.data,
        });
        dispatch({
          type: SET_SEARCH_OBJECT,
          payload: state1,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_PROPS,
          payload: {},
        })
      );
  };

export const getAuthToken = () => (dispatch) => {
  if (localStorage.setTime) {
    var ti = localStorage.getItem("setTime");
    var t = ReactDatetime.moment().add(-1, "h");
    console.log("t", "ti", t, moment(ti), t.isAfter(ti));
    if (t.isAfter(moment(ti))) {
      // if (true) {
      axios
        .get("user/getCreaToken")
        .then((res) => {
          console.log("res", res);
          var timeNow = ReactDatetime.moment();
          localStorage.removeItem("setTime");
          localStorage.removeItem("accessToken");
          localStorage.setItem("accessToken", res.data.access_token);
          localStorage.setItem("setTime", timeNow);
        })
        .catch((err) => console.log("err", err));
    }
  } else {
    axios
      .get("user/getCreaToken")
      .then((res) => {
        console.log("res", res);
        var timeNow = ReactDatetime.moment();
        localStorage.removeItem("setTime");
        localStorage.removeItem("accessToken");
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("setTime", timeNow);
      })
      .catch((err) => console.log("err", err));
  }
};

export const getAllProps = () => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .get("/api/properties/all")
    .then((res) =>
      dispatch({
        type: GET_PROPS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROPS,
        payload: {},
      })
    );
};

export const getFeaturedProps = () => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .get("/api/properties/threeProps")
    .then((res) =>
      dispatch({
        type: GET_F_PROPS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROPS,
        payload: {},
      })
    );
};

export const getOwnerProps = (owner_id) => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .post("/api/properties/filter", owner_id)
    .then((res) => {
      dispatch({
        type: GET_OWNER_PROPS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_OWNER_PROPS,
        payload: { nodata: "data no" },
      })
    );
};

export const registerProp = (propData) => (dispatch) => {
  axios
    .post("/api/properties/register", propData)
    .then((res) => {
      dispatch(saveNewPropKeyHolder({}));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateProp = (id, propData) => (dispatch) => {
  axios
    .put(`/api/properties/update_prop/${id}`, propData)
    .then((res) => {
      dispatch(getPropByObject({ _id: propData._id }));

      //history.push('pm');
      window.location = "/pm-property" + propData._id;
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// PROP loading
export const setPropLoading = () => {
  return {
    type: PROP_LOADING,
  };
};

// Clear PROP
export const clearCurrentProp = () => {
  return {
    type: CLEAR_CURRENT_PROP,
  };
};

export const saveNewPropInfo = (newProp) => {
  return {
    type: SAVE_NEW_PROP_INFO,
    payload: newProp,
  };
};
export const saveNewPropOwner = (newOwner) => {
  return {
    type: SAVE_NEW_PROP_OWNER,
    payload: newOwner,
  };
};
export const saveNewPropKeyHolder = (newKeyHolder) => {
  return {
    type: SAVE_NEW_PROP_KEY_HOLDER,
    payload: newKeyHolder,
  };
};
export const toggleRegPropOwner = (val) => {
  return {
    type: REGISTER_NEW_PROP_OWNER,
    payload: val,
  };
};
export const updateIsActive = (propData) => {
  console.log("User data in User Update Action", propData);
  axios
    .post("/api/properties/updateIsActive", propData)
    .then((res) => {
      console.log("User data up to date", res.data);
    })
    .catch((err) => console.log(err));
};
export const updateKeyReceived = (propData) => {
  console.log("User data in updateKeyReceived", propData);
  axios
    .post("/api/properties/updateKeyReceived", propData)
    .then((res) => {
      console.log("User data up to date", res.data);
    })
    .catch((err) => console.log(err));
};
export const updateGallery = (galData) => {
  console.log("User data in User Update Action", galData);
  axios
    .post("/api/properties/updateGallery", galData)
    .then((res) => {
      console.log("User data up to date", res.data);
    })
    .catch((err) => console.log(err));
};
export const changeInspectionStatus = (statusData) => (dispatch) => {
  axios
    .post("/api/properties/changeInspectionStatus", statusData)
    .then((res) => {
      console.log(" changeInspectionStatus result", res.data);
      //  dispatch(getAllProps());
      window.location.reload();
    })
    .catch((err) => console.log(err));
};
export const deleteInspectionCard = (id) => (dispatch) => {
  axios
    .post("/api/properties/deleteInspectionCard", { id: id })
    .then((res) => {
      console.log(" deleteInspectionCard result", res.data);
      //  dispatch(getAllProps());
      window.location.reload();
    })
    .catch((err) => console.log(err));
};
export const getPropDashs = () => (dispatch) => {
  dispatch(setPropLoading());
  axios
    .get("/api/properties/propertyCompleteDash")
    .then((res) =>
      dispatch({
        type: GET_PROP_DASHS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROPS,
        payload: {},
      })
    );
};
export const setThumbnail = (thumbnail_obj) => {
  axios
    .post("/api/properties/setThumbnail", thumbnail_obj)
    .then((res) => {
      console.log("setThumbnail result", res.data);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Thumbnail changed",
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .catch((err) => {
      console.log("setThumbnail error client", err);
    });
};

export const deleteProperty = (data1) => {
  var data2 = { prop_id: data1._id };
  console.log("data from deleteProperty", data1);
  console.log("data2 from deleteProperty", data2);
  axios
    .delete("/api/visits/delete_visit", { data: data2 })
    .then((res) => {
      console.log("res of delete visit", res.data);
      axios
        .delete("/api/properties/delete_prop", { data: data1 })
        .then((res) => {
          console.log("res.data delete property", res.data);
          window.location = "/pm";
        })
        .catch((err) => console.log("error"));
    })
    .catch((err) => console.log("error"));
};
