import React, { Component } from "react";
import Navbar from "components/Navbars/DarkNavbar";
import Property from "components/Cards/PropertyCard";
import Footer from "components/Footers/Footer/Footer";
import Spinner from "../../components/Common/Spinner";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllProps,
  getPropsByObject,
  getAuthToken,
} from "../../actions/propActions";
import { notifSubscriptionCheck } from "../../actions/authActions";
import Autocomplete from "components/Inputs/Autocomplete";
import { cities } from "utils/consts";
import {
  Row,
  Col,
  Container,
  NavItem,
  NavLink,
  Nav,
  Modal,
  Input,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import BubbleRadio from "components/Inputs/BubbleRadio/BubbleRadio";
import BubbleRadioSingle from "components/Inputs/BubbleRadio/BubbleRadioSingle";
import { PropertyTypes } from "utils/consts";
import { getAllConfig } from "../../actions/adminActions";
import BubbleRadioAuto from "components/Inputs/BubbleRadio/BubbleRadioAuto";
const configProps = [PropertyTypes.single_family, PropertyTypes.vacant_land];
const commercialProps = [
  PropertyTypes.industrial,
  PropertyTypes.vacant_land,
  PropertyTypes.agriculture,
  PropertyTypes.retail,
];

class Properties extends Component {
  constructor() {
    super();
    this.state = {
      plainTabs: 2,
      searchOpen: false,
      searchLocality: [], //["Kakkanad"],
      searchDeal: "", //Rental
      searchOfferType: "Residential",
      searchPropertyType: [PropertyTypes.single_family],
      searchFurnishedStatus: [],
      searchBhk: "1 Bed",
      searchBudget: [1],
      priceBud: "Below 1M",
      rentBud: [],
      cityName: "Barrie",
      pageNo: 1,
      itemsNo: 20,
    };
    this.searchDeal = this.plainTabs === 1 ? "Rental" : "Sale";
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  // const handleClick = (e) => {
  //   setState({
  //     activeItem: 0,
  //     filteredItems: [],
  //     displayItems: false,
  //     inputValue: e.currentTarget.innerText,
  //   });
  // };

  handleAutoComplete = (e) => {
    console.log("e.currentTarget.innerText", e.currentTarget.innerText);
    // const inputValue = e.currentTarget.value;
    this.setState({
      cityName: e.currentTarget.innerText,
    });
  };

  searchSubmit() {
    //  {available_for: {$in: ['both','family']},bathroom: {$in: ['1','2','3']}}
    //{rent:{ $gte:"10000", $lte: "12000" }}
    var searchKeys = ["locality,property_type,furnishing,bedroom,"];
    console.log(`searchKeys`, searchKeys);
    var searchObj = {};
    if (this.state.searchLocality.length > 0) {
      searchObj.locality = { in: this.state.searchLocality };
    }
    if (this.state.searchDeal !== "") {
      searchObj.purpose = this.state.searchDeal === "Rental" ? "rent" : "sell";
    }
    if (this.state.searchOfferType !== "") {
      // searchObj.property_type =
      //   this.state.searchOfferType === "Residential"
      //     ? { $in: configProps }
      //     : { $in: commercialProps };
    }
    console.log("this.state.cityName", this.state.cityName);
    if (this.state.cityName.length > 0) {
      searchObj.cityName = { city: this.state.cityName };
    }
    if (this.state.searchPropertyType.length > 0) {
      searchObj.property_type = { in: this.state.searchPropertyType };
    }
    if (this.state.searchFurnishedStatus.length > 0) {
      searchObj.furnishing = { in: this.state.searchFurnishedStatus };
    }
    if (this.state.searchBhk.length > 0) {
      var bed = this.state.searchBhk;
      var bedArr = [];
      if (bed.includes("1 Bed")) {
        //bedArr.push("1");
        bedArr[0] = 1;
      }
      if (bed.includes("2 Bed")) {
        bedArr[0] = 2;
      }
      if (bed.includes("3 Bed")) {
        bedArr[0] = 3;
      }
      var tempObj = { in: bedArr };

      if (bed.includes("3+ Bed")) {
        tempObj = { ...tempObj, gt: "3" };
      }
      if (bedArr.length > 0 || Object.keys(tempObj).includes("gt")) {
        searchObj.bedroom = tempObj;
      }
    }
    //{rent:{ $gte:"10000", $lte: "12000", $gte:"14000",$lte: "20000"}}
    // var combinedBud = this.state.priceBud.concat(this.state.rentBud);
    var combinedBud = this.state.priceBud;

    if (combinedBud.length > 0) {
      //this.state.searchBudget
      var bud = combinedBud; //this.state.searchBudget;
      var budObj = {};
      var objArr = [];

      if (true) {
        //(this.state.searchDeal === "Rental")
        // if (bud.includes("Below 15K")) {
        //   budObj = { ...budObj, $lt: "15000" };
        //   objArr.push({ rent: { $lt: 15000 } });
        // }
        // if (bud.includes("15K-25K")) {
        //   budObj = {
        //     ...budObj,
        //     $gte: "15000",
        //     $lt: "25000",
        //   };
        //   objArr.push({ rent: { $gte: 15000, $lt: 25000 } });
        // }
        // if (bud.includes("25K-40K")) {
        //   budObj = {
        //     ...budObj,
        //     $gte: "25000",
        //     $lt: "40000",
        //   };
        //   objArr.push({ rent: { $gte: 25000, $lt: 40000 } });
        // }
        // if (bud.includes("Above 40K")) {
        //   budObj = { ...budObj, $gte: "40000" };
        //   objArr.push({ rent: { $gte: 40000 } });
        // }
        // console.log("budObj :>> ", budObj);
        // if (Object.keys(budObj).length > 0) {
        //   //searchObj.rent = budObj;
        // }
        // if (objArr.length > 0) {
        //   // searchObj.rent = { $or: objArr };
        //   searchObj.$or = objArr;
        //   console.log("searchObj.rent :>> ", { $or: objArr });
        // }

        if (bud.includes("Below 1M")) {
          budObj = { ...budObj, lt: "1000000" };
          objArr.push({ lt: 5000000 });
        }
        if (bud.includes("1M-5M")) {
          budObj = { ...budObj, gte: "1000000", lt: "5000000" };
          objArr.push({ gte: 5000000, lt: 10000000 });
        }
        if (bud.includes("5M-10M")) {
          budObj = { ...budObj, gte: "5000000", lt: "10000000" };
          objArr.push({ gte: 5000000, lt: 10000000 });
        }

        if (bud.includes("Above 10M")) {
          budObj = { ...budObj, gte: "10000000" };
          objArr.push({ gte: 10000000 });
        }
        if (Object.keys(budObj).length > 0) {
          // searchObj.price = budObj;
        }
        if (objArr.length > 0) {
          // searchObj.rent = { or: objArr };
          // searchObj.or = objArr;
          searchObj.price = objArr;

          console.log("searchObj.price :>> ", { or: objArr });
        }
      }
    }
    searchObj.pageNo = this.state.pageNo;
    searchObj.itemsNo = this.state.itemsNo;
    console.log("searchObj :>> ", searchObj);
    var searchState = {
      searchLocality: this.state.searchLocality,
      searchDeal: this.state.searchDeal,
      // searchOfferType: this.state.searchOfferType,
      searchPropertyType: this.state.searchPropertyType,
      searchFurnishedStatus: this.state.searchFurnishedStatus,
      searchBhk: this.state.searchBhk,
      searchBudget: combinedBud, //this.state.searchBudget,
    };
    this.props.getPropsByObject(searchObj, searchState);
    this.toggleModal("searchOpen");
  }
  // componentDidUpdate() {
  //   console.log(
  //     "Object.keys(this.props.prop.searchState).length  :>> ",
  //     Object.keys(this.props.prop.searchState).length
  //   );
  //   if (Object.keys(this.props.prop.searchState).length > 0) {
  //     this.setState(this.props.prop.searchState);
  //   }
  // }
  componentDidMount() {
    const handle = this.props.match.params.id;
    this.setState({ pageNo: handle });
    console.log("this.state.pageNo", "handle", this.state.pageNo, handle);
    var defSearchObj = {
      pageNo: handle,
      itemsNo: this.state.itemsNo,
      cityName: {
        city: "Barrie",
      },
      property_type: {
        in: ["Single Family"],
      },
      bedroom: {
        in: [1],
      },
      price: [
        {
          lt: 5000000,
        },
      ],
    };
    console.log("defSearchObj", defSearchObj);
    this.props.getPropsByObject(defSearchObj);

    this.props.getAllConfig();
    this.props.getAllProps();
    this.props.getAuthToken();
    if (
      !this.props.auth.user.notifStatus ||
      this.props.auth.user.notifStatus === false
    )
      this.props.notifSubscriptionCheck(this.props.auth.user);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  toggleModal = (state) => {
    if (state === "searchOpen") {
      if (Object.keys(this.props.prop.searchState).length > 0) {
        this.setState({ ...this.state, ...this.props.prop.searchState }, () => {
          console.log("new state :>> ", this.state);
          this.setState({
            [state]: !this.state[state],
          });
        });
      } else {
        this.setState({
          [state]: !this.state[state],
        });
      }
    } else {
      this.setState({
        [state]: !this.state[state],
      });
    }
  };

  render() {
    const nextBtn = (
      <Button
        color="primary"
        size="lg"
        // style={{ marginLeft: "28px " }}
        type="button"
        className="next-cta"
        onClick={() => {
          var newPage = parseInt(this.state.pageNo) + 1;

          // this.props.history.push({
          //   pathname: "/search/" + newPage,
          // });
          window.location = `/search/${newPage}`;
          // window.location.href='/search';
        }}
      >
        Next
      </Button>
    );
    const prevBtn = (
      <Button
        color="primary"
        size="lg"
        // style={{ marginLeft: "28px " }}
        type="button"
        className="prev-cta"
        disabled={this.state.pageNo <= 1}
        onClick={() => {
          var newPage =
            this.state.pageNo > 1 ? parseInt(this.state.pageNo) - 1 : 1;

          // this.props.history.push({
          //   pathname: "/search/" + newPage,
          // });
          window.location = `/search/${newPage}`;
          // window.location.href='/search';
        }}
      >
        Previous
      </Button>
    );
    const no_property_found = (
      <>
        <Row
          className="mt-5"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Col sm="12" md="12" xl="12">
            <img
              className="mx-auto d-block pb-4"
              alt="..."
              src={require("./../../assets/img/illustrations/no-property-found.svg")}
              style={{ height: "auto", width: "229px" }}
            />
            <div style={{ color: "#4A5BEA" }}>
              No properties meeting your criteria.
            </div>
            <div style={{ color: "#4A5BEA" }}>
              Please change your criteria and search again.
            </div>
          </Col>
        </Row>
      </>
    );
    console.log("this.props", this.props);
    var { properties, loading } = this.props.prop;
    let rentPropertiesContent;
    let sellPropertiesContent;
    properties = properties ? properties.value : null;
    console.log("properties-array", properties);
    if (properties === null || loading) {
      rentPropertiesContent = <Spinner />;
      sellPropertiesContent = <Spinner />;
    } else {
      // Object.keys(properties)
      if (properties && properties.length > 0) {
        let default_pic = "images/fallback-property-image.svg";
        // p.purpose === "rent" &&
        // (p.is_active !== undefined
        //   ? p.is_active === "true"
        //     ? true
        //     : false
        //   : true) ?
        //rentPropertiesContent
        sellPropertiesContent = properties.map(
          (p, i) => (
            <Col sm="12" md="6" lg="6" xl="4" key={i}>
              <Property
                property_type={p.PropertySubType ? p.PropertySubType : ""}
                // floor_number={p.floor_number}
                //total_floor_number={p.total_floor_number}
                //land_area={p.LivingArea}
                purpose={p.TotalActualRent ? "Rent" : "Sell"}
                user="owner"
                id={p.ListingKey ? p.ListingKey : ""}
                area={p.LivingArea ? p.LivingArea : ""}
                name={p.UnparsedAddress ? p.UnparsedAddress : ""}
                price={p.ListPrice ? p.ListPrice : ""}
                rent={p.TotalActualRent ? p.TotalActualRent : ""}
                bedroom={p.BedroomsTotal ? p.BedroomsTotal : ""}
                bathroom={
                  p.BathroomsTotalInteger ? p.BathroomsTotalInteger : ""
                }
                place={p.UnparsedAddress ? p.UnparsedAddress : ""}
                city={p.City ? p.City : ""}
                remarks={p.PublicRemarks ? p.PublicRemarks : ""}
                // requested={p.requested}
                // inspection_status={p.inspection_status}
                //hero_image={p.gallery[0]}
                hero_image={
                  p.Media
                    ? p.Media[0].MediaURL
                    : // : Array.isArray(p.gallery) && p.gallery.length
                      // ? p.gallery[0] //this.getImage(p.gallery[0])
                      default_pic
                } //no++
              />
            </Col>
          )
          // : (
          //   <div key={i}></div>
          // )
        );
        // sellPropertiesContent = properties.map((p) =>
        //   p.purpose === "sell" &&
        //   (p.is_active !== undefined
        //     ? p.is_active === "true"
        //       ? true
        //       : false
        //     : true) ? (
        //     <Col sm="12" md="6" lg="6" xl="4">
        //       <Property
        //         property_type={p.PropertySubType}
        //         // floor_number={p.floor_number}
        //         //total_floor_number={p.total_floor_number}
        //         //land_area={p.LivingArea}
        //         purpose={p.TotalActualRent ? "Rent" : "Sell"}
        //         user="owner"
        //         id={p.ListingKey}
        //         area={p.LivingArea}
        //         name={p.UnparsedAddress}
        //         price={p.ListPrice}
        //         rent={p.TotalActualRent}
        //         bedroom={p.BedroomsTotal}
        //         bathroom={p.BathroomsTotalInteger}
        //         place={p.UnparsedAddress}
        //         city={p.City}
        //         remarks={p.remarks}
        //         requested={p.requested}
        //         inspection_status={p.inspection_status}
        //         //hero_image={p.gallery[0]}
        //         hero_image={
        //           p.Media
        //             ? p.Media[0]
        //             : // : Array.isArray(p.gallery) && p.gallery.length
        //               // ? p.gallery[0] //this.getImage(p.gallery[0])
        //               default_pic
        //         } //no++
        //       />
        //     </Col>
        //   ) : (
        //     <div></div>
        //   )
        // );
      } else {
        sellPropertiesContent = <Spinner />;
      }
    }
    const residentialBubbleRadio = (
      <BubbleRadio
        label="Property"
        option1={PropertyTypes.single_family}
        option2={PropertyTypes.vacant_land}
        def_options={this.state.searchPropertyType}
        //Array of options selected will reach here
        onOptionClick={(Deal) => {
          console.log("Deal==", Deal);
          this.setState({ searchPropertyType: Deal });
        }}
      />
    );
    const residentialBubbleRadioWithoutLand = (
      <BubbleRadio
        label="Property"
        option1={PropertyTypes.single_family}
        option2={PropertyTypes.vacant_land}
        def_options={this.state.searchPropertyType}
        //Array of options selected will reach here
        onOptionClick={(Deal) => {
          console.log("Deal==", Deal);
          this.setState({ searchPropertyType: Deal });
        }}
      />
    );

    const commercialBubbleRadio = (
      <BubbleRadio
        label="Property"
        option1={PropertyTypes.industrial}
        option2={PropertyTypes.agriculture}
        option3={PropertyTypes.retail}
        def_options={this.state.searchPropertyType}
        //Array of options selected will reach here
        onOptionClick={(Deal) => {
          console.log("Deal==", Deal);
          this.setState({ searchPropertyType: Deal });
        }}
      />
    );
    const rental_range = (
      <BubbleRadio
        label="Rental Range"
        option1="Below 15K"
        option2="15K-25K"
        option3="25K-40K"
        option4="Above 40K"
        def_options={this.state.rentBud} //this.state.searchBudget
        //Array of options selected will reach here
        onOptionClick={(budgetArr) => {
          console.log("budgetArr=", budgetArr);
          this.setState({ rentBud: budgetArr });
        }}
      />
    );
    const budget_range = (
      <BubbleRadio
        label="Budget Range"
        option1="Below 1M"
        option2="1M-5M"
        option3="5M-10M"
        option4="Above 10M"
        def_options={this.state.priceBud} //this.state.searchBudget
        //Array of options selected will reach here
        onOptionClick={(budgetArr) => {
          console.log("budgetArr=", budgetArr);
          this.setState({ priceBud: budgetArr });
        }}
      />
    );
    // const localities = this.props.admin.config.map((conf) => {});
    var locations = this.props.admin.config.locations;
    const locs = locations
      ? locations.includes(",")
        ? locations.split(",")
        : [locations]
      : ["Kalamassery"];

    const bedsBubble = (
      <BubbleRadioSingle
        label="Number of Bedrooms"
        option1="1 Bed"
        option2="2 Bed"
        option3="3 Bed"
        option4="3+ Bed"
        def_option={this.state.searchBhk}
        //Array of options selected will reach here
        onOptionClick={(bhkArr) => {
          console.log("bhkArr=", bhkArr);
          this.setState({ searchBhk: bhkArr });
        }}
      />
    );
    const furnishedBubble = (
      <BubbleRadio
        label="Furnished Status"
        option1="Fully Furnished"
        option2="Semi Furnished"
        option3="Not Furnished"
        def_options={this.state.searchFurnishedStatus}
        //Array of options selected will reach here
        onOptionClick={(furnishedArr) => {
          console.log("furnishedArr=", furnishedArr);
          this.setState({ searchFurnishedStatus: furnishedArr });
        }}
      />
    );
    return (
      <>
        <Navbar />

        <Modal
          className="modal-dialog-centered" //"modal-dialog-top"
          isOpen={this.state.searchOpen}
          toggle={() => this.toggleModal("searchOpen")}
        >
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.toggleModal("searchOpen")}
          >
            <span
              style={{ float: "right", padding: "2px 5px 0px 0px" }}
              aria-hidden="true"
            >
              ×
            </span>
          </button>
          <span
            className="d-block text-uppercase mt-3 mb-2 text-primary"
            style={{ textAlign: "center" }}
          >
            Search Your Property
          </span>
          {/* <small className="d-block text-uppercase mt-3 mb-2 text-primary">
            Location
          </small> */}
          <div className="modal-body px-3 py-1 mx-lg-5">
            <div className="pb-3">
              {/* <BubbleRadio
                label="Locality"
                option1="Kakkanad"
                option2="Idappilly"
                // option3="Decide Later"
                def_options={
                  this.state.searchLocality
                  // currProp === null
                  //   ? 1
                  //   : currProp.purpose === "rent"
                  //   ? 1
                  //   : currProp.purpose === "sell"
                  //   ? 2
                  //   : currProp.purpose === "later"
                  //   ? 3
                  //   : 1
                }
                //Array of options selected will reach here
                onOptionClick={(localityArr) => {
                  console.log("localityArr=", localityArr);
                  this.setState({ searchLocality: localityArr });
                }}
              /> */}
              {/* <BubbleRadioAuto
                label="Locality"
                optionArray={locs}
                // option3="Decide Later"
                def_options={
                  this.state.searchLocality
                  // currProp === null
                  //   ? 1
                  //   : currProp.purpose === "rent"
                  //   ? 1
                  //   : currProp.purpose === "sell"
                  //   ? 2
                  //   : currProp.purpose === "later"
                  //   ? 3
                  //   : 1
                }
                //Array of options selected will reach here
                onOptionClick={(localityArr) => {
                  console.log("localityArr=", localityArr);
                  this.setState({ searchLocality: localityArr });
                }}
              /> */}

              {/* <BubbleRadioSingle
                label="Deal"
                option1="Rental"
                option2="Sale"
                // option3="Decide Later"
                def_option={this.state.searchDeal}
                //Array of options selected will reach here
                onOptionClick={(Deal) => {
                  console.log("Deal==", Deal);
                  this.setState({ searchDeal: Deal });
                }}
              /> */}
              <small className="d-block text-uppercase mt-3 mb-2 text-primary">
                City
              </small>
              <div className="mb-3 ">
                <Autocomplete
                  countries={cities}
                  handleAutoComplete={this.handleAutoComplete}
                />
              </div>

              <BubbleRadioSingle
                label="Offer Type"
                option1="Residential"
                option2="Commmercial"
                // option3="Decide Later"
                def_option={this.state.searchOfferType}
                //Array of options selected will reach here
                onOptionClick={(OfferType) => {
                  console.log("OfferType=", OfferType);
                  this.setState({ searchOfferType: OfferType });
                }}
              />

              {this.state.searchOfferType === "Residential"
                ? this.state.searchDeal === "Sale" || this.state.plainTabs === 2
                  ? residentialBubbleRadio
                  : residentialBubbleRadioWithoutLand
                : commercialBubbleRadio}

              {/* this.state.searchPropertyType !== PropTypes.land || */}
              {this.state.searchOfferType === "Residential" &&
              !(
                this.state.searchPropertyType.includes(PropertyTypes.land) &&
                this.state.searchPropertyType.length === 1
              ) ? (
                <></> // furnishedBubble
              ) : (
                <></>
              )}
              {this.state.searchOfferType === "Residential" &&
              !this.state.searchPropertyType.includes(PropertyTypes.land) ? (
                bedsBubble
              ) : (
                <></>
              )}

              {/* {this.state.searchDeal === "Rental" || this.state.plainTabs === 1
                ? rental_range
                : budget_range} */}
              {/* {rental_range} */}
              {budget_range}
              <Button
                block
                onClick={() => this.searchSubmit()}
                className="mt-5 btn-round"
                color="primary"
                style={{
                  margin: "0px auto",
                  maxWidth: "80px",
                  padding: "5px 3px",
                  borderRadius: "5px",
                  background: "#4A5BEA",
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </Modal>

        <Container className="container-lg py-md" style={{ minHeight: "90vh" }}>
          <h3 className="text-primary mt-lg-5 mt-4 mb-3 mb-lg-4">
            {/* All Properties */}
          </h3>
          <Col className="nav-wrapper mt-2 mt-lg-2 pb-0 pb-lg-4" md="4">
            <Input
              className="mb-3 input_icon"
              placeholder="Search"
              style={{ cursor: "pointer" }}
              type="text"
              name="searchbox"
              value={"Search Your Property"}
              onChange={() => {
                this.toggleModal("searchOpen");
              }}
              onClick={() => {
                this.toggleModal("searchOpen");
              }}
              required
            />
            <Nav
              className="nav-fill flex-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              {/* <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 1,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  FOR RENT
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.plainTabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.plainTabs === 2,
                  })}
                  onClick={(e) => this.toggleNavs(e, "plainTabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  FOR SALE
                </NavLink>
              </NavItem> */}
            </Nav>
          </Col>
          <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
            <TabPane tabId="plainTabs1">
              <Row>
                {rentPropertiesContent != null
                  ? rentPropertiesContent
                  : no_property_found}
              </Row>
            </TabPane>
            <TabPane tabId="plainTabs2">
              <Row>{sellPropertiesContent}</Row>
            </TabPane>
          </TabContent>
          {nextBtn}
          {prevBtn}
          {/* <Row className="mb-2 mt-5">
            <nav
              aria-label="Page navigation example"
              style={{ margin: "0px auto" }}
            >
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-angle-left" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="active">
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    4
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    5
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fa fa-angle-right" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Row> */}
          {/* <a
            className="rounded-circle mb-4 bg-primary icon icon-shape shadow"
            style={{
              position: "fixed",
              bottom: "2%",
              right: "10%",
              height: "60px",
              width: "60px",
              zIndex: "2",
            }}
            href="/add-property"
          >
            <img
              className="fa white pb-1 pl-1"
              src={require("./../../assets/img/icons/common/add-property-icon.svg")}
              alt="add-property-icon"
            ></img>
          </a> */}
        </Container>

        <Footer />
      </>
    );
  }
}
Properties.propTypes = {
  getAllProps: PropTypes.func.isRequired,
  notifSubscriptionCheck: PropTypes.func,
  getAllConfig: PropTypes.func,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired,
  getPropsByObject: PropTypes.func,
  getAuthToken: PropTypes.func,
};

const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
  admin: state.admin,
});

export default connect(mapStateToProps, {
  getAllConfig,
  getAllProps,
  getPropsByObject,
  notifSubscriptionCheck,
  getAuthToken,
})(Properties);
