import "./Footer.scss";
import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    var address1 = "Barrie, ON";
    return (
      <>
        <section className="footer-wrapper section section-md">
          <div className="footer-landing container-fluid">
            <div className="company-links">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                {/* <li>
                  <a href="">Contact Us</a>
                </li>
                <li>
                  <a href="">Support</a>
                </li> */}
              </ul>
            </div>
            <div className="resources-links">
              <h5>Resources</h5>
              <ul>
                {/* <li>
                  <a href="">Documentation</a>
                </li> */}
                <li>
                  <a href="/policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms">Terms of Service</a>
                </li>
              </ul>
            </div>
            <div className="social-links">
              <h5>Social</h5>
              <ul className="social-item">
                <li>
                  <a href="">
                    <i class="fa fa-youtube social-icon  "></i>
                    Youtube
                  </a>
                </li>
                {/* <li>
                  <a href="">
                    <i class="fa fa-twitter social-icon  "></i>
                    Twitter
                  </a>
                </li>
                
                <li>
                  <a href="">
                    <i class="fa fa-instagram social-icon  "></i>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fa fa-facebook social-icon  "></i>
                    Facebook
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="igen-address">
              <h5>Stay Local LLP</h5>
              <div className="d-flex flex-row">
                <div className="d-inline align-self-center">
                  <i class="fa fa-map-marker pl-1 social-icon  "></i>
                </div>
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{ __html: address1 }}
                ></div>
              </div>
              <div className="my-2 my-md-0">
                <i class="fa fa-envelope social-icon  "></i>
                <a
                  className="text-primary"
                  href="mailto:info@igenproperties.in"
                >
                  info@staylocal.ca
                </a>
                <br />
              </div>
              <div>
                <i class="fa fa-phone social-icon  "></i>
                <a className="text-primary" href="tel:+12499891234">
                  +1 249 279 6060
                </a>
              </div>
            </div>
            <div className="copyright">© Copyright 2023 Stay Local INC</div>
          </div>
        </section>
      </>
    );
  }
}
