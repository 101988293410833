import axios from "../../client";

export const sendTest = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function(registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function(existedSubscription) {
            console.log("Existed subscription detected.");
            const userData = JSON.stringify(existedSubscription);

            return axios
              .post("/api/propNotifs/sendNotifTest", userData, {
                headers: { "Content-Type": "application/json" }
              })
              .then(res => {})
              .catch(err => {});
          });
      })
      .catch(function(e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
};

export const notifInspectionApprovedOwner = propInfo => {
  console.log("notifInspectionApprovedOwner :", propInfo);
  axios
    .post("/api/propNotifs/notifInspectionApprovedOwner", propInfo)
    .then(res => {
      console.log("notifInspectionApprovedOwner", res.data);
    })
    .catch(err => {});
};
export const notifPropertyActivated = propInfo => {
  console.log("notifPropertyActivated :", propInfo);
  axios
    .post("/api/propNotifs/notifPropertyActivated", propInfo)
    .then(res => {
      console.log("notifPropertyActivated", res.data);
      window.location.reload();
    })
    .catch(err => {});
};
export const notifInspectionReceivedOwner = propInfo => {
  console.log("notifInspectionReceivedOwner :", propInfo);
  axios
    .post("/api/propNotifs/notifInspectionReceivedOwner", propInfo)
    .then(res => {
      console.log("notifInspectionReceivedOwner", res.data);
    })
    .catch(err => {});
};
export const notifKeyReceivedOwner = propInfo => {
  console.log("notifKeyReceivedOwner :", propInfo);
  axios
    .post("/api/propNotifs/notifKeyReceivedOwner", propInfo)
    .then(res => {
      console.log("notifKeyReceivedOwner", res.data);
    })
    .catch(err => {});
};
export const notifPropRegister = propInfo => {
  console.log("notifPropRegister :", propInfo);
  axios
    .post("/api/propNotifs/notifPropRegister", propInfo)
    .then(res => {
      console.log("notifPropRegister", res.data);
    })
    .catch(err => {});
};
