import axios from "../client";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";

import { GET_ERRORS, SET_CURRENT_USER } from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - Get User Token
export const loginUser = (userData, url) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      window.location = url;
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const socialLogin = (userData, url) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
  axios
    .post("/api/users/socialLogin", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      window.location = url;
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("userWithRoleToken");
  localStorage.removeItem("userWithNotifSubscription");
  localStorage.removeItem("userWithUnreadNotif");
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.lcation = "/login-page";
};

// Register and Login User
export const registerLoginUser = (userData, url) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      dispatch(loginUser(userData, url));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
//update user role
export const updateUserRole = (userData) => (dispatch) => {
  axios
    .post("/api/users/updateRole", userData)
    .then((res) => {
      localStorage.setItem("userWithRoleToken", JSON.stringify(userData));
      dispatch(setCurrentUser(userData));

      // dispatch({
      //   type: CHANGE_USER_ROLE,
      //   payload: userData.role
      // });
    })
    .catch((err) => console.log(err));
};

export const updatePassword = (userData) => {
  axios
    .post("/api/users/updatePassword", userData)
    .then(async (res) => {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: "Password changed successfully",
      });
      window.location = "/login-page";
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to update password please try again!s",
      });
      console.log(err);
    });
};
export const forgotPswdPage = (userData) => {
  axios
    .post("/api/users/forgotPswdPage", userData)
    .then((res) => {
      console.log("mail triggered", res);
      if (res.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.success,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.error,
        });
      }
      return res.data;
    })
    .catch((err) => console.log(err));
};
export const updateUserProfile = (userData, hist) => (dispatch) => {
  axios
    .post("/api/users/updateUserProfile", userData)
    .then(async (res) => {
      // dispatch(setCurrentUser(userData));

      Swal.fire({
        title: "Continue with profile update?",
        text: "Clicking on Save will  save the changes and log you out.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.value) {
          dispatch(logoutUser());
          hist.push("/login-page");
        }
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const notifSubscriptionCheck = (userData) => (dispatch) => {
  axios
    .get("/api/users/notifSubscriptionCheck", {
      params: {
        user_id: userData.id,
      },
    })
    .then((res) => {
      console.log("notifSubscriptionCheck res", res);
      const newUser = {
        ...userData,
        notifStatus: res.data.notif_status,
      };
      localStorage.setItem(
        "userWithNotifSubscription",
        JSON.stringify(newUser)
      );
      dispatch(setCurrentUser(userData));
    })
    .catch((err) => {
      console.log("err notifSubscriptionCheck", err);
    });
};
export const updateUnreadNotifs = (userData) => (dispatch) => {
  axios
    .get("/api/users/updateUnreadNotif", {
      params: {
        user_id: userData.id,
      },
    })
    .then((res) => {
      console.log("updateUnreadNotif res", res);
      // const newUser = {
      //   ...userData,
      //   unread_notifs: res.data.unread_notifs,
      // };
      // localStorage.setItem("userWithUnreadNotif", JSON.stringify(newUser));
      // dispatch(setCurrentUser(userData));
    })
    .catch((err) => {
      console.log("err userWithUnreadNotif", err);
    });
};
export const emptyUnreadNotifs = (userData) => (dispatch) => {
  axios
    .get("/api/users/emptyUnreadNotif", {
      params: {
        user_id: userData.id,
      },
    })
    .then((res) => {
      console.log("emptyUnreadNotif res", res);
      const newUser = {
        ...userData,
        unread_notifs: res.data.unread_notifs,
      };
      localStorage.setItem("userWithUnreadNotif", JSON.stringify(newUser));
      dispatch(setCurrentUser(newUser));
    })
    .catch((err) => {
      console.log("err userWithUnreadNotif", err);
    });
};
export const syncUnreadNotifs = (userData) => (dispatch) => {
  axios
    .get("/api/users/syncUnreadNotif", {
      params: {
        user_id: userData.id,
      },
    })
    .then((res) => {
      console.log("syncUnreadNotif res", res);
      const newUser = {
        ...userData,
        unread_notifs: res.data.unread_notifs,
      };
      localStorage.setItem("userWithUnreadNotif", JSON.stringify(newUser));
      dispatch(setCurrentUser(newUser));
    })
    .catch((err) => {
      console.log("err userWithUnreadNotif", err);
    });
};
