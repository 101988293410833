import axios from "../../client";
export const mailPropRegister = (newPropInfo) => {
  axios
    .post("/api/propMails/mailPropRegister", newPropInfo)
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {});
};
export const mailInspectionApprovedOwner = (propInfo) => {
  console.log("mailInspectionApprovedOwner :", propInfo);
  axios
    .post("/api/propMails/mailInspectionApprovedOwner", propInfo)
    .then((res) => {
      console.log("mailInspectionApprovedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailPropertyActivated = (propInfo) => {
  console.log("mailPropertyActivated :", propInfo);
  axios
    .post("/api/propMails/mailPropertyActivated", propInfo)
    .then((res) => {
      console.log("mailPropertyActivated", res.data);
      window.location.reload();
    })
    .catch((err) => {});
};
export const mailInspectionReceivedOwner = (propInfo) => {
  console.log("mailInspectionReceivedOwner :", propInfo);
  axios
    .post("/api/propMails/mailInspectionReceivedOwner", propInfo)
    .then((res) => {
      console.log("mailInspectionReceivedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailKeyReceivedOwner = (propInfo) => {
  console.log("mailKeyReceivedOwner :", propInfo);
  axios
    .post("/api/propMails/mailKeyReceivedOwner", propInfo)
    .then((res) => {
      console.log("mailKeyReceivedOwner", res.data);
    })
    .catch((err) => {});
};
export const mailSendEnq = (enqData) => {
  console.log("mailSendEnq :", enqData);
  axios
    .post("/api/propMails/mailSendEnq", enqData)
    .then((res) => {
      console.log("mailSendEnq", res.data);
    })
    .catch((err) => {});
};
