import React, { Component } from "react";
import { FormGroup, Input, Button, Row, Col, Form, Modal } from "reactstrap";
import MultiStepProgressBar from "components/Navbars/MultiStepProgressBar/MultiStepProgressBar";
import PhoneInputnew from "components/Inputs/PhoneInput/PhoneInput";
import { saveNewPropOwner } from "../../actions/propActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import { mailPropRegister } from "actions/mailActions/propMails";
import { notifPropRegister } from "actions/notifActions/propNotifs";
import { mailInspectionReceivedOwner } from "actions/mailActions/propMails";
import { notifInspectionReceivedOwner } from "actions/notifActions/propNotifs";
import { saveNewPropKeyHolder } from "../../actions/propActions";
import { toggleRegPropOwner } from "../../actions/propActions";
import { registerProp } from "../../actions/propActions";
import NotificationRequestPopup from "components/Modals/NotificationRequestPopup/NotificationRequestPopup";
import { subscribeUser } from "../../notifications/subscription";
import SignupCard from "components/Cards/SignupCard";
import LoginCard from "components/Cards/LoginCard";

import { updateUserRole } from "../../actions/authActions";

class OwnerDetails extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      privacy_policy: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.prop.new_prop_owner != null) {
      this.setState(this.props.prop.new_prop_owner);
    } else if (this.props.auth.isAuthenticated) {
      this.setState(this.props.auth.user, () => {
        this.setState({ phone: "+91" + this.state.phone });
      });
    }
    if (this.state.phone) {
      this.setState({ phone: "+91" + this.state.phone });
    }
    console.log("this.state", this.state);
  }
  onChange(e) {
    if (e.target.name === "privacy_policy") {
      this.setState({ privacy_policy: !this.state.privacy_policy });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (
      isPossiblePhoneNumber(this.state.phone) &&
      !this.state.phone.match(phoneno)
    ) {
      //isValidPhoneNumber(this.state.phone)
      const ownerInfo = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
      };
      console.log("submitted", ownerInfo);
      const new_prop = this.props.prop.new_prop_info;
      console.log(`new_prop.property_type`, new_prop.property_type);

      // If property Type is land, key holder details need not be taken
      if (new_prop.property_type === "Land") {
        const new_prop = this.props.prop.new_prop_info;
        const new_owner = ownerInfo;

        const newProp = {
          name: new_prop.prop_name,
          area: new_prop.area,
          land_area: new_prop.land_area,
          city: new_prop.city,
          locality: new_prop.locality,
          address: new_prop.address,
          furnishing: new_prop.furnish,
          bedroom: new_prop.bedrooms,
          bathroom: new_prop.bathrooms,
          parking: new_prop.parking,
          purpose: new_prop.purpose,
          available_from: new_prop.available_from,
          available_for: new_prop.available_for,
          rent_amount: new_prop.rent_amount,
          maintenance_amount: new_prop.maintenance_amount,
          pic_names: new_prop.pic_names,
          pic_urls: new_prop.pic_urls,
          remarks: new_prop.remarks,
          price: new_prop.price,
          is_active: new_prop.is_active,
          is_rented_out: new_prop.is_rented_out,
          is_sold: new_prop.is_sold,
          inspection_status: new_prop.inspection_status,

          property_type: new_prop.property_type,
          landmark: new_prop.landmark,
          water_source: new_prop.water_source,
          floor_number: new_prop.floor_number,
          parking_number: new_prop.parking_number,
          total_floor_number: new_prop.total_floor_number,
          offer_type: this.state.offer_type,

          // owner_id:this.props.prop.new_prop_owner.owner_id,
          owner_id: this.props.auth.user.id,
          owner_name: new_owner.name,
          owner_email: new_owner.email,
          owner_phone: new_owner.phone,

          dateTime: new Date(Date.now()),
        };
        this.props.registerProp(newProp);
        //mail admin: new property created
        mailPropRegister(newProp);
        notifPropRegister(newProp);
        //mail to owner: property submitted for inspection
        mailInspectionReceivedOwner(newProp);
        notifInspectionReceivedOwner(newProp);

        // change user role to owner
        const roleChange = {
          ...this.props.auth.user,
          role: "owner",
        };
        this.props.updateUserRole(roleChange);
        // this.props.onProceed();

        if (!this.props.auth.user.notifStatus) {
          this.toggleModal("notifReq");
        } else {
          this.props.onProceed();
        }
      } else {
        this.props.saveNewPropOwner(ownerInfo);
        this.props.onProceed();
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid Phone Number!",
        text: "Please enter a Valid Phone number!",
      });
    }
  }
  render() {
    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <MultiStepProgressBar step="2" />
          <h3 className="text-black mt-3 mt-lg-4 mb-3 mb-lg-4 text-center">
            Personal Details
          </h3>
          <Row>
            <Col
              sm="12"
              md="6"
              lg="6"
              className="mt-3"
              style={{ margin: "0px auto" }}
            >
              <FormGroup>
                <Input
                  className="mb-3"
                  placeholder="Your Name"
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  required
                />
                <Input
                  className="my-3"
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  required
                />
                <PhoneInputnew
                  onNumberChange={(value) => {
                    this.setState({ phone: value });
                    console.log("phone no", value);
                  }}
                  def_phone={this.state.phone}
                />
                <div className="custom-control custom-checkbox mt-4 mb-2 d-inline-block">
                  <input
                    className="custom-control-input"
                    id="customCheck1"
                    type="checkbox"
                    name="privacy_policy"
                    onChange={this.onChange}
                    checked={this.state.privacy_policy}
                  />
                  <label
                    className="custom-control-label pl-2"
                    htmlFor="customCheck1"
                  >
                    <span>
                      I agree to the iGen<a href="/terms">Terms & Conditions</a>
                    </span>
                  </label>
                </div>
                <div className="d-flex flex-row">
                  <div className="d-flex flex-grow-1 ml-2 mr-3">
                    <Button
                      className="mt-5 btn-round btn-outline-primary"
                      type="submit"
                      onClick={() => this.props.onBack()}
                      style={{
                        margin: "0px auto",
                        minWidth: "140px",
                        width: "100%",
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="d-flex flex-grow-1 mx-3">
                    <Button
                      className="mt-5 btn-round"
                      color="primary"
                      type="submit"
                      style={{
                        margin: "0px auto",
                        minWidth: "140px",
                        width: "100%",
                      }}
                      disabled={!this.state.privacy_policy}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </FormGroup>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
              >
                <SignupCard
                  onDone={() => {
                    this.toggleModal("defaultModal");
                  }}
                  onLoginClick={() => {
                    this.toggleModal("logModal");
                    this.toggleModal("defaultModal");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.logModal}
                toggle={() => this.toggleModal("logModal")}
              >
                <LoginCard
                  onDone={() => {
                    setTimeout(() => {
                      if (this.props.auth.isAuthenticated) {
                        this.toggleModal("logModal");
                      }
                    }, 1500);
                  }}
                  onSignupClick={() => {
                    this.toggleModal("defaultModal");
                    this.toggleModal("logModal");
                  }}
                />
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.notifReq}
                toggle={() => this.toggleModal("notifReq")}
                style={{ zIndex: 100 }}
              >
                <NotificationRequestPopup
                  clickAction={() => {
                    subscribeUser(this.props.auth.user.id);
                    this.toggleModal("notifReq");
                  }}
                  clickAction2={() => {
                    this.toggleModal("notifReq");
                    window.location = "/owner-dashboard";
                  }}
                />
              </Modal>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prop: state.prop,
  errors: state.errors,
  auth: state.auth,
});
OwnerDetails.propTypes = {
  saveNewPropOwner: PropTypes.func,
  saveNewPropKeyHolder: PropTypes.func,
  updateUserRole: PropTypes.func,
  registerProp: PropTypes.func,
  toggleRegPropOwner: PropTypes.func,
};
export default connect(mapStateToProps, {
  saveNewPropKeyHolder,
  registerProp,
  toggleRegPropOwner,
  updateUserRole,
  saveNewPropOwner,
})(OwnerDetails);
