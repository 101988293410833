import {
  BOOK_PROP_VISIT,
  SAVE_VISIT_DATE,
  SAVE_VISIT_USER,
  SAVE_VISIT_TIME,
  GET_TENANT_DASHS,
  GET_OWNER_VISITS,
  VISIT_LOADING
} from "../actions/types";

const initialState = {
  bookVisit: false,
  visitDate: null,
  visitTime: null,
  visitUser: null,
  loading: false,
  ownerVisits: [],
  visitDashs: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BOOK_PROP_VISIT:
      return {
        ...state,
        bookVisit: action.payload
      };
    case SAVE_VISIT_DATE:
      return {
        ...state,
        visitDate: action.payload
      };
    case SAVE_VISIT_TIME:
      return {
        ...state,
        visitTime: action.payload
      };
    case SAVE_VISIT_USER:
      return {
        ...state,
        visitUser: action.payload
      };
    case VISIT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TENANT_DASHS:
      return {
        ...state,
        visitDashs: action.payload,
        loading: false
      };
    case GET_OWNER_VISITS:
      return {
        ...state,
        ownerVisits: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
