import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { syncUnreadNotifs } from "../../actions/authActions";
import Logo from "./Logo/logo";

class DarkNavbar extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    this.props.syncUnreadNotifs(this.props.auth.user);
  }

  constructor(props) {
    super(props);
    this.state = { showPopup: false, closeMenu: null };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
      closeMenu: true,
    });
  }

  render() {
    let showhide = this.state.closeMenu ? "hide" : "";
    const { isAuthenticated, user } = this.props.auth;
    const authLinks = (
      <Nav className="navbar-nav-hover ml-lg-auto" navbar>
        <NavItem>
          <NavLink href="/pm">Home</NavLink>
        </NavItem>
        {/* <NavItem>
              <NavLink href="#pablo" onClick={e => e.preventDefault()}>
                About
              </NavLink>
            </NavItem> */}
        <NavItem>
          <NavLink href="/pm-dashboard">Pm Dashboard</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/visit-requests">Visit Requests</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/inspection-requests">Inspection Requests</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="nav-link-icon"
            href="notifications"
            id="tooltip356693867"
          >
            <div className="d-lg-flex" style={{ minWidth: "5px" }}>
              <i className="fa fa-bell desktoponly mt-1" />
              <span className="d-lg-none">Notifications</span>
              {this.props.auth.user.unread_notifs &&
              this.props.auth.user.unread_notifs !== "0" ? (
                <span
                  className="d-inline-block floating-sm ml-2 ml-lg-0"
                  style={{
                    marginTop: "-10px",
                    height: "20px",
                    width: "20px",
                    fontSize: "13px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "0px 6px",
                  }}
                >
                  {this.props.auth.user.unread_notifs}
                </span>
              ) : null}
            </div>
          </NavLink>
          <UncontrolledTooltip delay={0} target="tooltip356693867">
            See your notifications
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/add-property"
            //onClick={e => e.preventDefault()}
          >
            <Button
              className="btn-icon btn-neutral btn-round"
              color="#4a5bea"
              type="button"
              style={{
                backgroundColor: "#4a5bea",
              }}
            >
              <span className="btn-inner--icon">
                <img
                  className="fa white pb-1 pl-1"
                  src={require("./../../assets/img/icons/common/add-property-icon.svg")}
                  alt="add-property-icon"
                ></img>
                {/* <i className="fa fa-home mr-0" /> */}
              </span>
              {/* <span className="btn-inner--text">Post A Property</span> */}
            </Button>
          </NavLink>
        </NavItem>
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <i className="fa fa-user mr-2" />
            <span className="nav-link-inner--text">
              {this.props.auth.user.name}
            </span>
            <img
              className="fa ml-2"
              src={require("./arrow-down.svg")}
              alt="furnishing-icon"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="mt-2 ml-4" to="/profile" tag={Link}>
              Profile
            </DropdownItem>
            <DropdownItem className="ml-1" to="" tag={Link}>
              <Button
                className="btn-round btn-mb-black"
                color="transparent"
                type="link"
                onClick={this.onLogoutClick.bind(this)}
              >
                Logout
              </Button>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
    const guestLinks = (
      <Nav className="navbar-nav-hover ml-lg-auto" navbar>
        <NavItem>
          <NavLink href="/pm">Home</NavLink>
        </NavItem>
        {/* <NavItem>
              <NavLink href="#pablo" onClick={e => e.preventDefault()}>
                About
              </NavLink>
            </NavItem> */}
        <NavItem>
          <NavLink href="/visit-requests">
            <div className=" d-xs-none d-lg-flex" style={{ minWidth: "5px" }}>
              Visit Requests
              {this.props.auth.user.unread_notifs &&
              this.props.auth.user.unread_notifs !== "0" ? (
                <span
                  className="d-inline-block floating-sm"
                  style={{
                    marginTop: "-10px",
                    height: "20px",
                    width: "20px",
                    fontSize: "13px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "0px 6px",
                  }}
                >
                  {this.props.auth.user.unread_notifs}
                </span>
              ) : null}
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/inspection-requests">Inspection Requests</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/add-property"
            // onClick={e => e.preventDefault()}
          >
            <Button
              className="btn-icon btn-neutral btn-round"
              style={{
                backgroundColor: "#4a5bea",
              }}
              color="primary"
              type="button"
            >
              <span className="btn-inner--icon">
                <i className="fa fa-home mr-0" />
              </span>
              <span className="btn-inner--text">Post A Property</span>
            </Button>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              padding: "5px 5px 0px 5px",
              textAlign: "center",
            }}
            href="/login-page"
            // onClick={this.togglePopup.bind(this)}
          >
            <Button
              className="btn-round btn-mb-black"
              color="white"
              outline
              type="button"
            >
              Login/SignUp
            </Button>
          </NavLink>
        </NavItem>
      </Nav>
    );
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-dark bg-primary"
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand href="/">
                <Logo />
              </NavbarBrand>
              <NavLink
                className="nav-link-icon text-white"
                id="tooltip356693867"
                target="_blank"
                onClick={() => {
                  window.location = "/notifications";
                }}
              >
                <div
                  className="notification-icon d-lg-none"
                  style={{ display: "flex", maxWidth: "2px" }}
                >
                  <i className="fa fa-bell mt-1 display-inline" />
                  {this.props.auth.user.unread_notifs &&
                  this.props.auth.user.unread_notifs !== "0" ? (
                    <span
                      className="d-inline-block floating-sm"
                      style={{
                        marginTop: "-10px",
                        height: "20px",
                        width: "20px",
                        fontSize: "13px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        padding: "0px 6px",
                      }}
                    >
                      {this.props.auth.user.unread_notifs}
                    </span>
                  ) : null}
                </div>
              </NavLink>
              <button className="navbar-toggler" id="navbar-primary">
                <span className="navbar-toggler-icon" />
              </button>

              <UncontrolledCollapse
                navbar
                toggler="#navbar-primary"
                className={showhide}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("./Logo/logo_primary.svg")}
                          style={{ height: "auto", width: "229px" }}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar-primary">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                {isAuthenticated ? authLinks : guestLinks}
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          {/* Navbar success */}
        </header>
        {/* {this.state.showPopup ? (
          <div>
            <SignupModal />
          </div>
        ) : null} */}
      </>
    );
  }
}

DarkNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  syncUnreadNotifs: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, syncUnreadNotifs })(
  DarkNavbar
);
