import React, { Component } from "react";
import { Button, Row, Modal } from "reactstrap";
import InfoWithCta from "components/Modals/InfoWithCta";
import {
  changeDepositReceived,
  changeConsideration,
  deleteVisit,
} from "../../actions/visitActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  mailPaymentRequest,
  mailDepositReceivedTenant,
  mailDepositReceivedOwner,
  mailChangeConsiderationTenant,
  mailChangeConsiderationOwner,
} from "../../actions/mailActions/visitMails";
import {
  notifPaymentRequest,
  notifDepositReceivedTenant,
  notifDepositReceivedOwner,
  notifChangeConsiderationTenant,
  notifChangeConsiderationOwner,
} from "../../actions/notifActions/visitNotifs";
import getCurrentDate from "../../utils/getCurrentDate";
import Swal from "sweetalert2";

class VisitRequestCardComplete extends Component {
  constructor() {
    super();
    this.state = {
      success: true,
      deposit: "",
      consideration: "",
      pr_date: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  onChange(e) {
    if (e.target.name === "deposit") {
      this.setState({ deposit: !this.state.deposit });
      this.setState({ success: this.state.deposit });
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
        console.log("consideration :", this.state.consideration);
        this.props.changeConsideration({
          id: this.props.visit_id,
          consideration: this.state.consideration,
        });
        const mail_content = {
          visit_id: this.props.visit_id,
          prop_id: this.props.prop_id,
          owner_name: this.props.owner_name,
          owner_phone: this.props.owner_phone,
          owner_email: this.props.owner_email,
          tenant_id: this.props.tenant_id,
          owner_id: this.props.owner_id,
          ten_name: this.props.tenant,
          prop_name: this.props.propName,
          time: this.props.timeSlot,
          date: this.props.bookDate,
          consideration: this.state.consideration,
          dateTime: new Date(Date.now()),
        };
        console.log("mail_content :", mail_content);
        mailChangeConsiderationTenant(mail_content);
        mailChangeConsiderationOwner(mail_content);
        notifChangeConsiderationTenant(mail_content);
        notifChangeConsiderationOwner(mail_content);
      });
    }
  }
  componentDidMount() {
    //pre check the deposit check box if deposit received
    this.setState({
      success: this.state.deposit,
      deposit: this.props.deposit_received,
      consideration: this.props.consideration,
      pr_date: this.props.pr_date,
    });
  }
  render() {
    const kh_det = (
      <>
        <div className="mb-2">
          <div className="d-block mt-1 text-muted">
            <small className="text-uppercase">Keyholder Name</small>
          </div>
          <div className="d-block">{this.props.kh_name}</div>
        </div>
        <div className="mb-2">
          <div className="d-block mt-1 text-muted">
            <small className="text-uppercase">Keyholder Phone</small>
          </div>
          <div className="d-block">{this.props.kh_phone}</div>
        </div>
      </>
    );
    return (
      <>
        <div
          className="shadow-sm shadow-lg--hover rounded my-2"
          style={{ padding: "20px 40px" }}
        >
          <div className="my-2">
            <row>
              <Button
                className="btn-icon btn-neutral btn-round  my-2 mr-1"
                color="primary"
                type="button"
                style={{ float: "right" }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "This visit request will be Deleted permanantly!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      // console.log("this.props", this.props.prop.property._id);
                      var dat1 = {
                        _id: this.props.visit_id,
                      };

                      deleteVisit(dat1);
                    }
                  });
                }}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-trash mr-0" />
                </span>
                {/* <span className="btn-inner--text">Delete Property</span> */}
              </Button>
            </row>
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Property Name</small>
            </div>
            <div className="d-block">{this.props.propName}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Tenant Name</small>
            </div>
            <div className="d-block">{this.props.tenant}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Mobile Number</small>
            </div>
            <div className="d-block">{this.props.tenantMobile}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Date of Visit</small>
            </div>
            <div className="d-block">{this.props.bookDate}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Name</small>
            </div>
            <div className="d-block">{this.props.owner_name}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Phone</small>
            </div>
            <div className="d-block">{this.props.owner_phone}</div>
          </div>
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Owner Email</small>
            </div>
            <div className="d-block">{this.props.owner_email}</div>
          </div>
          {this.props.kh_name || this.props.kh_phone ? kh_det : ""}
          <div className="mb-2">
            <div className="d-block mt-1 text-muted">
              <small className="text-uppercase">Status</small>
            </div>
            <div className="d-block text-uppercase">
              <form action="">
                <div>
                  <select
                    name="consideration"
                    style={{
                      color: "white",
                      height: "45px",
                      background: "#4a5bea",
                      padding: "10px 10px",
                    }}
                    onChange={this.onChange}
                  >
                    <option
                      selected={this.state.consideration === "no_response"}
                      value="no_response"
                    >
                      No Response
                    </option>
                    <option
                      selected={this.state.consideration === "confirmed"}
                      value="confirmed"
                    >
                      Confirmed
                    </option>
                    <option
                      selected={this.state.consideration === "considering"}
                      value="considering"
                    >
                      Considering
                    </option>
                    <option
                      selected={this.state.consideration === "not_considering"}
                      value="not_considering"
                    >
                      Not Considering
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-4 mb-2">
            <Row>
              <Button
                block
                className="btn-round"
                color="primary"
                outline
                type="button"
                style={{
                  margin: "0px auto",
                  padding: "10px 12px",
                  fontSize: "0.7em",
                }}
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text:
                      "The mail requesting advance payment will be sent to the tenant. ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      const mail_content = {
                        visit_id: this.props.visit_id,
                        prop_id: this.props.prop_id,
                        owner_name: this.props.owner_name,
                        owner_phone: this.props.owner_phone,
                        owner_email: this.props.owner_email,
                        tenant_id: this.props.tenant_id,
                        owner_id: this.props.owner_id,
                        ten_name: this.props.tenant,
                        prop_name: this.props.propName,
                        time: this.props.timeSlot,
                        date: this.props.bookDate,
                        pr_date: getCurrentDate(),
                        dateTime: new Date(Date.now()),
                      };
                      mailPaymentRequest(mail_content);
                      notifPaymentRequest(mail_content);

                      Swal.fire(
                        "Mail sent!",
                        "Payment request sent to tenant.",
                        "success"
                      );
                      this.setState({ pr_date: getCurrentDate() });
                    } else {
                      Swal.fire(
                        "Mail not sent!",
                        "Please try again !",
                        "error"
                      );
                    }
                  });
                }}
              >
                Request Payment
              </Button>
            </Row>
            {this.state.pr_date && this.state.pr_date !== "null" && (
              <div className="text-warning text-center">
                <small>{this.state.pr_date}</small>
              </div>
            )}
            <Row>
              {/* When the checkbox is clicked on, the confirmation popup should appear. Upon selecting yes, the checkbox should be locked into yes. */}
              <label className="custom-toggle my-4">
                <input
                  type="checkbox"
                  name="deposit"
                  onClick={() => this.toggleModal("success")}
                  // onChange={this.onChange}
                  checked={this.state.deposit}
                  disabled={this.props.deposit_received}
                />

                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <span className="ml-3 mt-4">Received Deposit</span>
            </Row>
          </div>
        </div>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.success}
          toggle={() => this.toggleModal("success")}
        >
          <div className="modal-header border-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("success")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <InfoWithCta
            icon="fa-check-circle"
            title="Are you Sure ?"
            cta="YES"
            action2="NO"
            clickAction={() => {
              this.setState({ success: true, deposit: true }, () => {
                this.props.changeDepositReceived({
                  id: this.props.visit_id,
                  deposit_received: this.state.deposit,
                });
                const mail_content = {
                  prop_id: this.props.prop_id,
                  owner_name: this.props.owner_name,
                  owner_phone: this.props.owner_phone,
                  owner_email: this.props.owner_email,
                  visit_id: this.props.visit_id,
                  tenant_id: this.props.tenant_id,
                  owner_id: this.props.owner_id,
                  ten_name: this.props.tenant,
                  prop_name: this.props.propName,
                  time: this.props.timeSlot,
                  date: this.props.bookDate,
                  pr_date: getCurrentDate(),
                  dateTime: new Date(Date.now()),
                };
                mailDepositReceivedTenant(mail_content);
                mailDepositReceivedOwner(mail_content);
                notifDepositReceivedTenant(mail_content);
                notifDepositReceivedOwner(mail_content);
                console.log("mailDepositReceived mail content :", mail_content);
              });

              this.toggleModal("success");
            }}
            clickAction2={() => {
              this.setState({ success: false, deposit: false });
              this.toggleModal("success");
            }}
          >
            <p>
              This action cannot be undone.
              <br /> Only choose the YES option if you have received the payment
              from the tenant !
            </p>
          </InfoWithCta>
        </Modal>
      </>
    );
  }
}
VisitRequestCardComplete.propTypes = {
  changeConsideration: PropTypes.func,
  changeDepositReceived: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  visit: state.visit,
});

export default connect(mapStateToProps, {
  changeConsideration,
  changeDepositReceived,
})(VisitRequestCardComplete);
