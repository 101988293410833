import axios from "../../client";
import { GET_NOTIFS, NOTIF_LOADING } from "../types";
export const getNotifsById = (user_id) => (dispatch) => {
  dispatch(setNotifLoading());

  axios
    .get("/api/notifications/allById", {
      params: {
        user_id: user_id,
      },
    })
    .then((res) =>
      dispatch({
        type: GET_NOTIFS,
        payload: res.data,
      })
    )
    .catch((err) => console.log("err", err));
};

export const setNotifLoading = () => {
  return {
    type: NOTIF_LOADING,
  };
};

export const updateNotif = (id, user_id, notifData) => (dispatch) => {
  axios
    .put(`/api/notifications/update_notif/${id}`, notifData)
    .then((res) => {
      dispatch(getNotifsById(user_id));
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const clearAllNotif = (ids, user_id, notifData) => (dispatch) => {
  var lp = new Promise((resolve, reject) => {
    Array.from(ids).forEach((id, index, array) => {
      axios
        .put(`/api/notifications/update_notif/${id}`, notifData)
        .then((res) => {})
        .catch((err) => {
          console.log("err", err);
        });
      if (index === array.length - 1) resolve();
    });
  });
  lp.then(() => {
    dispatch(getNotifsById(user_id));
  });
};
