import {
  GET_PROP,
  GET_PROPS,
  PROP_LOADING,
  CLEAR_CURRENT_PROP,
  SAVE_NEW_PROP_INFO,
  SAVE_NEW_PROP_OWNER,
  SAVE_NEW_PROP_KEY_HOLDER,
  REGISTER_NEW_PROP_OWNER,
  GET_OWNER_PROPS,
  GET_PROP_DASHS,
  GET_F_PROPS,
  SET_SEARCH_OBJECT,
} from "../actions/types";

const initialState = {
  properties: null,
  featured_props: null,
  owner_properties: null,
  loading: false,
  property: null,
  new_prop_info: null,
  new_prop_owner: null,
  new_prop_key_holder: null,
  reg_new_prop_owner: false,
  prop_dashs: [],
  searchState: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROP:
      return {
        ...state,
        property: action.payload,
        loading: false,
      };
    case GET_PROPS:
      return {
        ...state,
        properties: action.payload,
        loading: false,
      };
    case GET_OWNER_PROPS:
      return {
        ...state,
        owner_properties: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT_PROP:
      return {
        ...state,
        properties: null,
      };
    case SAVE_NEW_PROP_INFO:
      return {
        ...state,
        new_prop_info: action.payload,
      };
    case SAVE_NEW_PROP_OWNER:
      return {
        ...state,
        new_prop_owner: action.payload,
      };
    case SAVE_NEW_PROP_KEY_HOLDER:
      return {
        ...state,
        new_prop_key_holder: action.payload,
      };
    case REGISTER_NEW_PROP_OWNER:
      return {
        ...state,
        reg_new_prop_owner: action.payload,
      };
    case GET_PROP_DASHS:
      return {
        ...state,
        prop_dashs: action.payload,
        loading: false,
      };
    case GET_F_PROPS:
      return {
        ...state,
        featured_props: action.payload,
        loading: false,
      };
    case SET_SEARCH_OBJECT:
      return {
        ...state,
        searchState: action.payload,
      };
    default:
      return state;
  }
}
