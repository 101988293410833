import React, { useState } from "react";

const Autocomplete = (props) => {
  const items = props.countries; // This could be a GET request to whatever

  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: "Barrie",
  });

  const handleChange = (e) => {
    const inputValue = e.currentTarget.value;
    const filteredItems = items.filter(
      (optionName) =>
        optionName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

    setState({
      activeItem: 0,
      filteredItems,
      displayItems: true,
      inputValue: e.currentTarget.value,
    });
  };

  const handleClick = (e) => {
    setState({
      activeItem: 0,
      filteredItems: [],
      displayItems: false,
      inputValue: e.currentTarget.innerText,
    });
    props.handleAutoComplete(e);
  };

  const handleKeyDown = (e) => {
    const { activeItem, filteredItems } = state;

    if (e.keyCode === 13) {
      setState({
        activeItem: 0,
        filteredItems: [],
        displayItems: false,
        inputValue: filteredItems[activeItem],
      });
    } else if (e.keyCode === 38) {
      e.preventDefault();
      if (activeItem === 0) {
        return;
      }
      setState({
        activeItem: activeItem - 1,
        filteredItems,
        displayItems: true,
        inputValue: e.currentTarget.value,
      });
    } else if (e.keyCode === 40) {
      e.preventDefault();
      if (
        (filteredItems && activeItem === filteredItems.length - 1) ||
        activeItem >= 9
      ) {
        return;
      }
      setState({
        activeItem: activeItem + 1,
        filteredItems,
        displayItems: true,
        inputValue: e.currentTarget.value,
      });
    }
  };

  return (
    <>
      <div className="uk-inline uk-width-1-1 uk-margin-top">
        <span className="uk-form-icon" data-uk-icon="icon: world" />
        <input
          name="languages"
          label="Items"
          placeholder="City"
          className="uk-input uk-form-large uk-width-expand"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={state.inputValue}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
        />

        {state.displayItems &&
        state.inputValue.length &&
        state.filteredItems ? (
          <div className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
            <ul className="uk-list">
              {state.filteredItems
                .map((optionName, index) => {
                  return (
                    <li
                      className={`${
                        state.activeItem === index
                          ? "active-item"
                          : "default-item"
                      }`}
                      key={optionName}
                      onClick={handleClick}
                    >
                      {optionName}
                    </li>
                  );
                })
                .slice(0, 10)}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Autocomplete;
