import React, { Component } from "react";
import InfoWithCta from "components/Modals/InfoWithCta";
import MultiStepProgressBar from "components/Navbars/MultiStepProgressBar/MultiStepProgressBar";
import { Row, Col } from "reactstrap";

export default class AddPropSuccess extends Component {
  render() {
    return (
      <>
        <MultiStepProgressBar step="4" />
        <Row>
          <Col
            sm="12"
            md="6"
            lg="6"
            className="mt-3"
            style={{ margin: "0px auto" }}
          >
            <InfoWithCta
              icon="fa-check-square"
              title="Property Added Successfully!"
              cta="GO TO PROPERTIES"
              clickAction={() => {
                window.location = "/properties";
              }}
            >
              <p>
                Our property manager will contact you shortly on the mobile
                number you provided. <br />
                Please check the dashboard for progress.
              </p>
            </InfoWithCta>
          </Col>
        </Row>
      </>
    );
  }
}
