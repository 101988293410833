import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { registerLoginUser, socialLogin } from "../../actions/authActions";
import { saveVisitUser } from "../../actions/visitActions";
import SocialButton from "../../components/Common/SocialButton";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class SignupCard extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
      privacy_policy: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidUpdate(nextProps) {
    if (nextProps.auth.isAuthenticated && this.props.onDone) {
      this.props.onDone();
    }
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    if (e.target.name === "privacy_policy") {
      this.setState({ privacy_policy: !this.state.privacy_policy });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  handleFacebookLogin = (user) => {
    console.log("social media loggin success ", user);
    console.log("user._profile :", user._profile);
    const newUser = {
      name: user._profile.name,
      email: user._profile.email,
      phone: "",
    };
    //  role: "tenant",
    this.props.socialLogin(newUser, this.props.return_url);
  };

  handleFacebookLoginFailure = (err) => {
    console.error("social media loggin failure", err);
  };
  handleGoogleLogin = (user) => {
    console.log("social media loggin success ", user);
    console.log("user._profile :", user._profile);
    const newUser = {
      name: user._profile.name,
      email: user._profile.email,
      phone: "",
    };
    this.props.socialLogin(newUser, this.props.return_url);
  };

  handleGoogleLoginFailure = (err) => {
    console.error("social media loggin failure", err);
  };

  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      password2: this.state.password2,
    };
    console.log("book visit " + this.props.prop.bookVisit);
    if (this.props.visit.bookVisit) {
      this.props.registerLoginUser(newUser, this.props.return_url);
      this.props.saveVisitUser(newUser);
      // if (this.props.auth.isAuthenticated) {
      //   this.props.onDone(); //for hiding signup modal and show date picker in PropertyInfoCard page
      // }
    } else if (this.props.prop.reg_new_prop_owner) {
      this.props.registerLoginUser(newUser, this.props.return_url);
    } else {
      this.props.registerLoginUser(newUser, this.props.return_url); //this.props.history);
    }
  }
  // state = {};href="/login-page"
  render() {
    var card_state = (
      <a
        onClick={this.props.onLoginClick}
        style={{
          textDecoration: "underline",
          color: "#4a5aeb",
          cursor: "pointer",
        }}
      >
        here
      </a>
    );
    var page_state = <a href="/login-page">here</a>;
    const { errors } = this.state;
    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-white pb-5">
            <div className="text-center text-muted mt-2">
              Already a member ? Login{" "}
              {this.props.onLoginClick ? card_state : page_state}
              <br />
            </div>
            <div className="text-muted text-center mb-2">
              <hr
                className="d-inline-block mb-1 mr-2"
                style={{ width: "32%" }}
              ></hr>
              OR
              <hr
                className="d-inline-block mb-1 ml-2"
                style={{ width: "32%" }}
              ></hr>
            </div>
            <div className="text-muted text-center mb-3">
              <small>Sign up with</small>
            </div>
            <div className="btn-wrapper text-center">
              <SocialButton
                provider="google"
                appId="183940291907-qqjeq6vn08193odqpos1danmbft9oab4.apps.googleusercontent.com"
                onLoginSuccess={this.handleGoogleLogin}
                onLoginFailure={this.handleGoogleLoginFailure}
                icon="fa fa-google"
                color="google-plus"
              >
                Continue with Google &nbsp;&nbsp;&nbsp;
              </SocialButton>
              <SocialButton
                provider="facebook"
                appId="663506307551273"
                onLoginSuccess={this.handleFacebookLogin}
                onLoginFailure={this.handleFacebookLoginFailure}
                icon="fa fa-facebook-f"
                color="facebook"
              >
                Continue with Facebook
              </SocialButton>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up with Details</small>
            </div>
            <Form role="form" onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    className="is-invalid"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
              // className={classnames("", {
              //   "has-danger": errors.email
              // })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.email && (
                  <div className="text-warning text-center ">
                    <small>{errors.email}</small>
                  </div>
                )}
              </FormGroup>
              <FormGroup
              // className={classnames("", {
              //   "has-danger": errors.email
              // })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-mobile-button" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mobile Number"
                    type="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.phone && (
                  <div className="text-warning text-center ">
                    <small>{errors.phone}</small>
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.password && (
                  <div className="text-warning text-center">
                    <small>{errors.password}</small>
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="off"
                    name="password2"
                    value={this.state.password2}
                    onChange={this.onChange}
                  />
                </InputGroup>
                {errors.password2 && (
                  <div className="text-warning text-center">
                    <small>{errors.password2}</small>
                  </div>
                )}
              </FormGroup>
              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div> */}
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      name="privacy_policy"
                      onChange={this.onChange}
                      checked={this.state.privacy_policy}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span>
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  disabled={!this.state.privacy_policy}
                  className="mt-2"
                  color="primary"
                  type="submit"
                >
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }
}
SignupCard.propTypes = {
  registerUser: PropTypes.func.isRequired,
  registerLoginUser: PropTypes.func,
  saveVisitUser: PropTypes.func,
  socialLogin: PropTypes.func,
  auth: PropTypes.object.isRequired,
  prop: PropTypes.object,
  visit: PropTypes.object,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  prop: state.prop,
  auth: state.auth,
  visit: state.visit,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  registerLoginUser,
  registerUser,
  saveVisitUser,
  socialLogin,
})(withRouter(SignupCard));
