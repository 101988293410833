import React, { Component } from "react";
import { Card, Container, CardBody } from "reactstrap";
import DarkNavbar from "components/Navbars/DarkNavbar";
import Footer from "components/Footers/Footer/Footer";

export default class Terms extends Component {
  render() {
    return (
      <>
        <DarkNavbar />
        <section className="section section-md bg-primary">
          <Container>
            <div>
              <h1 className="text-white mt-3">Terms of Use Agreement </h1>
            </div>
          </Container>
        </section>
        <Container class="py-md">
          <Card
            className="shadow shadow-lg--hover mb-5"
            style={{ marginTop: "-50px" }}
          >
            <CardBody className="py-md-5 px-md-5 lh-180">
              <p>
                Stay Local is concerned and observant with users using its
                website located at www.igenproperties.in. It has provided this
                Terms of Use statement (TOU) to familiarize the user with it.
              </p>
              <h3>Acceptance of Terms</h3>
              <p>
                The services provided by Stay Local to its users are subjected
                to the following TOU. Stay Local reserve its right to modify and
                update the TOU at any time without notifying the user.
              </p>
              <ul style={{ listStyle: "upper-latin" }}>
                <li>
                  The agreement that is incorporated by reference is applicable
                  to be used for the workings of the website. This includes, but
                  not limited to, the terms and conditions as well as privacy
                  policies set forth hereof governing the use of certain
                  information or content on the website by the user. By using
                  the website of Stay Local and its services, a user complies
                  with all terms and conditions as well as privacy policies
                  thereof. The right for using the website is personal to the
                  user and is mandatory that, it does not get transferred to any
                  other entity or person. The user is solely responsible for the
                  account made by the user with all the details. The user’s
                  account complies fully with the provisions of this TOU
                  agreement. User shall be responsible for protecting
                  confidential information such as passwords.
                </li>
                <li>
                  Any feature or aspects of this website can get changed by iGen
                  Properties at any time and is subjected to the content or
                  data, and hours of availability.
                </li>
              </ul>
              <h3 className="mt-5">Changed Terms</h3>
              <p>
                Stay Local has the right to modify or change the terms &
                conditions, which applies to the user’s use of the website.
                These new conditions include changing of property listed prices
                according to the market value. Such changes are effective
                immediately; that is, as soon as they are updated on the
                website. The notification of these modifications that include
                any additions or deletions thereof, will be given through
                website, e-mails or postal mails to all registered users. Users
                using the website after these notifications convey acceptance to
                these updated terms.
              </p>
              <h3 className="mt-5">Description of Services </h3>
              <p>
                A variety of resources and services can be accessed by the user
                through the website; wherein, a user can list the property for
                renting, lease or sale. Users can search for properties that are
                for rent, lease or sale as well. Other services include
                preparing the lease or rental agreements, collection of monthly
                rent, and collecting the security deposit to name a few. The
                services, which include new updates, enhancements, features, and
                the addition of a new website are subjected to TOU.
              </p>
              <h3 className="mt-5">User Conduct </h3>
              <ul style={{ listStyle: "upper-latin" }}>
                <li>
                  A user shall visit the website for only a decent listing or
                  buying; lawful purposes. There should not be any kind of
                  violations thereof that might be unlawful or illegal,
                  threatening, defamatory, abusive, which invokes invasion of
                  privacy or publicity rights, obscene, vulgar, or
                  objectionable. These kinds of violations can give rise to
                  criminal offenses, civil liabilities or any law violations.
                  Any conduct by users at the discretion of Stay Local that
                  inhibit other users from using the website will not be
                  permitted. Advertisements by users are not permitted along
                  with any kind of commercial solicitation that might promote
                  the services competitive with that of iGen properties.
                </li>
                <li>
                  The website of Stay Local consists of copyrighted material,
                  trademarks, along with other proprietary information that
                  includes text, software, and photos. The entire website is
                  copyrighted as a collective entity under the Trademark &
                  Copyright Laws of India. Users are not allowed to modify,
                  transmit, publish or participate in the transfer, sale, create
                  derivations or exploit the website content in any way.
                </li>
                <li>
                  Any kind of copyrighted material, trademarks, and other
                  proprietary rights should not be posted by users, without
                  taking permission from the owner of the copyright, trademark
                  or proprietary rights. In such a case, users are solely
                  responsible for checking if a content falls under this
                  category and any consequences thereof.
                </li>
              </ul>
              <h3 className="mt-5"> Use of Services </h3>
              <p>
                The services may contain emails, forums, chats, photos, and
                other communication facilities that enable flexible contact with
                other users as well as administrators. Users agree on using a
                comfortable communication service for posting, sending, and
                receiving messages as well as proper materials, and when
                applicable. Users should not use e-mails for surveys,
                defamation, abusing, harassment, or stalking. Also, the mail
                should not be used for posting, publishing, advertising, or
                uploading inappropriate, obscene photos, videos or any unlawful
                content. It is strictly prohibited to use any material or
                information that infringes copyright, trademark, patent, trade
                secret or other proprietary rights of any party; upload files
                that contain a virus, advertising to sell or offer, buy any
                goods and services for business purposes unless communication
                services allow such messages. The files provided by
                communication services cannot be legally displayed, reproduced,
                or distributed.
              </p>
              <p>
                Stay Local reserves the right to monitor and examine those
                materials posted to communication services and remove any of
                them in sole discretion. Stay Local has the right to terminate
                any user’s profile and access and communication services upon
                its sole discretion without any notice or reason whatsoever.
                Stay Local has the right to disclose any information to
                concerned parties as it deems necessary for satisfying any kind
                of legal process or government request. iGen Properties can
                edit, refuse to post or remove any information and materials in
                its sole discretion.
              </p>
              <p>
                Users are advised to exercise caution while registering and
                filling out personal details in any of the communication
                services. Stay Local does not control or endorse any personal
                content along with messages or information found in any
                communication services. Hence, Stay Local disclaim any liability
                whatsoever concerning the communication services along with any
                actions that result from the user’s participation in the
                communication services. The managers, as well as hosts of Stay
                Local, are not its spokespersons, and hence, their views do not
                reflect that of Stay Local.
              </p>
              <h3 className="mt-5">Member Account, Password, and Security </h3>
              <p>
                Opening an account is necessary for availing the services of
                listing, renting, or buying the properties through the website
                of Stay Local. Users are liable to provide Stay Local with
                current, accurate as well as complete information as prompted by
                the registration form. A password is created as per the
                registration rules along with a user name. The confidentiality
                of user name, password and other personal details should be
                maintained by the users themselves. The user is solely
                responsible for all activities that undergo under the user’s
                account. The users should notify Stay Local about any
                unauthorized use of the user’s account or any other security
                breach regarding the same. Stay Local cannot be held liable for
                any information loss or any events thereof, the user might incur
                as a result of unauthorized or someone else using the user’s
                account. Users could be held liable for losses incurred by Stay
                Local due to a user account or password.
              </p>
              <h3 className="mt-5">
                Disclaimer regarding Software, Documents, and Services available
              </h3>
              <p>
                In no events whatsoever, Stay Local shall be liable for any kind
                of damages that include loss of data, profits, and negligence
                arising out of or in connection with the use of software
                performance, documents, or information available from the
                services.
              </p>
              <h3 className="mt-5">
                Details provided to Stay Local or posted on the website
              </h3>
              <p>
                Stay Local does not claim ownership of properties listed on its
                website. The feedback and suggestions on these properties are
                given by users and do not have to do anything with the
                administration. Users post or list properties on to the website
                to lease, rent, or sell and the ownership of the properties
                solely belong to them. Services posted on the website gets
                reviewed by users as well as members of any private or public
                community. The data given by the user with its appropriate
                request or need might get passed around among potentially
                interested parties. The submission by a user grants iGen
                properties to use them in regards to their online business with
                the license to publicly display the content for the sake of
                fulfilling the services via the website.
              </p>
              <h3 className="mt-5">
                Disclaimer of Warranty; Limitation of Liability
              </h3>
              <ul style={{ listStyle: "upper-latin" }}>
                <li>
                  The user agrees to use the website at the user’s sole risk.
                  Administration of Stay Local along with employees, agents or
                  third-party content providers agree that this website might
                  not be error-free or cause any interruptions. Stay Local does
                  not warrant reliability, accuracy or information of any
                  service or properties listed through the website.
                </li>
                <li>
                  The Stay Local website is provided without any kind of
                  warranties, either implied or express, warranties of title or
                  implied warranties of properties thereof. This is implied by
                  and cannot be excluded, restricted, or modified under the laws
                  applicable to this TOU agreement.
                </li>
                <li>
                  The liability disclaimer applies to any damages or injuries
                  caused by a failure in performance, interruption, omission,
                  defect, deletion, or delay in operation of services regarding
                  properties, computer viruses, communication line failures,
                  breach of contract, and under any other cause of action that
                  result in losses.
                </li>
                <li>
                  In addition to the terms outlined in this TOU, Stay Local will
                  be liable for any misinterpretation or defect in the content
                  displayed on its websites for however time-frame. iGen
                  Properties is liable for any errors, omissions, inaccuracies,
                  or any other defects in the website.
                </li>
              </ul>
              <h3 className="mt-5">Unsolicited Idea Submission Policy</h3>
              <p>
                Stay Local and any of its administrative staff or employees do
                not accept and consider unsolicited ideas, which might include
                those for marketing, advertising campaigns, new promotions, or
                new technologies or products. The sole purpose of this TOU is to
                avoid potential misunderstandings or any kind of disputes.
                Unsolicited ideas will not be entertained whatsoever and thus,
                will not be treated as confidential or proprietary.
              </p>
              <h3 className="mt-5">Monitoring</h3>
              <p>
                Stay Local has the right to monitor its website, including the
                communication channels for determining compliance with this TOU
                agreement and any & all of the operating rules established by
                them. Stay Local has the right to edit, refuse to post or remove
                any properties listed on the website for valid reasons. Removal
                of material on the website can be done in sole discretion by
                Stay Local.
              </p>
              <h3 className="mt-5">Indemnification</h3>
              <p>
                In case of any issues brought forth, the users agree to
                indemnify, defend, and hold Stay Local and its employees from
                any fraud problems; which include the attorney’s fees from the
                user’s account for the benefit of Stay Local.
              </p>
              <h3 className="mt-5">Termination </h3>
              <p>
                The user’s account can be terminated by the user at any point in
                time and in certain cases, the termination can be done by iGen
                Properties in its sole discretion; which can happen during a
                breach of the agreement.
              </p>
              <h3 className="mt-5">Intellectual Property </h3>
              <p>
                All the copyrights, trademarks, and logos posted by iGen
                Properties on its website are the intellectual properties and
                all rights are reserved for the same.
              </p>
              <h3 className="mt-5">Miscellaneous </h3>
              <p>
                This TOU Agreement and any or all operating rules established by
                Stay Local constitute an entire agreement of all-inclusive
                parties concerning subject matter involved in the website. The
                TOU agreement shall be interpreted per the Laws of India.
              </p>
            </CardBody>
          </Card>
        </Container>
        <Footer />
      </>
    );
  }
}
