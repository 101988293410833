import React, { Component } from "react";

export default class DividerWithText extends Component {
  render() {
    return (
      <>
        <div className="d-flex flex-row">
          <div className="col">
            <hr
              className="text-primary"
              style={{ borderTop: "0.09rem solid" }}
            ></hr>
          </div>
          <div className="flex-column text-center align-self-center text-primary">
            {this.props.content}
          </div>
          <div className="col">
            <hr
              className="text-primary"
              style={{ borderTop: "0.09rem solid" }}
            ></hr>
          </div>
        </div>
      </>
    );
  }
}
