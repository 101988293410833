import React, { Component } from "react";
import { Button } from "reactstrap";
import DateInput from "../Inputs/DateInput";
import OptionSelector from "../Inputs/OptionSelector/OptionSelector";
import { saveVisitDate } from "../../actions/visitActions";
import { saveVisitTime } from "../../actions/visitActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class DateTime extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }
  state = { error: null };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  onSubmit(e) {
    e.preventDefault();
  }
  render() {
    const { visit } = this.props;

    var er = (
      <div className="text-warning text-center ">
        <small>{this.state.error}</small>
      </div>
    );

    return (
      <>
        <form onSubmit={this.onSubmit}>
          <div className="modal-body px-3 py-1 mx-lg-5">
            <div className="pb-3">
              <h4 className="my-0 text-center mb-4">Choose Date & Time</h4>
              <small className="d-block text-uppercase mt-3 mb-2 text-muted">
                Select Date
              </small>
              <DateInput
                onDateSelect={(date) => {
                  this.props.saveVisitDate(date);
                }}
                def_date={visit.visitDate === null ? null : visit.visitDate}
                noPastDate={true}
              />
              <OptionSelector
                label="Select time"
                option1="MORNING"
                option1sub="09AM-12PM"
                option2="AFTERNOON"
                option2sub="12PM-05PM"
                // option3="EVENING"
                // option3sub="05PM-07PM"
                def_option={
                  visit.visitTime === null
                    ? null
                    : visit.visitTime === "09AM-12PM"
                    ? 1
                    : visit.visitTime === "12PM-05PM"
                    ? 2
                    : visit.visitTime === "05PM-07PM"
                    ? 3
                    : null
                }
                onOptionClick={(val) => {
                  switch (val) {
                    case 1:
                      this.props.saveVisitTime("09AM-12PM");
                      break;
                    case 2:
                      this.props.saveVisitTime("12PM-05PM");
                      break;
                    case 3:
                      this.props.saveVisitTime("05PM-07PM");
                      break;
                    default:
                      this.props.saveVisitTime(null);
                  }
                }}
              />
            </div>
          </div>
          <div className="modal-footer border-0 pb-1 pt-2 mb-lg-3">
            <Button
              className="btn-round btn-mb-primary btn-block"
              color="primary"
              outline
              type="button"
              onClick={() => this.props.onBack()}
            >
              <small>BACK</small>
            </Button>
            <Button
              className="btn-primary btn-round btn-block mb-2"
              color="primary"
              type="submit"
              onClick={() => {
                if (
                  this.props.visit.visitDate === null ||
                  this.props.visit.visitTime === null
                ) {
                  this.setState({ error: "Please fill all the fileds." });
                } else {
                  this.setState({ error: null });
                  this.props.onDateTime();
                }
              }}
            >
              <small>BOOK</small>
            </Button>
          </div>
          <div className="d-block">{this.state.error !== null ? er : ""}</div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  visit: state.visit,
  auth: state.auth,
});
DateTime.propTypes = {
  saveVisitDate: PropTypes.func,
  saveVisitTime: PropTypes.func,
};
export default connect(mapStateToProps, { saveVisitDate, saveVisitTime })(
  DateTime
);
